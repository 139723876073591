import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import Modal from "../../../view/common/Modal";
import { executeGraphQLQuery, executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import { BankLogo, Payout_Calculator } from "../../../queries/payout.gql";
import { useCleverTap } from '../../CleverTapContext';
import { useHistory } from "react-router";

const PayoutCalculator = (props) => {

  const history = useHistory();
  const { updateStateIntoRedux } = props;
  const [selectedLoanType, setSelectedLoanType] = useState("1");
  const [selectedRadioOption, setSelectedRadioOption] = useState("self");
  const [sliderValue, setSliderValue] = useState('4000000');
  const [showBank, setShowBank] = useState(false);
  const [payoutData, SetPayoutData] = useState([]);
  const [bankDetails,setBankDetails]= useState([]);
  const [partnerId,setPartnerId]= useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false); 
  const trackEvent = useCleverTap();

  const togglePopup = () => {
    setShowBank(!showBank);
    if (!showBank) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const getBanksNameForModal = () => {
    if (payoutData.length > 0) {
      for (let i = 0; i < payoutData.length; i++) {
        if (payoutData[i].banks.length > 5) {
          const bankDetail = payoutData[i];
          const banksArray = bankDetail.banks_name.split(',').map((bankName, index) => ({
            name: bankName? bankName.trim():'',
            logo: bankDetail.bank_logos ? bankDetail.bank_logos[bankDetail.banks[index]] : ''
          }));
          setBankDetails(banksArray);
          break;
        }
      }
    }
  };

  const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(amount);
    return formattedAmount.replace(/\.00$/, '');
};

  useEffect(() => {
    trackEvent("page_loaded", "payout_cal_landing");
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
        setPartnerId(loginResponse.data.dealer_id)
      }
    };
    updateReduxData();
  }, [updateStateIntoRedux]);

  useEffect(() => {
    setButtonDisabled(false); 
  }, [selectedLoanType, selectedRadioOption, sliderValue]);

  const handleLoanTypeChange = (event) => {
    setSelectedLoanType(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedRadioOption(event.target.value);
  };

  const handleSliderChange = (event) => {
    const intAmount=parseInt(event.target.value.replace(/\D/g, ''), 10) || 0;
    setSliderValue(intAmount);
  };

  useEffect(() => {
    const rangeInputs = document.querySelectorAll('input[type="range"].slider-progress');
    rangeInputs.forEach((e) => {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min === '' ? '0' : e.min);
        e.style.setProperty('--max', e.max === '' ? '100' : e.max);
        e.addEventListener('input', (event) => {
            e.style.setProperty('--value', event.target.value);
        });
    });
    return () => {
        rangeInputs.forEach((e) => {
        });
    };
}, [sliderValue]);

  const handlePayoutClick = async (product_type, fullfillment_type, amount, partner_id) => {
    trackEvent("cta_clicked", "payout_cal_calculate");
    setButtonDisabled(true);
    try {
      const stringAmount = String(amount);
      const integerAmount = parseInt(stringAmount.replace(/\D/g, ''), 10) || 0;
      

      const result = await executeGraphQLQuery(Payout_Calculator(integerAmount, fullfillment_type, partner_id, product_type));
      if (result) {
        try{
          const masterResult= await executeGraphQLQueryFinex(BankLogo);
        SetPayoutData(result.data.payout_calculator.map(payout => ({
          ...payout,
          bank_logos: masterResult.data.masterdata.bank_list.reduce((acc, bank) => {
            acc[bank.id] = bank.logo;
            return acc;
          }, {})
        })));
        }catch(err){
          console.error('Error during bank logo fetching:', err);
        }
       
      } else {
        console.log('No data from  query');
      }
    } catch (error) {
      console.error('Error during payout calculation:', error);
    }
  };

  const Payoutsearch = () =>{
    history.push("/saathi-tools")
  }

  return (
    <>
      <div className="mytoolscard">
        <div className="backoffer" onClick={Payoutsearch} style={{marginBottom:20}}>
          <i className="ic-arrow_back" style={{ fontSize: 20, cursor: "pointer" }}></i>
        <div className="commonheading">Payout Calculator</div>
        </div>
        <div className="payoutcalculator">
          <div className="payoutcard">
            <div className="flotleft">
              <div className="emibox">
                <div className="mainamount">
                  <div>Loan Amount</div>
                  <input
                    type="text"
                    id="loan_amount"
                    name="loan_amount"
                    value={formatCurrency(sliderValue)}
                    onChange={handleSliderChange}
                    maxLength={12}
                  />
                </div>
              </div>
              <div className="slidernew-container">
                <input
                  type="range"
                  id="loan_amount"
                  name="loan_amount"
                  min="0"
                  max="20000000"
                   step="50"
                  value={sliderValue}
                  className="styled-slider slider-progress"
                  onChange={handleSliderChange}
                />
              </div>

              <div className="slidernew-container">
                <div className="slidernew-labels">
                  <span>0</span>
                  <span style={{marginLeft:"28px"}}>25L</span>
                  <span style={{marginLeft:"11px"}}>50L</span>
                  <span style={{marginLeft:"6px"}} >75L</span>
                  <span style={{marginLeft:"11px"}}>100L</span>
                  <span>125L</span>
                  <span style={{marginLeft:"6px"}}>150L</span>
                  <span style={{marginLeft:"6px"}}>175L</span>
                  <span>200L</span>
                </div>
              </div>

              <div className="payoutheading">Product Type</div>
              <div className="loanfulfillment">
                <div className="loaninput">
                  <input
                    type="radio"
                    required
                    id="homeloan"
                    name="loan_type"
                    value="1"
                    checked={selectedLoanType == "1"}
                    onChange={handleLoanTypeChange}
                  />
                  <label
                    htmlFor="homeloan"
                    style={{ marginTop: 5, fontSize: "13px" }}
                  >
                    Home Loan
                  </label>
                </div>
                <div className="loaninput">
                  <input
                    type="radio"
                    required
                    id="loanproperty"
                    name="loan_type"
                    value="2"
                    checked={selectedLoanType == "2"}
                    onChange={handleLoanTypeChange}
                  />
                  <label
                    htmlFor="loanproperty"
                    style={{ marginTop: 5, fontSize: "13px" }}
                  >
                    Loan Against Property
                  </label>
                </div>
              </div>

              {/* Fulfillment Type */}
              <div className="payoutheading">Fulfillment Type</div>
              <div className="newleadcreateflexbtn">
                <div className="newleadtabscreate">
                  <input
                    type="radio"
                    id="self"
                    name="self"
                    value="self"
                    onChange={handleRadioChange}
                    checked={selectedRadioOption === "self"}
                  />
                  <label htmlFor="self">I will do it myself</label>
                </div>
                <div className="newleadtabscreate">
                  <input
                    type="radio"
                    id="ambak"
                    name="ambak"
                    value="ambak"
                    onChange={handleRadioChange}
                    checked={selectedRadioOption === "ambak"}
                  />
                  <label htmlFor="ambak">Refer to Ambak</label>
                </div>
              </div>
              <button className="payoutcalculate" 
                      disabled={buttonDisabled} 
                      onClick={() => handlePayoutClick(selectedLoanType, selectedRadioOption, sliderValue,partnerId)}>Calculate Now</button>
            </div>

            <div className="flotright">
              <div className="payoutrightcard">
                <div className="payoutheadingright">
                  Your Earnings on <span>your fingertips</span>
                </div>
                <div className="payoutlistone">
                  <div>
                    <img src="https://static.ambak.com/saathi/1726573806997.svg" />
                  </div>
                  <div>Estimate your Payouts in one simple step.</div>
                </div>

                <div className="payoutlisttwo">
                  <div>
                    <img src="https://static.ambak.com/saathi/1726573876017.svg" />
                  </div>
                  <div>Analyse the bank which helps you earn the most.</div>
                </div>

                <div className="payoutlistthree">
                  <div>
                    <img src="https://static.ambak.com/saathi/1726573912153.svg" />
                  </div>
                  <div>Know your exact payout percentage & scheme for each bank.</div>
                </div>
              </div>
            </div>
          </div>

          <div className="allbankflexcard" >
            {payoutData.map((data, index) => {
              const banks = data.banks_name.split(',');
              const showMoreButton = banks.length > 5;
              return (
                <div className="payoutbankcard" key={index}>
                  <div className="payoutcommon">
                    <div>
                      <div className="payoutamount">Amount</div>
                      <div className="payoutprice"> {formatCurrency(Math.round(data.payout_amount)) || '0'}</div>
                    </div>
                    <div>
                      <div className="payoutamount">Payout %</div>
                      <div className="payoutprice">{data.pay_per || '0%'}%</div>
                    </div>
                  </div>
                  <div>
                    <ul className="bankofferlist">
                      {banks.slice(0, showMoreButton ? 5 : banks.length).map((bank, bankIndex) => (
                        <li className="banklist" key={bankIndex}>
                          <div className="showbank">
                          <img src={data.bank_logos[data.banks[bankIndex]]} alt="banklogo" />
                            <span>{bank.trim()}</span>
                          </div>
                        </li>
                      ))}
                      {showMoreButton && (
                        <li className="banklist">
                          <div className="showbank">
                            <div className="morebankbtn" onClick={() => { getBanksNameForModal(); togglePopup(); }}>
                              +{banks.length-5} More
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

              );
            })}
          </div>
        </div>
      </div>

      <Modal isOpen={showBank} togglePopup={togglePopup} width={984}>
  <h3>All Lenders</h3>
  <div>
    <ul className="bankofferlist">
      {bankDetails?.map((bank, bankIndex) => (
        <li className="banklist" key={bankIndex}>
          <div className="allshowbank">
            <img src={bank.logo || 'default-logo.svg'} alt={`${bank.name} logo`} />
            <span>{bank.name}</span>
          </div>
        </li>
      ))}
    </ul>
    <div className="flexbankshowbtn">
      <button className="banklistshowbtn" onClick={togglePopup}>
        Ok! Got It
      </button>
    </div>
  </div>
</Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(PayoutCalculator)
);
