import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getNumberFormat_IN } from "../MyLeads/helpers/helpers";
import PageLoader from "../../view/elements/PageLoader";
import { toast } from "react-toastify";
import Modal from "../../view/common/Modal";
import NewLeadLoanOffer from "../my-tools/component/loanoffer/NewLeadLoanOffer";
import crypto from "../../config/crypto";
import { SAVE_LOAN_OFFER } from "../../queries/sangam/create.gql";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { useCleverTap } from '../CleverTapContext';
import ContactUsPopup from "../my-tools/component/ContactUsPopup";
import BottomModal from "../../view/common/BottomModal1";

const MIN_TENURE_YEAR = 5;
const MIN_LOAN_AMOUNT = 500000;

const BankOfferList = ({
  bankOffer,
  recalculateOffer,
  loading,
  leadDetail,
  setCheckOfferLoan,
  shortlistedOffers,
  newLeadId = null,
  sortValue,
  setSortValue,
  setLoanTenure
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [checkedOffers, setCheckedOffers] = useState([]);
  const [showView, setShowView] = useState(false);
  const [ShowAllFees, setShowAllFees] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [bankDetails, setBankDetails] = useState([]);
  const [allFees, setAllFees] = useState({});
  const [loanDetails, setLoanDetails] = useState({});
  const [showFees, setShowFees] = useState(false);
  const [callUsModal, setCallUsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const trackEvent = useCleverTap();  

  useEffect(() => {
    if (!bankOffer.length) return false;

    const listOfShortlistedOffer = [];
    const listOfMaxEligibalLoanAmount = bankOffer.map(
      (offer) => offer.maxEligibalLoanAmount
    );
    const listOfMaxLoanAmount = bankOffer.map((offer) =>
      offer.eligibalLoanAmount.replaceAll(",", "")
    );
    const listOfTenure = bankOffer.map((offer) => offer.finalMaxTenure);
    const listOfMaxTenure = bankOffer.map((offer) => offer.maxTenure);
    const maxEligibleLoan = Math.max(...listOfMaxEligibalLoanAmount);
    const maxLoan = Math.max(...listOfMaxLoanAmount);
    const maxTenure = Math.max(...listOfTenure);
    const tenure = Math.max(...listOfMaxTenure);

    for (let i = 0; i < bankOffer.length; i++) {
      const offer = bankOffer[i];
      const isShortlisted = shortlistedOffers.some(
        (obj) => obj.bank_id === offer.bankId
      );
      if (isShortlisted) listOfShortlistedOffer.push(offer);
    }

    setCheckedOffers(listOfShortlistedOffer);
    setLoanDetails((prev) => ({
      ...prev,
      maxEligibleLoanAmount: maxEligibleLoan,
      requiredLoanAmount: maxEligibleLoan,
      minLoanAmount: MIN_LOAN_AMOUNT,
      requiredTenure: maxTenure,
      maxLoan,
      tenure,
      maxTenure,
    }));
  }, [bankOffer, shortlistedOffers]);

  useEffect(() => {
    if (Object.keys(leadDetail).length || newLeadId) {
      setLeadId(leadDetail?.id || newLeadId);
    }
  }, [leadDetail, newLeadId]);

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      trackEvent("cta_clicked", "offer_page_tick_mark");
      setCheckedOffers((prevCheckedOffers) => [...prevCheckedOffers, item]);
    } else {
      setCheckedOffers((prevCheckedOffers) =>
        prevCheckedOffers.filter((offer) => offer !== item)
      );
    }
  };

  const handleAmountSlider = (event) => {
    const { value } = event.target;
    setLoanDetails((prev) => ({
      ...prev,
      maxLoan: +value,
    }));
  };

  useEffect(() => {
    const rangeInputs = document.querySelectorAll(
      'input[type="range"].slider-progress'
    );
    rangeInputs.forEach((e) => {
      e.style.setProperty("--value", e.value);
      e.style.setProperty("--min", e.min === "" ? "0" : e.min);
      e.style.setProperty("--max", e.max === "" ? "100" : e.max);
      e.addEventListener("input", (event) => {
        e.style.setProperty("--value", event.target.value);
      });
    });
    return () => {
      rangeInputs.forEach((e) => {});
    };
  }, [loanDetails?.maxLoan]);

  const handleCopyOffer = async () => {
    let msgBody1 = "",
      msgBody2 = "";
    if (checkedOffers.length === 1) {
      msgBody1 = `Based on your profile and requirement, I have identified the best loan offer for you with ${checkedOffers[0].bankName} Bank. Please go through the details and note that this offer is subject to change basis the final lender policy.`;
    } else if (checkedOffers.length > 1) {
      msgBody1 = `These are the best suited loan offers for you based on your profile and requirements. Please go through the details & note that the offers are subject to change basis the final lender policy.`;
    }

    for (let i = 0; i < checkedOffers.length; i++) {
      const offer = checkedOffers[i];
      const msg = `
${offer.bankName} Bank
Loan amount: ${offer.eligibalLoanAmount}
Rate of Interest: ${offer.minRoi}
Tenure: ${offer.maxTenure}
EMI: ${offer.monthlyEMI}
Login Fee: ${offer.login_fee?.replace("Login Fee: ", "")}
Processing Fee: ${offer.processingText?.replace("Processing Fee: ", "")}`;
      msgBody2 += msg;
      msgBody2 += "\n\n";
    }

    const msgTemplate = `
Hi There,

${msgBody1}

${msgBody2}

Looking forward to your confirmation for taking the loan process ahead.

Regards,
${loginUserInfo.name}`;

    try {
      await navigator.clipboard.writeText(msgTemplate);
      toast.success("Copied!");
    } catch (error) {
      toast.error("Failed to copy offer");
    }
  };

  const togglePopup = () => {
    setShowView((prevShowView) => !prevShowView);

    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleFessPopup = (index) => {
    const feesObj = {};
    const selectedOffer = bankOffer[index];
    feesObj["Processing Fees"] = selectedOffer?.processingText || "N/A";
    feesObj["Login Fee"] = selectedOffer?.login_fee || "N/A";
    feesObj["Account handling charges/Other Charges"] =
      selectedOffer?.ac_handling_charge || "N/A";
    feesObj["Legal and Technical Fee"] =
      selectedOffer?.legal_technical_fee || "N/A";
    feesObj["E-Stamp Charges"] = selectedOffer?.e_stamp_charge || "N/A";
    feesObj["Due Diligence Charges"] =
      selectedOffer?.due_diligence_charge || "N/A";
    feesObj["CERSAI Charges"] = selectedOffer?.CERSAI_charge || "N/A";
    feesObj["MODT Charges"] = selectedOffer?.MODT_charge || "N/A";
    feesObj["NOI Charges"] = selectedOffer?.NOI_charge || "N/A";
    setAllFees(feesObj);

    setShowAllFees((prevShowView) => !prevShowView);
    if (!ShowAllFees) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleSuccessPopup = () => {
    setShowSuccess((prev) => !prev);

    if (!showSuccess) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const saveLoanOffer = async (bankData = [], lead_id) => {
    try {
      const data = bankData.map((obj) => {
        return {
          lead_id,
          roi: obj.minRoi,
          emi: +obj.monthlyEMI?.replaceAll(",", ""),
          loan_amount: +obj.eligibalLoanAmount?.replaceAll(",", ""),
          bank_id: obj.bankId,
          processing_fee: obj.processingText,
        };
      });
      const mutation = SAVE_LOAN_OFFER;
      const variables = {
        partner_id: loginUserInfo.user_data.dealer_id,
        api_called_by: "saathi_web",
        loanOfferInput: data,
      };

      const resp = await executeGraphQLMutationFinex(mutation, variables);
      if (!resp?.data?.save_loan_offer) throw new Error("Something went wrong");

      toggleSuccessPopup();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleApplyOffer = (bankDetails) => {
    trackEvent("cta_clicked", "offer_page_apply_now");
    setBankDetails([bankDetails]);
    if (!Object.keys(leadDetail).length && !newLeadId) {
      return togglePopup();
    } else {
      saveLoanOffer([bankDetails], leadId);
    }
  };

  const handleSavetoLead = () => {
    if (leadId) {
      trackEvent("cta_clicked", "offer_page_save_to_lead");
      return saveLoanOffer(checkedOffers, leadId);
    }
    return togglePopup();
  };

  const handleSkipAndContinue = (data) => {
    togglePopup();
  };

  const handleLeadCreated = (lead_id) => {
    const offerList = bankDetails.length > 0 ? bankDetails : checkedOffers;
    setLeadId(lead_id);
    togglePopup();
    saveLoanOffer(offerList, lead_id);
  };

  const handleGoToLead = () => {
    window.location.href = `/saathi-details-page/${crypto.encode(leadId)}`;
  };

  const getMaxTenureOptions = (minYear, maxYear) => {
    const options = [];
    for (let i = minYear; i <= maxYear; i++) {
      options.push({
        id: i,
        label: `${i} Years`,
        value: i,
      });
    }
    return options;
  };

  const handleTenureChange = (data) => {
    setLoanDetails((prev) => ({
      ...prev,
      tenure: data.value,
    }));

    setLoanTenure(`${data.value}`)
  };

  const handleRecalculate = () => {
    const { maxLoan, tenure } = loanDetails;
    const reCalculate = {
      loan_amount: `${maxLoan}`,
      max_tenure: tenure,
    };
    recalculateOffer(reCalculate);
  };

  const handleHover = (eventName, index) => {
    if (eventName === "mouse_enter") {
      const feesObj = {};
      const selectedOffer = bankOffer[index];
      feesObj["Processing Fees"] = selectedOffer?.processingText || "N/A";
      feesObj["Login Fee"] = selectedOffer?.login_fee || "N/A";
      feesObj["Account handling charges/Other Charges"] =
        selectedOffer?.ac_handling_charge || "N/A";
      feesObj["Legal and Technical Fee"] =
        selectedOffer?.legal_technical_fee || "N/A";
      feesObj["E-Stamp Charges"] = selectedOffer?.e_stamp_charge || "N/A";
      feesObj["Due Diligence Charges"] =
        selectedOffer?.due_diligence_charge || "N/A";
      feesObj["CERSAI Charges"] = selectedOffer?.CERSAI_charge || "N/A";
      feesObj["MODT Charges"] = selectedOffer?.MODT_charge || "N/A";
      feesObj["NOI Charges"] = selectedOffer?.NOI_charge || "N/A";
      setAllFees(feesObj);
      setShowFees(true);
    } else {
      setAllFees({});
      setShowFees(false);
    }
  }

  const filters = [
    { id: 1,   label: "Loan Amount: High to Low" },
    { id: 2,   label: "Loan Amount: Low to High" },
    { id: 3,   label: "Rate of Interest: Low to High" },
    
  ];

  const   handleViewFeeModal = (bankName,index) => {
    // gtmEventTrigger("CustomerPage_QuotePage_View_Fees_Clicked","CustomerPage","QuotePage_View_Fees_Clicked",bankName)
    const selectedOffer = bankOffer[index];
    const feesObj = {
      ...(selectedOffer?.processingText ? { "Processing Fees": selectedOffer.processingText.replace("Processing Fee:", "").trim() } : {}),
      ...(selectedOffer?.login_fee ? { "Login Fee": selectedOffer.login_fee.replace("Login Fee:", "").trim() } : {}),
      ...(selectedOffer?.ac_handling_charge ? { "Account handling charges/Other Charges": selectedOffer.ac_handling_charge } : {}),
      ...(selectedOffer?.legal_technical_fee ? { "Legal and Technical Fee": selectedOffer.legal_technical_fee.replace("Processing Fee:", "").trim() } : {}),
      ...(selectedOffer?.e_stamp_charge ? { "E-Stamp Charges": selectedOffer.e_stamp_charge.replace("Processing Fee:", "").trim() } : {}),
      ...(selectedOffer?.due_diligence_charge ? { "Due Diligence Charges": selectedOffer.due_diligence_charge } : {}),
      ...(selectedOffer?.CERSAI_charge ? { "CERSAI Charges": selectedOffer.CERSAI_charge } : {}),
      ...(selectedOffer?.MODT_charge ? { "MODT Charges": selectedOffer.MODT_charge } : {}),
      ...(selectedOffer?.NOI_charge ? { "NOI Charges": selectedOffer.NOI_charge } : {}),
    };
    setAllFees(feesObj);
    setShowModal(true);
    document.body.classList.add("overflow-hidden");
  } 

  const handleCloseFeeModal = () => {
    setShowModal(false);
    document.body.classList.remove("overflow-hidden");
  };
  return (
    <>
      <div className="bankoffermainCONT">
      <div className="loantype">Best Offers</div>

      
      <div className="bankofferCONTPC">
        <div className="" style={{width:"75%"}}>
      {loanDetails?.requiredLoanAmount > 0 && (
        <>
          {/* <div className="bankofferselect"> */}
          {/* <div className="bankselectbox">
          <div
          style={{
            display: "flex",
            gap: "100px",
            marginBottom: "20px",
            width: "100%",
          }}
          >
            <div style={{ width: "80%", marginTop: "8px" }}>
                <div className="newloancomon">
                  <label>Desired Amount</label>
                  <div className="newmainamount">
                    <div>₹ {getNumberFormat_IN(loanDetails?.maxLoan)}</div>
                  </div>
                </div>

                <div className="range">
                  <input
                    type="range"
                    id="loan_amount"
                    name="loan_amount"
                    className="styled-slider slider-progress"
                    step="10000"
                    onChange={handleAmountSlider}
                    min={loanDetails?.minLoanAmount}
                    max={loanDetails?.maxEligibleLoanAmount}
                    value={loanDetails?.maxLoan}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.minLoanAmount)}
                    </label>
                  </div>
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.maxEligibleLoanAmount)}
                    </label>
                  </div>
                </div>
              </div>

            </div>
          </div> */}

          {/* <div className="banktipscard">
          <img
            src="https://static.ambak.com/saathi/1729775917444.png"
            alt="tips"
          />
          <div>
            Income allows for a higher loan of upto
            <span>
              {" "}
              ₹ {getNumberFormat_IN(loanDetails?.maxLoanAmount || 0)}
            </span>{" "}
            customer can increase their property budget!
          </div>
        </div> */}
          {/* </div> */}
          
          <div className="row offertenureflex">
          <div className="col-sm-2" style={{marginTop:20}}>

          <div className="sorthidemobile">
             <div className="sortbyfiltter">
              Sort By 
              <i className="ic-keyboard_arrow_down" style={{fontSize:20}}></i>
             <div className="showpcfilter">
              <ul className="sortfilltercard">
                  {filters.map((filter) => (
                    <li key={filter.id} onClick={() => setSortValue(filter.id)}>
                      <div className="sortlisting">
                        <div>{filter.label}</div>
                        <div>
                          <input
                          type="radio"
                          name="sort"
                          checked={sortValue === filter.id}
                          onChange={() => setSortValue(filter.id)}
                        />
                       </div>
                      </div>
                    </li>
                ))}
              </ul>
             </div>
             </div>
           </div>
             
          </div>
            <div className="col-sm-2">
              <label>Tenure</label>
              <div className="offerselect-container">
              <Select
                id="company_type"
                onChange={(data) => handleTenureChange(data)}
                options={getMaxTenureOptions(
                  MIN_TENURE_YEAR,
                  loanDetails?.maxTenure
                )}
                name="company_type"
                placeholder="Company Type"
                value={getMaxTenureOptions(
                  MIN_TENURE_YEAR,
                  loanDetails?.maxTenure
                ).find((obj) => obj.id == loanDetails.tenure)}
              />
              {/* <i className="ic-createmode_editedit"></i> */}
              </div>
            </div>
            {/* <div
              className="col-sm-2 bankofferrecalculate"
              onClick={handleRecalculate}
              style={{ marginTop: "30px" }}
            >
              Recalculate
            </div> */}
          </div>
        </>
      )}
      {loading ? (
        <PageLoader loaderClass="loading" />
      ) : (
        <div>
          {bankOffer.map((item, index) => (
            <div className="bankoffercard">
              <div className="pcoffercard">
                <div className="offerpadding">
                  
                  <div className="priceofferbox">
                  <div className="bankofferbox">
                    <div className="bankcheckbox">
                      <input
                        type="checkbox"
                        name="bank_check"
                        id="bank_check"
                        checked={checkedOffers.includes(item)}
                        onChange={(e) =>
                          handleCheckboxChange(item, e.target.checked)
                        }
                      />
                    </div>
                    <div className="banklogo">
                      <img
                        src={item.bankLogo}
                        alt="thankyou"
                        width={50}
                        height={50}
                      />
                      {/* <span>{item.bankName}</span> */}
                    </div>
                  </div>
                    <div>
                      <div className="offerprice">
                        ₹ {getNumberFormat_IN(item.eligibalLoanAmount || 0)}
                      </div>
                      {/* <div className="bankonwords">Loan Amount</div> */}
                    </div>
                    <div>
                      <div className="offerprice">{item.minRoi}%*</div>
                      {/* <div className="bankonwords">Rate of interest</div> */}
                    </div>
                    <div>
                      <div className="offerprice">
                        ₹ {getNumberFormat_IN(item.monthlyEMI || 0)}*<span style={{fontWeight:400,fontSize:12}}>/MO</span>
                      </div>
                      {/* <div className="bankonwords">Monthly EMI</div> */}
                    </div>
                    <div className="offertimecard">
                       <img src="https://static.ambak.com/public/image/timeicon.png" alt="Ambak Logo" width={16} height={16}/>
                      <div className="offerpric">{item.maxTenure} years</div>
                      {/* <div className="bankonwords">Tenure</div> */}
                    </div>
                  </div>
                  <div className="offergst">
                    <span></span>
                    {item.processingText}
                  </div>
                </div>
                {/* <div className="mobileonwordshide">*onwards</div> */}

               {
                index === 0 && 

                <div className="offerrecommended"> Recommended</div>
               }

                <div className="appylbtnborder">
                  <button
                    className="applybtn"
                    onClick={() => handleApplyOffer(item)}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="offerfeeslist">
                <div className="offerpricelist">
                  <div className="processingfees">


                 <div className="offerfeesflex">
                  <div className="offergstcard"
                      onMouseEnter={() => handleHover('mouse_enter', index)}
                      onMouseLeave={() => handleHover('mouse_leave', index)}
                    >
                      <div className="offergstpc">
                        <span></span>
                        {item.processingText}
                      </div>
                      <i
                        className="ic-keyboard_arrow_down"
                       
                      ></i>

                        {showFees && (
                          <div className="offershowfees">
                            {Object.keys(allFees)?.map((feeKey, index) => (

                              allFees[feeKey] !== "N/A" &&
                              <div key={index}>
                                <div className="offerlistingfees">
                                  <div className="feesheading" style={{ fontSize: 12, paddingBottom: 10 }}>
                                    <ul style={{ paddingLeft: "20px" }}>

                                      <li style={{ listStyle: "initial" }}>
                                        {feeKey}: <span className="feessubheading">{allFees[feeKey]}</span>
                                      </li>

                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>

                      {

                  item?.uspData?.length > 0 &&
                          <div className="offerfast">
                            <img src={item?.uspData[0]?.logo} alt="fast" width={20} height={20} />
                            <div className="hidefasttext">{item?.uspData[0]?.usp_text}</div>
                          </div>
                        
                      }

                    </div>





                    {/* <div
                      style={{
                        color: "blue",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleFessPopup(index)}
                    >
                      View All Fees
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div style={{ fontSize: 12 }}>
        *All offers are subject to document proofs submitted and lender policies
        and approval.
      </div>
      </div>

      
      {checkedOffers.length > 0 && (
        <div className="bankofferfixed">
          <div className="bankselected">
            <div style={{ width: "100%", padding: "0px 10px" }}>
              <span> {checkedOffers.length} Offers shortlisted</span>
            </div>
            <button
              className="selectedbankshare"
              onClick={handleSavetoLead}
              style={{ marginRight: "20px" }}
            >
              Save to Lead
            </button>
            <button className="selectedbankshare" onClick={handleCopyOffer}>
              Copy to Clipboard
            </button>
          </div>
        </div>
      )}
     <div className="offerflotleft">
      <div className="offercheckhere">
        <div className="offercreditflex">
             <div style={{width:"60%"}}>
             <div className="offercreditheading">Check Free Credit Score to Accurate offers here!</div>
             <a href="/my-tool-cibil">
              <button className="clickofferbtn">Click Here</button>
             </a>
             </div>
             <div style={{width:"40%"}}>
               <img src="https://static.ambak.com/images/cibil.png" alt="Ambak Logo" width={120} height={70}/>
             </div>
        </div>
      </div>

      <div className="offercallcard">
        <div className="offercallflex">
          <div>
            <img src="https://static.ambak.com/images/operator.png" alt="Ambak Logo" width={70} height={70}/>
          </div>
          <div>
            <div className="offerneed">Need Guidance?</div>
            <div className="offersubneed">Talk to a Personal Advisor!</div>
          </div>
        </div>

        <div className="offerbtncard">
        <button className="offercallbtn" onClick={()=>setCallUsModal(true)}>
            <img src="https://static.ambak.com/images/call.png" alt="Ambak Logo" width={20} height={20}/>
        <div >Call Now</div>
        </button>
        {/* <button className="offerscheduldebtn">
            <img src="https://static.ambak.com/images/date.png" alt="Ambak Logo" width={20} height={20}/>
        <div>Schedule</div>
        </button> */}
        </div>

      </div>

      </div>
      </div>
      {loading ? (
        <PageLoader loaderClass="loading" />
      ) : (
    <div className="offerSection">
    {bankOffer.map((data, index) => (
      <div className="hidepcbankoffer">
        <div className="newoffercard">
          <div className="offerfastcard">

            {/* {
              !data?.uspData.length ? <div></div> : ""
            } */}
            {
              data?.uspData?.map((usp) =>
                <div className="offerfast">
                  <div>
                  <img src={usp?.logo.includes("http") ? usp.logo : ""}alt="fast" width={16} height={16} />
                  </div>
                  {/* <div>{"usp?.usp_text"}</div> */}
                  <div className="hidefasttext"> {usp?.usp_text}</div>
                </div>
              )
            }

            {
              index === 0 &&
              <div className="offerrecommendedM">Recommended</div>
            }

          </div>
          <div className="offerCard1">
            <div className="cardTop">
              <div className="cardTopLeft">
                <img
                src={data.bankLogo}
                  width={50}
                  height={50}
                  alt={data?.bankName || "bankLogo"}
                  title={data?.bankName || "BankLogo"}
                  loading='lazy' />
              </div>


              <div className="timeoffercard">
                {/* <div className=cardHeading}>Max Tenure</div> */}
                <img src="https://static.ambak.com/public/image/timeicon.png"
                  width={16}
                  height={16}
                  alt="time"
                  loading='lazy' />

                <div className="tenurevalue">{data.maxTenure} years</div>
              </div>

            </div>
            <div className="cardBottom">
              <div className="cardDetailsBox">
                {/* <div className=cardHeading}>Max Amount</div> */}
                <div className="cardValue">
                ₹ {getNumberFormat_IN(data.eligibalLoanAmount || 0)}
                  </div>
              </div>
              <div className="cardDetailsBox">
                {/* <div className=cardHeading}>ROI</div> */}
                <div className="cardValue">{data.minRoi}%*</div>
              </div>
              <div className="cardDetailsBox">
                {/* <div className=cardHeading}>EMI Amount</div> */}
                <div className="cardValue">
                ₹ {getNumberFormat_IN(data.monthlyEMI || 0)}*<span className="MOspan">/MO</span>
                  </div>
               
              </div>


            </div>
            <div className="cardDetailsBox">
              {/* <div className=cardHeading}>Fee Details</div> */}
              <div className="offerfeescard" 
              onClick={(e) => handleViewFeeModal(data.bankName,index)}
              >
                <div className="breviewfees">{data?.processingText?.split(" ").slice(0, 5).join(" ")}...
                  
                  {/* <HiChevronDown style={{ fontSize: 16 }} /> */}
                  </div>
                  <div> 
                <i className="ic-keyboard_arrow_down"></i>
                 </div>

              </div>
              <div className="cardTopRight" >
                {/* <FiArrowRight className=arrowButton} onClick={() => handleLeadCreate(data.bankId)} /> */}
                <div className="applyNowButton" 
                
                // onClick={() => {
                //   handleLeadCreate(data.bankId);
                //   gtmEventTrigger("CustomerPage_QuotePage_Apply_Now_Clicked", "CustomerPage", "QuotePage_Apply_Now_Clicked", data.bankName)
                // }}
                onClick={() => handleApplyOffer(data)}
                
                >
                  Apply Now
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
))}
    </div>
      )}
              <div className="callstatuscard">
                  <div className="offercallcard1">
                    <div>
                      <img src="https://static.ambak.com/images/operator.png"
                        width={46}
                        height={46}
                        alt="time"
                        loading='lazy'
                      />
                    </div>

                    <div>
                      <div className="offerneed">Need Guidance?</div>
                      <div className="offertalk">Talk to a Personal Advisor!</div>
                    </div>
                    {/* <div className={style.offerdatesched}>
               
                  <Image src="/image/date.png"
                    width={16}
                    height={16}
                    alt="time"
                    loading='lazy' />
                    
                    <div className={style.offertalk}>Schedule</div>
                   </div> */}

                    <div className="offerdatesched" onClick={()=>setCallUsModal(true)}>
                      <img src="https://static.ambak.com/images/call.png"
                        width={16}
                        height={16}
                        alt="time"
                        loading='lazy' />

                      <div className="offertalk" 
                      // onClick={OpenCallPopup}
                      >Call Now</div>
                    </div>
                    {/* {callBackModal && <GetInTouchWithUs handleClose={CallPopupClose} loadedFrom={`${pathname}_speak_to_expert`} />} */}

                  </div>
                </div>
                </div>
      <Modal isOpen={ShowAllFees} togglePopup={toggleFessPopup} width={700}>
        {Object.keys(allFees)?.map((feeKey, index) => (
          <div key={index}>
            <div className="offerlistingfees">
              <div
                className="feesheading"
                style={{ fontSize: 12, paddingBottom: 10 }}
              >
                <ul style={{ paddingLeft: "20px" }}>
                  <li style={{ listStyle: "initial" }}>
                    {feeKey} :{" "}
                    <span className="feessubheading">{allFees[feeKey]}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </Modal>

      <Modal
        isOpen={showView}
        togglePopup={togglePopup}
        width={900}
        removeHeight={true}
      >
        <NewLeadLoanOffer
          skipAndContinue={handleSkipAndContinue}
          leadCreated={handleLeadCreated}
        />
      </Modal>

      {showSuccess && (
        <Modal
          isOpen={showSuccess}
          togglePopup={toggleSuccessPopup}
          width={900}
          removeHeight={true}
        >
          <div className="newleadthankyou">
            <img
              src={require(`../../../src/saathi-web/images/thankyouimg.svg`)}
              alt="thankyou"
              width={300}
            />
            <h4>Offer Shortlisted!</h4>
            <p>
              Your preference for{" "}
              {checkedOffers.length > 0
                ? `${checkedOffers.length} Banks`
                : `${bankDetails[0]?.bankName} Bank`}{" "}
              has been saved.
            </p>
            <p>Go to the lead to continue to Login</p>
          </div>
          <div className="newleadbtnflex">
            {leadId && (
              <button className="submitbtnnewlead" onClick={handleGoToLead}>
                Go to Lead
              </button>
            )}
          </div>
        </Modal>
      )}

      {
        callUsModal && 
        <Modal 
        isOpen={callUsModal}
        togglePopup={(prev)=>setCallUsModal(!prev)}
        width={600}
        removeHeight={true}
        >
          <ContactUsPopup/>
        </Modal>
      }
<BottomModal isOpen={showModal} closeModal={handleCloseFeeModal} width={800} showCloseButton={false}>
          <div className="feesContainer">
            <div className="feesTopSection">
              <div className="feesTopHeadingSection">
                <div>Fee Details</div>
                {/* <IoCloseCircleOutline className={style.closeModalCross} onClick={() => handleCloseFeeModal()} /> */}
              </div>
              <div className="feesContextContainer">
                {Object.keys(allFees)?.map((feeKey, feeIndex) => (
                  <div className="offerlistingfees1" key={feeIndex}>
                    <div className="feesheading" style={{ fontSize: 12 }}>
                      <ul style={{ paddingLeft: "20px" }}>
                        <li style={{ listStyle: "initial" }}>
                          {feeKey} :{" "}
                          <span className="feessubheading">{allFees[feeKey]}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="newleadbtnflex">
              <button className="submitbtnnewlead1" onClick={() => handleCloseFeeModal()}>
                Got It!
              </button>
            </div>
          </div>
        </BottomModal>
    </>
  );
};

export default BankOfferList;
