import React, { useEffect, useState, useSyncExternalStore } from "react";
import LoanOfferBasicDetails from "./LoanOfferBasicDetails";
import LoanOfferIncomeDetails from "./LoanOfferIncomeDetails";
import LoanOfferCoApplicant from "./LoanOfferCoApplicant";
import LoanOfferProperty from "./LoanOfferProperty";
import Modal from "../../../../view/common/Modal";
import NewLeadLoanOffer from "./NewLeadLoanOffer";
import BankOfferList from "../../../CibilScore/BankOfferList";
import { toast } from "react-toastify";
import { GET_BRE_OFFERS } from "../../../../queries/sangam/bre.gql";
import { getNumberFormat_IN } from "../../../MyLeads/helpers/helpers";
import dateFormat from "dateformat";
import { useCleverTap } from '../../../CleverTapContext';
import MasterService from "../../../../service/MasterService";

import {
  executeGraphQLMutationFinex,
  executeGraphQLQueryFinex,
} from "../../../../common/executeGraphQLQuery";
import {
  GET_LOAN_OFFER,
  SAVE_LOAN_OFFER,
} from "../../../../queries/sangam/create.gql";

const PROPERTY_TYPE_MAPPING_OBJ = {
  9: "resale",
  11: "builder_endorsement",
  12: "builder_allotment",
  13: "authority_allotment",
};

const PROPERTY_SUB_TYPE_MAPPING_OBJ = {
  1: "ready_to_move",
  4: "contruction_linked",
  2: "plot",
  3: "plot_contruction",
};

const PROPERTY_REGISTRATION_TYPE_MAPPING_OBJ = {
  1: "khasra_khatoni_laldora",
  2: "regularised",
  3: "khasra_khatoni_laldora",
  4: "khasra_khatoni_laldora",
  5: "khasra_khatoni_laldora",
  6: "freehold",
  7: "leasehold",
};

const ADDITIONAL_INCOME_MAPPING_OBJ = {
  1: "rent_amount",
  2: "annual_bonus_amount",
  3: "monthly_incentives_amount",
  4: "monthly_reimbursements_amount",
  5: "others",
  6: "liquid_amount",
};

const LoanAllInfo = ({
  bankOfferVisibility,
  showBankOfferList,
  leadDetail,
  breData,
  handleBreDataChange,
  masterdata,
  loanType
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [currentStep, setCurrentStep] = useState(1);
  const [showView, setShowView] = useState(false);
  const [bankOffer, setBankOffer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newLeadId, setNewLeadId] = useState(null);
  const [shortlistedOffers, setShortlistedOffers] = useState([]);
  const [propertyType , setPropertyType] = useState("")
  const [sortValue, setSortValue] = useState()
  const [loanTenure, setLoanTenure] = useState("");
  

  const trackEvent = useCleverTap();

  const convertValuesToNumeric = (obj) => {
    const finalObj = {};
    for (let key in obj) {
      finalObj[key] = +obj[key];
    }
    return finalObj;
  };
  const handleDataFromPropertyComponent = (data) => {
    setPropertyType(data)
  }
  const shortlistOffers = async (listOfOffers, lead_id) => {
    try {
      if (lead_id) {
        const response = await executeGraphQLQueryFinex(
          GET_LOAN_OFFER(lead_id)
        );
        if (response.data.get_loan_offer) {
          const isAnyOfferShortlisted = response.data.get_loan_offer;
          if (!isAnyOfferShortlisted.length) {
            setShortlistedOffers(listOfOffers);
            const offerToSave = listOfOffers.map((obj) => {
              return {
                lead_id,
                roi: obj.minRoi,
                emi: +obj.monthlyEMI?.replaceAll(",", ""),
                loan_amount: +obj.eligibalLoanAmount?.replaceAll(",", ""),
                bank_id: obj.bankId,
                processing_fee: obj.processingText,
              };
            });
            const mutation = SAVE_LOAN_OFFER;
            const variables = {
              partner_id: loginUserInfo.user_data.dealer_id,
              api_called_by: "saathi_web",
              loanOfferInput: offerToSave,
            };
            await executeGraphQLMutationFinex(mutation, variables);
            setShortlistedOffers(offerToSave);
          } else {
            setShortlistedOffers(isAnyOfferShortlisted);
          }
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getBankOffers = async (recalculatedObj = {}) => {
    setLoading(true);
    const {
      lead_id,
      loan_amount,
      profession,
      mobile,
      dob,
      gross_monthly_salary,
      monthly_salary,
      existing_emi_amount,
      emi_ending_six_month,
      is_additional_income,
      additional_income,
      is_co_applicant,
      co_applicant,
      tenure,
      cibil_score,
      income_calculation_mode,
      year_1,
      year_2,
      no_of_accounts,
      is_property_identified,
      expected_property_value,
      property_type,
      property_sub_type,
      property_agreement_value,
      property_city,
      agreement_type,
    } = breData;

    let loanAmount = "",
      maxTenure = "";

    const isPropDec = is_property_identified
      ? is_property_identified.charAt(0).toUpperCase() +
        is_property_identified.slice(1)
      : "No";

    if (Object.keys(recalculatedObj).length) {
      loanAmount = recalculatedObj?.loan_amount;
      maxTenure = `${recalculatedObj?.max_tenure}`;
    } else {
      loanAmount = loan_amount;
      maxTenure = tenure ? `${tenure}` : null;
    }

    const other_income = {};
    let isCoAppAddIncConsider = "No";

    const isAddInc =
      profession !== 1 && income_calculation_mode === "bank"
        ? "No"
        : is_additional_income
        ? is_additional_income.charAt(0).toUpperCase() +
          is_additional_income.slice(1)
        : "";

    const isCo = is_co_applicant
      ? is_co_applicant.charAt(0).toUpperCase() + is_co_applicant.slice(1)
      : "";

    if (isAddInc === "Yes") {
      const filteredAdd = additional_income?.filter(
        ({ status }) => status == "1"
      );
      for (let i = 0; i < filteredAdd?.length; i++) {
        other_income[
          ADDITIONAL_INCOME_MAPPING_OBJ[filteredAdd[i].income_type_id]
        ] = {
          amount: +filteredAdd[i].amount,
          payment_mode: filteredAdd[i].amount_type,
        };
      }
    }

    let coApplicants = [];
    if (isCo === "Yes") {
      coApplicants = co_applicant?.map((coApp) => {
        const isCoAddInc = coApp.ca_is_additional_income
          ? coApp.ca_is_additional_income.charAt(0).toUpperCase() +
            coApp.ca_is_additional_income.slice(1)
          : "";

        const ca_applicant_other_income = {};

        if (isCoAddInc === "Yes") {
          isCoAppAddIncConsider = isCoAddInc;
          const filteredAdd = coApp.ca_additional_income?.filter(
            ({ status }) => status == "1"
          );
          for (let i = 0; i < filteredAdd.length; i++) {
            if (
              ADDITIONAL_INCOME_MAPPING_OBJ[filteredAdd[i].ca_income_type_id]
            ) {
              ca_applicant_other_income[
                `ca_${
                  ADDITIONAL_INCOME_MAPPING_OBJ[
                    filteredAdd[i].ca_income_type_id
                  ]
                }`
              ] = {
                amount: +filteredAdd[i].ca_amount,
                payment_mode: filteredAdd[i].ca_amount_type,
              };
            }
          }
        }

        const finalObj = {};
        if (+coApp.ca_profession === 3) {
          finalObj["income_calculation_mode"] =
            coApp?.ca_income_calculation_mode === "bank"
              ? "bank_income"
              : coApp?.ca_income_calculation_mode;
          if (coApp?.ca_income_calculation_mode === "itr") {
            const itrObj = {};
            if (coApp.year_1) {
              const parseYear1 = convertValuesToNumeric(coApp.year_1);
              itrObj["year1"] = {
                npat: parseYear1["npat"],
                depreciation: parseYear1["depreciation"],
                interest: parseYear1["interest"],
                tax_paid: parseYear1["tax_paid"],
              };
            }
            if (coApp.year_2) {
              const parseYear2 = convertValuesToNumeric(coApp.year_2);
              itrObj["year2"] = {
                npat: parseYear2["npat"],
                depreciation: parseYear2["depreciation"],
                interest: parseYear2["interest"],
                tax_paid: parseYear2["tax_paid"],
              };
            }
            if (Object.keys(itrObj).length === 0) {
              const obj = {};
              for (let i = 0; i < coApp?.ca_offer_itr_details?.length; i++) {
                const current = coApp?.ca_offer_itr_details[i];
                if (current.itr_year === "1") {
                  obj["year_1"] = {
                    npat: current.npat,
                    depreciation: current.depreciation,
                    interest: current.interest,
                    tax_paid: current.tax_paid,
                  };
                }
                if (current.itr_year === "2") {
                  obj["year_2"] = {
                    npat: current.npat,
                    depreciation: current.depreciation,
                    interest: current.interest,
                    tax_paid: current.tax_paid,
                  };
                }
              }
              if (obj.year_1) {
                const parseYear1 = convertValuesToNumeric(obj.year_1);
                itrObj["year1"] = {
                  npat: parseYear1["npat"],
                  depreciation: parseYear1["depreciation"],
                  interest: parseYear1["interest"],
                  tax_paid: parseYear1["tax_paid"],
                };
              }
              if (obj.year_2) {
                const parseYear2 = convertValuesToNumeric(obj.year_2);
                itrObj["year2"] = {
                  npat: parseYear2["npat"],
                  depreciation: parseYear2["depreciation"],
                  interest: parseYear2["interest"],
                  tax_paid: parseYear2["tax_paid"],
                };
              }
            }
            finalObj["ITR"] = itrObj;
          } else if (coApp?.ca_income_calculation_mode === "bank") {
            const bankIncomes = [];
            for (let i = 0; i < coApp.no_of_accounts; i++) {
              if (coApp[`bank_account_type_${i}`]) {
                bankIncomes.push({
                  ac_type: coApp[`bank_account_type_${i}`],
                  avg_balance: +coApp[`bank_account_avg_amount_${i}`],
                });
              } else {
                break;
              }
            }
            for (let i = 0; i < coApp?.ca_offer_bank_details?.length; i++) {
              const current = coApp?.ca_offer_bank_details[i];
              bankIncomes.push({
                ac_type: current?.account_type,
                avg_balance: +current?.amount,
              });
            }
            finalObj["bankIncome"] = bankIncomes;
          }
        }

        return {
          ca_profession: +coApp.ca_profession,
          ...(+coApp.ca_profession === 1
            ? { ca_monthly_salary: +coApp.ca_monthly_salary || null }
            : { ca_annual_income: +coApp.ca_annual_income || null }),
          ca_gross_monthly_salary: +coApp.ca_gross_monthly_salary || null,
          ...(coApp.ca_is_obligation === "yes"
            ? {
                ca_emi_ending_six_month: +coApp.ca_emi_ending_six_month,
                ca_existing_emi_amount: +coApp.ca_existing_emi_amount,
              }
            : {}),
          ca_applicant_other_income,
          ...finalObj,
        };
      });
    }

    const breOfferInput = {
      sort:sortValue,
      loan_type: "",
      lead_details: {
        lead_id,
        loan_amount: loanAmount,
        profession: `${profession}`,
        cibil_score: Number(cibil_score) || -1,
        existing_emi_amount,
        ...(maxTenure ? { max_tenure: maxTenure } : {}),
        emi_ending_six_month: +emi_ending_six_month || 0,
        is_property_decided: isPropDec,
        ...(profession !== 1
          ? {
              income_calculation_mode:
                income_calculation_mode === "bank"
                  ? "bank_income"
                  : income_calculation_mode,
            }
          : {}),
          max_tenure:loanTenure
      },
      customer: {
        mobile,
        pancard_no: "",
        dob: `${dateFormat(dob, "dd/mm/yyyy")}`,
      },
      is_additional_income: `${isAddInc}`,
      other_income,
      is_co_additional_income: isCoAppAddIncConsider,
      co_applicant: coApplicants,
      fulfillment_type: "",
    };

    if (profession === 1) {
      breOfferInput.lead_details["monthly_salary"] = +monthly_salary;
      breOfferInput.lead_details["gross_monthly_salary"] = gross_monthly_salary
        ? +gross_monthly_salary
        : null;
    }

    if (profession === 3) {
      if (income_calculation_mode === "itr") {
        const itrObj = {};
        if (year_1) {
          const parseYear1 = convertValuesToNumeric(year_1);
          itrObj["year1"] = {
            npat: parseYear1["npat"],
            depreciation: parseYear1["depreciation"],
            interest: parseYear1["interest"],
            tax_paid: parseYear1["tax_paid"],
          };
        }
        if (year_2) {
          const parseYear2 = convertValuesToNumeric(year_2);
          itrObj["year2"] = {
            npat: parseYear2["npat"],
            depreciation: parseYear2["depreciation"],
            interest: parseYear2["interest"],
            tax_paid: parseYear2["tax_paid"],
          };
        }
        breOfferInput["ITR"] = itrObj;
      } else if (income_calculation_mode === "bank") {
        const bankIncomes = [];
        for (let i = 0; i < no_of_accounts; i++) {
          bankIncomes.push({
            ac_type: breData[`bank_account_type_${i}`],
            avg_balance: +breData[`bank_account_avg_amount_${i}`],
          });
        }
        breOfferInput["bankIncome"] = bankIncomes;
      }
    }

    if (isPropDec === "Yes") {
      const obj = {
        property_city,
        expected_market_value: expected_property_value
          ? +expected_property_value
          : null,
        property_type: Number(PROPERTY_TYPE_MAPPING_OBJ[property_type]),
        property_sub_type: PROPERTY_SUB_TYPE_MAPPING_OBJ[property_sub_type],
        ...(property_type === 9 || property_type === 13
          ? {
              agreement_type:
                PROPERTY_REGISTRATION_TYPE_MAPPING_OBJ[agreement_type],
            }
          : {}),
        registration_value: +property_agreement_value,
      };
      breOfferInput["property_details"] = obj;
    }
    
    if (loanType === "loan_against_property") {
      let lapOfferInput = breOfferInput
      if (!lapOfferInput.lead_details) {
        lapOfferInput.lead_details = {};
      }
    
      lapOfferInput["property_details"] = {
        expected_market_value: breData?.expected_property_value,
        property_type: propertyType?.label?.replace(/\s+/g, '_').toLowerCase(),
        property_usage: breData?.property_usage,
        occupancy_status: breData?.occupancy_type,
      };
      lapOfferInput.lead_details["is_property_identified"] = "yes";
    
      try {
        const response = await MasterService.post(
          "/process-rule/get-lap-bank-offers",
          lapOfferInput
        ) .then((resp) => {

          if (resp.data) {
            // const jsonData = JSON.parse(resp.data);
            if (resp.data.data) {
              const respData = resp.data.data || [];

              console.log({ API_RESPONSE: respData });
  
              // respData.sort(
              //   (a, b) => b.eligibalLoanAmount - a.eligibalLoanAmount
              // );
              const offerData = respData?.map((obj) => {
                return {
                  ...obj,
                  netMonthlyIncome: getNumberFormat_IN(
                    obj?.netMonthlyIncome || 0
                  ),
                  eligibalLoanAmount: getNumberFormat_IN(
                    obj?.eligibalLoanAmount || 0
                  ),
                  monthlyEMI: getNumberFormat_IN(Math.ceil(obj?.monthlyEMI) || 0),
                };
              });
            
              shortlistOffers(offerData.slice(0, 3), lead_id);
              setBankOffer(offerData);
              setShowView(false);
              bankOfferVisibility(true);
              setLoading(false);
            } else {
              console.log("🔻 ERROR : ", resp.data);
              toast.error(resp.data.message);
              setLoading(false);
            }
              
          }
         
      });
        return;
      } catch (error) {
        console.error("Error fetching LAP bank offers:", error);
        return;
      }
    }
    console.log({ API_REQUEST_BODY: breOfferInput });

    const variables = { breOfferInput };
    const mutation = GET_BRE_OFFERS;
    executeGraphQLMutationFinex(mutation, variables)
      .then((resp) => {
        if (resp.data?.get_bre_offers) {
          const jsonData = JSON.parse(resp.data.get_bre_offers);
          if (jsonData.data) {
            const respData = jsonData.data || [];

            console.log({ API_RESPONSE: respData });

            // respData.sort(
            //   (a, b) => b.eligibalLoanAmount - a.eligibalLoanAmount
            // );
            const offerData = respData?.map((obj) => {
              return {
                ...obj,
                netMonthlyIncome: getNumberFormat_IN(
                  obj?.netMonthlyIncome || 0
                ),
                eligibalLoanAmount: getNumberFormat_IN(
                  obj?.eligibalLoanAmount || 0
                ),
                monthlyEMI: getNumberFormat_IN(Math.ceil(obj?.monthlyEMI) || 0),
              };
            });
          
            shortlistOffers(offerData.slice(0, 3), lead_id);
            setBankOffer(offerData);
            setShowView(false);
            bankOfferVisibility(true);
            setLoading(false);
          } else {
            console.log("🔻 ERROR : ", jsonData);
            toast.error(jsonData.message);
            setLoading(false);
          }
            
        }
       
    })
      .catch((error) => {
        setLoading(false);
        console.log(`🔻 ERROR : ${error.message}`);
        toast.error("Something went wrong");
      });
  };

  const togglePopup = () => {
    const exVal = localStorage.getItem("showLeadCreatePopup");
    localStorage.setItem("showLeadCreatePopup", !exVal);

    setShowView((prevShowView) => !prevShowView);

    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const handleSkipAndContinue = (data) => {
    togglePopup();
    getBankOffers();
  };

  const handleStepClick = (step) => {
    if (step == 1) trackEvent("cta_clicked", "offer_engine_form_nav_page_1");
    if (step == 2) trackEvent("cta_clicked", "offer_engine_form_nav_page_2");
    if (step == 3) trackEvent("cta_clicked", "offer_engine_form_nav_page_3");
    if (step == 4) trackEvent("cta_clicked", "offer_engine_form_nav_page_4");
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      if (prevStep < 4) {
        return prevStep + 1;
      }
      return prevStep;
    });
  };

  const handleViewOffer = () => {
    if (Object.keys(leadDetail).length) {
      getBankOffers();
    } else {
      const isPopupShow = localStorage.getItem("showLeadCreatePopup");
      if (isPopupShow === "true") return togglePopup();
      return getBankOffers();
    }
  };

  const handleLeadCreated = (lead_id) => {
    setNewLeadId(lead_id);
    handleSkipAndContinue();
  };

  const handleFetchOffer = (recalculatedObj) => {
    getBankOffers(recalculatedObj);
  };

  useEffect(()=>{
    if(sortValue || loanTenure)
    getBankOffers();
  },[sortValue,loanTenure])

  return (
    <>
      {showBankOfferList ? (
        <BankOfferList
          bankOffer={bankOffer}
          recalculateOffer={handleFetchOffer}
          loading={loading}
          leadDetail={leadDetail}
          newLeadId={newLeadId}
          shortlistedOffers={shortlistedOffers}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setLoanTenure={setLoanTenure}
        />
      ) : (
        <div className="editleadnew">
          <div className="lefteditleadcont">
            <section className="step-wizard">
              <ul className="step-wizard-list">
                {[1, 2, 3, 4].map((stepNumber) => (
                  <li
                    key={stepNumber}
                    className={`step-wizard-item ${
                      currentStep === stepNumber ? "current-item" : ""
                    }`}
                    onClick={() => handleStepClick(stepNumber)}
                  >
                    <span className="progress-count">{stepNumber}</span>
                  </li>
                ))}
              </ul>
              <ul className="Editlead_flowlis">
                <li onClick={() => handleStepClick(1)}>Basic Details</li>
                <li onClick={() => handleStepClick(2)}>Income</li>
                <li onClick={() => handleStepClick(3)}>Co-Applicant</li>
                <li onClick={() => handleStepClick(4)}>Property</li>
              </ul>
            </section>
          </div>
          <div className="righteditleadcont">
            {currentStep === 1 && (
              <LoanOfferBasicDetails
                breData={breData}
                handleBreDataChange={handleBreDataChange}
                masterdata={masterdata}
                setNextActive={handleNextStep}
              />
            )}
            {currentStep === 2 && (
              <LoanOfferIncomeDetails
                breData={breData}
                handleBreDataChange={handleBreDataChange}
                masterdata={masterdata}
                setNextActive={handleNextStep}
              />
            )}
            {currentStep === 3 && (
              <LoanOfferCoApplicant
                breData={breData}
                handleBreDataChange={handleBreDataChange}
                masterdata={masterdata}
                setNextActive={handleNextStep}
              />
            )}
            {currentStep === 4 && (
              <LoanOfferProperty
                breData={breData}
                handleBreDataChange={handleBreDataChange}
                masterdata={masterdata}
                viewOffer={handleViewOffer}
                loanType = {loanType}
                handleDataFromPropertyComponent ={handleDataFromPropertyComponent}
              />
            )}
          </div>

          <Modal isOpen={showView} togglePopup={togglePopup} width={900}>
            <NewLeadLoanOffer
              skipAndContinue={handleSkipAndContinue}
              leadCreated={handleLeadCreated}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default LoanAllInfo;
