import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import ContactUs from "./ContactUs";
import Accordion from "../../view/elements/Accordion";
import InputField from "../../view/elements/Input";
import HelpSupportTable from "./HelpSupportTable";
import ChatSupport from "./ChatSupport";
import { FRESH_DESK_API_KEY, FRESH_DESK_DOMAIN_URL } from "../../config/constant";
import { DealerService } from "../../service";
import { GET_TICKETS_CONVERSATION } from "../../queries/threads.gql";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import PageLoader from "../../view/elements/PageLoader";


const HelpAndSupport = (props) => {

  const updateStateIntoRedux = props.updateStateIntoRedux;


  const initialFAQs = [
    {
      question: 'How long will it take to get my code?',
      answer:
        'With Ambak, you can get your code instantly! Just sign up on the Ambak Saathi App or Web platform to get started. The signup process is free for everyone. Once registered, go to the menu, then to your profile—you’ll find your Ambak code there.',
    },
    {
      question: 'Are there any hidden charges for onboarding?',
      answer:
        'Not at all! Onboarding and platform usage (both app and web) are completely free with no hidden charges.',
    },
    {
      question: 'What if I onboard but don’t submit any files for a whole month?',
      answer:
        'No worries, partner! Your dedicated relationship manager will work closely with you to log files and grow your business. You don’t have to worry about your code being deactivated.',
    },
    {
      question: 'What if I decide not to work with Ambak after a certain time?',
      answer:
        'We’re confident this won’t happen, as partnering with Ambak brings you many benefits, from banker connections to timely payouts. However, if you wish to discontinue, simply inform your relationship manager. They will escalate it to the support team, and your code will be closed within 7 working days after receiving an official email from you, along with your reason for leaving.',
    },
    {
      question: 'What is the escalation matrix?',
      answer:
        'The escalation process is simple, with a promised response time of 1 working day. Go to the help and support section on your Saathi App, where you can escalate issues in two ways: 1. Click the “Contact Us” button to view your dedicated RM’s contact details and the central support team’s contact details. You can directly call, SMS, or WhatsApp them. 2. Click “Chat with Us” to get assistance from the Ambak bot, which will address your concerns and raise queries with the support team. The support team will then call you back within a day.',
    },
    {
      question: 'Will Ambak provide a legal agreement?',
      answer:
        'You will receive all confirmations from the official Ambak email, which is as valid as a written document. Additionally, when you sign up on the Ambak Saathi App/Web platform, you agree to the terms, conditions, and legal requirements outlined.',
    },
    {
      question: 'Can commissions be paid in cash?',
      answer:
        'No, commissions cannot be paid in cash. We strictly follow a no-cash policy.',
    },
    {
      question: 'Who will train my team on the Saathi app/web platform?',
      answer:
        'Your dedicated relationship manager will assist with all app and web platform training and address any queries.',
    },
    {
      question: 'Who should I contact for commission-related queries?',
      answer:
        'For any commission or payout-related queries, you can raise a ticket from the Help and Support section. Alternatively, go to the “My Earnings” section on the app, select the payout in question, click on “View Details,” and then on “Raise Query.” Your query will be registered with us, and our support team will reach out to you within 24 working hours.',
    },
  ];

  const [tabsName, setTabsName] = useState("faq");
  const [showchat,setShowChat] = useState(false);
  const [tickets,setTickets] = useState();
  const [filteredTickets,setFilteredTickets] = useState();
  const [partnerId,setPartnerId] = useState();
  const [currentChat, setCurrentChat] = useState([]);
  const [loginInfo, setLoginInfo] = useState();
  const [dealerShipEmail, setDealerShipEmail] = useState();
  const [sfaUserName, setSfaUserName] = useState();
  const [sfaUserEmail, setSfaUserEmail] = useState();
  const [conversations, setConservations] = useState({});
  const [faqs, setFaqs] = useState(initialFAQs);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();

  //  fetchData(loginInfo?.dealer_hash_id)
  }, [loginInfo?.dealer_hash_id]);



  useEffect(() => {
    if (tickets?.length) {
      setConservations([])
      tickets.forEach((ticket) => {
        fetchTicketConverstion(ticket.id);
      });
    }
  }, [tickets]);



  useEffect(() => {
    setIsLoading(true)
    const fetchFreshdeskData = async () => {
      const loginInfo = await secureStorage.getItem('loginUserInfo').data;

      setLoginInfo(loginInfo)
      setPartnerId(loginInfo?.dealer_id)
      const response = await getFreshDeskData(loginInfo?.dealer_id);

      if(response){

        setIsLoading(false)
      }
      if(response.length){
        setTickets(response);
        const currentTab = localStorage.getItem("currentTab")
        setTabsName(currentTab || "myticket")
      }
    };
  
    fetchFreshdeskData();
   
  }, [partnerId,currentChat]);


    useEffect(() => {
      getUserDetails()
    },[])
    useEffect(() => {
      setFilteredTickets(tickets)
    },[tickets])

  const getUserDetails = async () => {
    try {
      const dealer_hash_id = localStorage.getItem("dealer_hash_id");
      const postData = { 
        dealer_id_hash: dealer_hash_id, 
        source: "saathi_web", 
        _with: ["DIF"] 
      };
  
      const response = await DealerService.getBasicDetails(postData);
      setDealerShipEmail(response.data?.data[0].dealership_email);
      setSfaUserName(response.data?.data[0]?.sfa_refferal_user_name);
      setSfaUserEmail(response.data?.data[0]?.sfa_refferal_user_email)
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };



const fetchTicketConverstion = async (ticket_id) => {
    const res = { status: false, cnt: 0 }
      await executeGraphQLQueryFinex(GET_TICKETS_CONVERSATION(ticket_id))
        .then((response) => {
          if (response?.data?.get_all_tickets_from_freshdesk) {
            const jsonData = JSON.parse(
              response?.data?.get_all_tickets_from_freshdesk
            );

    
            res.status = true
            res.cnt = jsonData.length;

            if (localStorage.getItem(`count${ticket_id}`)) {
              // Calculate the unread count and update it in localStorage
              const previousCount = parseInt(localStorage.getItem(`count${ticket_id}`), 10);
              const unreadCount = jsonData.length - previousCount;
              
              localStorage.setItem(`unread${ticket_id}`, unreadCount.toString());
            
              setConservations((prev) => ({
                ...prev, // Preserve existing data
                [ticket_id]: jsonData, // Add or update the current ticket's conversation
              }));
            } else {
              // Initialize the count and set conversations
              localStorage.setItem(`count${ticket_id}`, jsonData.length.toString());
            
              setConservations((prev) => ({
                ...prev, // Preserve existing data
                [ticket_id]: jsonData, // Add or update the current ticket's conversation
              }));
            }
            
            
          }
        })
        .catch((error) => console.error(error.message));
    
    return res;
  };
  

  let isFreshdeskLoaded = false; // Global flag to track script load
  

  useEffect(() => {
    initializeFreshdesk();
  
    return () => {
      removeFreshdeskWidget(); // Cleanup on unmount
    };
  }, []);
  




  const initializeFreshdesk = () => {
    return new Promise((resolve) => {
      if (!isFreshdeskLoaded) {
        isFreshdeskLoaded = true;
  
        // Hide the launcher immediately via CSS
        const style = document.createElement("style");
        style.innerHTML = `
          #launcher-frame, 
          .freshworks-chat-launcher,
          .fw-widget-container {
            display: none !important;
          }
        `;
        document.head.appendChild(style);
  
        window.fwSettings = { widget_id: 1070000002508 };
  
        if (!document.querySelector(`script[src="https://ind-widget.freshworks.com/widgets/1070000002508.js"]`)) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = "https://ind-widget.freshworks.com/widgets/1070000002508.js";
          script.async = true;
          script.defer = true;
  
          script.onload = () => {
            // Ensure Freshdesk Widget exists before hiding it
            setTimeout(() => {
              if (typeof window.FreshworksWidget === "function") {
                window.FreshworksWidget("hide", "launcher");
              }
              resolve();
            }, 0); // Execute instantly after script load
          };
  
          document.body.appendChild(script);
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  };
  
  
  let isWidgetOpen = false; // Track the state manually

  const toggleFreshdeskWidget = (partnerId, loginInfo, dealerShipEmail,sfaUserName,sfaUserEmail) => {
    if (!partnerId) return;
    initializeFreshdesk().then(async () => {
      if (typeof window.FreshworksWidget === "function") {
        if (isWidgetOpen) {
          window.FreshworksWidget("close");
          isWidgetOpen = false; // Update state
        } else {
          window.FreshworksWidget("open");
          isWidgetOpen = true; // Update state

        //console.log("esponse.data.data[0]esponse.data.data[0]esponse.data.data[0]",response.data.data[0].sfa_refferal_user_name)

          const name = loginInfo?.user_data.name;
          let createdByDetails = `${name} - ${dealerShipEmail}`;
          let rmDetails  = "Not Found";         
          if (sfaUserName) {
            rmDetails = `${sfaUserName} - ${sfaUserEmail ||""}`;
          }
          
          window.FreshworksWidget("prefill", "ticketForm", {
            name: name,
            subject: "Query raised by "+name +" - "+partnerId,
            email: dealerShipEmail,
            custom_fields: {
              cf_partner_id: partnerId.toString(),
              cf_source: "Saathi Web",
              cf_email :dealerShipEmail,
              cf_requester:dealerShipEmail,
              cf_requested_by : createdByDetails,
              ...(rmDetails && {cf_rm_details :rmDetails}),
            },
          });
  
          window.FreshworksWidget("disable", "ticketForm", [
            "name",
            "email",
            "custom_fields.cf_partner_id",
          ]);
  
          window.FreshworksWidget("hide",
             "ticketForm",
              ["name",
               "subject",
               "custom_fields.cf_source",
               "email",
               "custom_fields.cf_source",
               "custom_fields.cf_requested_by",
               "custom_fields.cf_partner_id",
              "custom_fields.cf_rm_details"]);
  
          // Wait for the widget container to appear before adding the event listener
          setTimeout(() => {
            document.addEventListener("mousedown", handleClickOutsideWidget);
          }, 500);
        }
      }
    });
  };
  
  // Function to clean up Freshdesk widget on route change
  const removeFreshdeskWidget = () => {
    isFreshdeskLoaded = false;
  
    const script = document.querySelector(`script[src="https://ind-widget.freshworks.com/widgets/1070000002508.js"]`);
    if (script) script.remove();
  
    if (typeof window.FreshworksWidget === "function") {
      window.FreshworksWidget("destroy");
    }
  
    delete window.FreshworksWidget;
    delete window.fwSettings;

    document.removeEventListener("mousedown", handleClickOutsideWidget); // Remove event listener
  };


  const handleClickOutsideWidget = (event) => {

    if (
      event.target.className === "chatus" || 
      event.target.className === "helpAndSupportIconContainer" || 
      event.target.tagName === "IMG" // Checks if the clicked element is an <img> tag
    ) {
      return;
    }
    


    const widgetContainer = document.querySelector("iframe[id^='launcher-frame'], .fw-widget-container");
  
    if (widgetContainer && !widgetContainer.contains(event.target)) {
      if (typeof window.FreshworksWidget === "function") {
        window.FreshworksWidget("close");
      }
      document.removeEventListener("mousedown", handleClickOutsideWidget);
    }
    isWidgetOpen = false;
  };




  const getFreshDeskData = async (dealerId) => {
    const url = `${FRESH_DESK_DOMAIN_URL}api/v2/search/tickets?query="custom_string:${dealerId}"`;
  
    const options = {
      method: "GET",
      headers: {
        Authorization: FRESH_DESK_API_KEY,
        Cookie: "_x_w=7_2; service_worker=true",
      },
    };
  
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      return   data?.results || [] ;
    } catch (error) {
      // Handle error here,  e.g. throw a new error or log a more specific message
      console.error("Error fetching Freshdesk data:", error);
    }
  };

  useEffect(() => {
    const currentTab = localStorage.getItem("currentTab") ?? "faq";
    setTabsName(currentTab);
  }, []);
  
  

  const handleTabsChanges = (tabs) => {

    localStorage.setItem("currentTab",tabs)
    setTabsName(tabs)
    setSearchTerm("")
    setFaqs(initialFAQs)
    setFilteredTickets(tickets)
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // Filter FAQs based on the search term
    const filteredFAQs = initialFAQs.filter((faq) =>
      faq.question.toLowerCase().includes(term)
    );

    if(tickets){
   
      const filteredTickets = tickets.filter(ticket => 
        ticket.id.toString().includes(term) || 
        ticket.custom_fields.cf_type_of_issue.toLowerCase().includes(term)
    );
     setFilteredTickets(filteredTickets)
    }

    // Update the state with filtered FAQs
    setFaqs(filteredFAQs);
  };



  return (
    <>
    
      <div className="helpheadingnew">
        <span> Help & Support</span> 
        <span className="helpAndSupportIconContainer" onClick={() => toggleFreshdeskWidget(partnerId, loginInfo, dealerShipEmail,sfaUserName,sfaUserEmail)} style={{visibility:"hidden"}}>
        <img  src={require(`../../../src/saathi-web/images/helpAndSupportIcon.svg`)} width={23} height={23} />
        </span>
      </div>

      <div className="LeadContainer">
        <div className="mytoolhelpcard">
          <div className="helpleft">

            {currentChat.length ? (
            <ChatSupport currentChat = {currentChat[0]} partnerId={partnerId} setCurrentChat={setCurrentChat} currentConversation={conversations[currentChat[0].id]}/>):(
              <>
              <div className="searchfaqflex">
                <div className="commonbtn">
                  <button
                    className={`self-btn ${
                      tabsName === "myticket" ? "active" : ""
                    }`}
                    onClick={() => handleTabsChanges("myticket")}
                  >
                    My Tickets
                  </button>
                  <button
                    className={`self-btn ${tabsName === "faq" ? "active" : ""}`}
                    onClick={() => handleTabsChanges("faq")}
                  >
                    FAQ's
                  </button>
                </div>
  
                <div>
                  <div className="col-md-12">
                    <div className="sibh">
                      <i className="ic-search" style={{ fontSize: 20 }}></i>
                      <InputField
                        id="search_text"
                        key={tabsName}
                        type="text"
                        name="search_text"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="form-input-new"
                        title={"SEARCH_TEXT"}
                        placeholder={tabsName === "faq" ? "Search your faqs..." :" Search your tickets..."}
                        //onKeyDown={handleKeyDown}
                      />
                      <div className="searchiconforword">
                        <i className="ic-arrow_forward"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              
              {

                !isLoading &&
              <div className={tabsName === "faq" ? "supportteambox" : tickets?.length ? "supportteambox":"supportteambox2"} >
                <div className={tabsName === "faq" ? "chatsupportpara" : tickets?.length ? "chatsupportpara":"chatsupportpara2"}>Got a query? Chat with Amby instantly or raise a ticket to get in touch with our support team</div>
                <div className="chatus" onClick={() => toggleFreshdeskWidget(partnerId, loginInfo, dealerShipEmail,sfaUserName,sfaUserEmail)}>Raise a Query</div>
              </div>
              }
              
  
              {tabsName === "myticket" && tickets?.length  && !isLoading &&
              <HelpSupportTable tickets={filteredTickets} setCurrentChat={setCurrentChat} conversations={conversations}/> 
              }
             
  
              {tabsName === "faq" && !isLoading ?
               !faqs?.length ? <div style={{marginLeft:"45%",marginTop:"20%"}}>No Data Found</div>:<div className="faqsContainer"><Accordion faqs={faqs} /></div>:""
              }

              {isLoading &&
              <div style={{marginTop:"100px"}}>

                <PageLoader />
              </div>
              }
              </>
            )}
            </div>
            
           

          <div className="toolcibilright">
            <div className="callusheading">Call Us</div>
            <ContactUs />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(HelpAndSupport)
);
