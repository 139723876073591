import React, { useRef } from 'react';

const NewInputField = ({ type, label, placeholder, id, name, value, onChange, maxLength, autoFocus, required,asterisk,error,verified ,disabled}) => {
  const inputRef = useRef(null);
  
  return (
    <div className="commoninput">
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        autoFocus={autoFocus}
        className={`newinput ${error ? "input-error" : ""}`}
        ref={inputRef}
        required={required}
        asterisk={asterisk}
        error={error}
        disabled={disabled}
      />
      <label htmlFor={id} className="newlabel">
        {label}
        {asterisk && <span className="required-asterisk">*</span>}
      </label>
      {error && <div className="error-message">{error}</div>}
      {verified && <div className="right-content">{verified}</div>}
    </div>
  );
};

export default NewInputField;
