import React from "react";

const BottomModal = ({ isOpen, closeModal, children }) => {
  return (
    <>
      {isOpen && (
        <div className="bottomoverlay" >
          <div className="bottommodal" >
            <span className="bottomcloseButton" onClick={closeModal}>&times;</span>
            <div className="bottomfixheight">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BottomModal;