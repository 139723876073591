import React, { useEffect, useState } from "react";
import Modal from "../../view/common/Modal";
import RadioBox from "../../view/elements/Radiobox";
import InputField from "../../view/elements/Input";
import { toast } from "react-toastify";
import {
  executeGraphQLMutationFinex,
  executeGraphQLQueryFinex,
} from "../../common/executeGraphQLQuery";
import {
  CREATE_FOLLOWUP,
  LEADS_HISTORY_QUERY,
  GET_FOLLOWUP,
  MARK_AS_DONE,
} from "../../queries/sangam/leads.gql";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";

const FollowUpPage = ({ leadDetail }) => {
  const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [showPopup, setShowPopup] = useState(false);
  const [scheduleData, setscheduleData] = useState("");
  const [isMarkAsDone, setIsMarkAsDone] = useState(false);
  const lead_id = leadDetail?.id || "";
  const [followUp, setFollowUp] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    lead_id: lead_id,
    followup_date: "",
    followup_time: "",
    comment: "",
  });

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchFollowUpHistory(lead_id);
      await fetchFollowUpDetails(lead_id);
    };

    fetchData();
  }, [lead_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const toggleDocumentPopup = (data, type = "") => {
    if (data) {
      const followupDate = data?.followup_date?.slice(0, 10);
      const followupTime = data?.followup_date?.slice(11, 16);

      if (type === "mark_as_done") {
        setFormData({
          lead_id: leadDetail.id,
          followup_type: data.followup_type,
          followup_date: "",
          followup_time: "",
          comment: "",
          ...(data?.id ? { id: data?.id } : {}),
        });
        setIsMarkAsDone(true);
      } else {
        setFormData({
          lead_id: leadDetail.id,
          followup_type: data.followup_type,
          followup_date: followupDate,
          followup_time: followupTime,
          comment: data.comment || "",
          ...(data?.id ? { id: data?.id } : {}),
        });
      }
    }

    setscheduleData(data);
    setShowPopup(!showPopup);
    document.body.classList.toggle("overflow-hidden", !showPopup);
  };

  const fetchFollowUpHistory = async (data) => {
    try {
      const result = await executeGraphQLQueryFinex(LEADS_HISTORY_QUERY(data));
      setFollowUp(result.data.get_history);
    } catch (error) {
      console.error("Error fetching follow-up history:", error);
    }
  };

  const fetchFollowUpDetails = async (data) => {
    try {
      const result = await executeGraphQLQueryFinex(
        GET_FOLLOWUP(data, user_info?.dealer_id)
      );
      const filteredFollow = result.data?.get_followup?.filter(
        (obj) => obj.followup_status == "0"
      );
      setFollowUp(filteredFollow || []);
    } catch (error) {
      console.error("Error fetching follow-up details:", error);
    }
  };

  const submitData = async () => {
    if (isMarkAsDone) return handleMarkAsDone();
    if (!validateModal()) return;
    validateModal();
    setIsSubmitDisabled(true);
    const followUpDate = new Date(
        `${formData.followup_date}T${formData.followup_time}:00.000Z`
    );
    const variables = {
      api_called_by: "saathi_web",
      partner_id: user_info?.dealer_id,
      CreateFollowupInput: {
        followup_type: formData?.followup_type,
        lead_id: leadDetail.id,
        followup_date: followUpDate,
        comment: formData.comment,
        ...(formData?.id
          ? { id: formData?.id, followup_status: "1" }
          : { followup_status: "0" }),
      },
    };
    try {
      const res = await executeGraphQLMutationFinex(CREATE_FOLLOWUP, variables);
      if (res.data.save_followup) {
        toast.success("Follow-up scheduled successfully!");
        window.location.reload();
      } else {
        setIsSubmitDisabled(false);
        toast.error("Failed to schedule follow-up");
      }
    } catch (error) {
      setIsSubmitDisabled(false);
      console.error("Error creating follow-up:", error);
      toast.error("An error occurred while scheduling the follow-up");
    }
  };

  const validateModal = () => {
    const errors = {};

    if (!formData?.followup_type) {
      errors.followup_type = "Please select an option (Call or Doc Pickup)";
    }
    if (!formData.followup_date) {
      errors.followup_date = "Follow-up date is required";
    }
    if (!formData.followup_time) {
      errors.followup_time = "Follow-up time is required";
    }
    if (!formData.comment.trim()) {
      errors.comment = "Comment is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleMarkAsDone = async () => {
    setIsSubmitDisabled(true);
    try {
      const lead_id = leadDetail.id;
      const variables = {
        api_called_by: "saathi_web",
        partner_id: user_info?.dealer_id,
        UpdateFollowupInput: {
          lead_id,
          id: +formData?.id,
          comment: formData.comment,
        },
      };
      await executeGraphQLMutationFinex(MARK_AS_DONE, variables);
      toast.success("Success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setIsSubmitDisabled(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="righttopcontainer">
        <div className="folloupheading">Followups</div>
        <div className="notes_contain">
          {followUp.length < 1 ? (
            <div className="nofollowup">
              <img
                src={require(`../../../src/saathi-web/images/home.svg`)}
                alt="plusicon"
              />
              <div>No Followups Created</div>
            </div>
          ) : (
            <>
              {followUp.map((data, index) => (
                <div className="folloupbox" key={index}>
                  <div className="followupddone">
                    <div className="folloupdetailsnew">
                      <h4 className="nextfollowup">
                        Next Follow-up (
                        {data?.followup_type == "doc_pickup"
                          ? "Doc Pickup"
                          : "Call"}
                        )
                      </h4>
                      <div className="followupflex">
                        <img
                          src={require(`../../../src/saathi-web/images/dateicon.svg`)}
                          alt="dateicon"
                          width={16}
                          height={16}
                        />
                        <div>
  {data?.followup_date
    ? `${new Date(data.followup_date).toISOString().slice(0, 10)} ${new Date(data.followup_date).toLocaleTimeString()}`
    : ""}
</div>

                      </div>
                      {data?.comment && (
                        <div className="followupflex">
                          <img
                            src="https://static.ambak.com/saathi/1724924753018.svg"
                            alt="commenticon"
                            width={14}
                            height={14}
                          />
                          <div>{data?.comment}</div>
                        </div>
                      )}
                    </div>
                    <div>
                      <h4
                        className="followupstatus"
                        onClick={() =>
                          toggleDocumentPopup(followUp[index], "mark_as_done")
                        }
                      >
                        Mark as Done
                      </h4>

                      <h4
                        className="followupstatus"
                        onClick={() => toggleDocumentPopup(followUp[index])}
                      >
                        Reschedule
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="add_notes_btn" onClick={toggleDocumentPopup}>
          <img
            src={require(`../../../src/saathi-web/images/pluscircle.svg`)}
            alt="plusicon"
          />
        </div>
      </div>

      <Modal isOpen={showPopup} togglePopup={toggleDocumentPopup} width={600} removeHeight={true}>
        {isMarkAsDone ? (
          <>
            <div className="verificationheadingmain">Mark as Done</div>
            <div className="schedlefollowdetails">
              {leadDetail?.customer?.first_name} •{" "}
              <span>{leadDetail?.customer?.mobile}</span>
            </div>

            <div>
              <div className="row">
                <fieldset className="form-filed">
                  <div className="material">
                    <textarea
                      placeholder="Comment"
                      className="form-input"
                      rows="3"
                      name="comment"
                      value={formData.comment}
                      onChange={handleInputChange}
                      error={errors?.comment}
                    ></textarea>
                  </div>
                </fieldset>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="verificationheadingmain">Schedule Follow-up</div>
            <div className="schedlefollowdetails">
              {leadDetail?.customer?.first_name} •{" "}
              <span>{leadDetail?.customer?.mobile}</span>
            </div>

            <div>
              <div className="myteamsflex">
                <div style={{ marginRight: 10 }}>
                  <RadioBox
                    type="radio"
                    name="followup_type"
                    id="call"
                    value="call"
                    label="Call"
                    checked={formData?.followup_type === "call"}
                    onChange={handleOptionChange}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <RadioBox
                    type="radio"
                    name="followup_type"
                    id="doc_pickup"
                    value="doc_pickup"
                    label="Doc Pickup"
                    checked={formData?.followup_type === "doc_pickup"}
                    onChange={handleOptionChange}
                  />
                </div>
              </div>
              <div className="row" style={{marginTop:20}}>
                <div className="col-sm-6">
                  <InputField
                    type="date"
                    name="followup_date"
                    id="date"
                    placeholder="Date*"
                    value={formData?.followup_date?.slice(0, 10)}
                    label="Date"
                    minDate={new Date()}
                    onChange={handleInputChange}
                    error={errors?.followup_date}
                  />
                </div>

                <div className="col-sm-6">
                  <InputField
                    type="time"
                    name="followup_time"
                    id="time"
                    placeholder="Time*"
                    value={formData.followup_time}
                    label="Time"
                    onChange={handleInputChange}
                    error={errors?.followup_time}
                  />
                </div>
                <fieldset className="form-filed">
                  <div className="material">
                    <textarea
                      placeholder="Comment"
                      className="form-input"
                      rows="3"
                      name="comment"
                      value={formData.comment}
                      onChange={handleInputChange}
                      error={errors?.comment}
                    ></textarea>
                  </div>
                  {errors?.comment && (<div className="error show" style={{marginTop:-16}} >{errors?.comment}</div>)}
                </fieldset>
              </div>
            </div>
          </>
        )}
        <div className="cibilsendconsent" style={{ marginTop: 20 }}>
          <button className="cibilcheckbtn"  onClick={submitData}>
            {isMarkAsDone ? "Done" : "Schedule Now"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default FollowUpPage;
