import React, { useEffect, useState } from "react";
import MyEarningsPopup from "./MyEarningsPopup";
import Modal from "../../view/common/Modal";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import {  INVOICE_LISTING_DATA, NOTES_LISTING_DATA } from "../../queries/payout.gql";
import NoDataFound from "../../common/NoDataFound";
import { useCleverTap } from '../CleverTapContext';
import SideBar from "../../view/common/SideBar";
import MyEarningNotesDetail from "./MyEarningNotesDetail";
import NotesPopUp from "./NotesComponent/NotesPopUp";
import { FINEX_MASTER_LIST } from "../../queries/scheme.gql";
import { buildApproveInvoiceUrl } from '../MyLeads/helpers/helpers';
import { useHistory } from "react-router-dom";
import PageLoader from "../../view/elements/PageLoader";


const MyEarningListTable = (props) => {
  const history = new useHistory(); 
  const {
    activeTab,
    expandedRow,
    handleTabClick,
    handleRowClick,
    paidInvoiceData,
  } = props;

  const [projectedData, setProjectedData] = useState([]);
  const [schemeData, setSchemeData] = useState([]);
  const [showView, setShowView] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const trackEvent = useCleverTap();

  const handleTabClickWithTracking = (tab) => {
    trackEvent(tab, "cta_clicked");  // Track event when tab is clicked
    handleTabClick(tab);  // Original tab click handler
  };
  const [notesData, setNotesData] = useState({});
  const [notesInnerData,setNotesInnerData] = useState({})
  const [selectedInvoiceId,setSelectedInvoiceId] = useState(null)
  const [selectedLeadId,setSelectedLeadId] = useState(null)
  const [bankList,setBankList] = useState([])
 const [isLoading, setIsLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
    const toggleNotesPopup=(lead_id,invoice_id)=>{
      if(lead_id) setSelectedLeadId(lead_id)
      if(invoice_id) setSelectedInvoiceId(invoice_id)
      setShowPopUp(!showPopUp)
    }

  const handlePayoutApi = async (data, paidWholeData) => {
    if (activeTab === "Projected") {
      setInvoiceData(data);
      setProjectedData(paidWholeData);
    } else if (activeTab === "Earned" || activeTab === "Paid") {
      const response = await executeGraphQLMutation(INVOICE_LISTING_DATA(data.id));
      setInvoiceData(response.data.invoice_payouts[0]);
    }
    togglePopup();
  };



  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = []
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      if (bank_list && bank_list.length > 0) {

        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
            logo:banks.logo
          });
        });
        setBankList(bankData);
      }
    }
  };

  useEffect(()=>{
    getFinexMasterData()
  },[])

  const togglePopup = () => {
    setShowView((prevShowView) => !prevShowView);

    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleSideBar = async(data,call=false) => {
    if(call && data && Object.values(data).length){
      const result = await executeGraphQLQuery(NOTES_LISTING_DATA(data.id));
      // setNotesInnerData(result.data.invoice_payouts[0]);
      const dataToSet={...data,notes:result.data.fetch_notes_by_invoice.data.length?result.data.fetch_notes_by_invoice.data:[]}
      setNotesData(dataToSet)
    }
    setShowSideBar((prevShowView) => !prevShowView);
  };

  const handleInvoiceData = async (index , id) => {
    setIsLoading(true)
    handleRowClick(index)
    const result = await executeGraphQLQuery(INVOICE_LISTING_DATA(id));
    setSchemeData(result.data.invoice_payouts);
    setIsLoading(false)
  };


  const formatCurrency = (x) => {
    x=x.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res
  }
  const handleGenerateInvoice = (data) => {
        history.push(buildApproveInvoiceUrl(data));
  }

  const renderPayoutStatus = (status)=>{
    switch(status){
      case "credit":
        return <span className="noteTag" ><img src="https://static.ambak.com/saathi/1732712104166.png" alt="note" width={16} height={16}/>Credit</span>
      case "debit":
        return <span className="noteTag" >Debit</span>
        case "booster":
        return <span className="noteTag" >Booster</span>
      default :return ""
    }
  }
  return (
    <div className="tablecontainer">
     {  isLoading ? <PageLoader /> : null}
      <div className="newtabsContainer">
        <ul className="newtabs">
          {["Projected", "Earned", "Paid"].map((tab) => (
            
            <li
              key={tab}
              className={`newtabsItem ${activeTab === tab ? "active" : ""}`}
            >
              <div className="newtabContent" onClick={() => handleTabClickWithTracking(tab)}>
                {tab}
                <span className="newtabSpan"></span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {activeTab === "Projected" && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Lead ID</th>
                <th>Name</th>
                <th>Bank Name</th>
                <th>Product Type</th>
                <th>Disb. Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              { paidInvoiceData.length > 0 ?  paidInvoiceData.map((data, index) => (
                <React.Fragment key={index}>
                  <tr onClick={() => handleRowClick(index)}>
                    <td>{data?.lead_id || '--'}</td>
                    <td>{data?.customer_name || '--'}</td>
                    <td>{data?.bank_name || '--'}</td>
                    <td>{data?.products || '--'}</td>
                    <td>
                      {formatCurrency(data.total_disbursal_amount || '--')}
                    </td>
                    <td>{expandedRow === index ? "-" : "+"}</td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan="6">
                        <table>
                          <thead style={{ background: "#ccc" }}>
                            <tr>
                              <th style={{ color: "#000" }}>Tranche ID</th>
                              <th style={{ color: "#000" }}>Product Type</th>
                              <th style={{ color: "#000" }}>Disb. Amount</th>
                              <th style={{ color: "#000" }}>Date</th>
                              <th style={{ color: "#000" }}>Projected Amt</th>
                              <th style={{ color: "#000" }}>Breakup</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.payouts.map((payout, subIndex) => (
                              <tr key={subIndex}>
                                <td>{payout?.tranche_id} {" "}{renderPayoutStatus(payout.payout_type)}</td>
                                <td>{payout?.products}</td>
                                <td>{formatCurrency(payout?.disbursal_amount)}</td>
                                <td>
                                  {new Date(payout?.disbursal_date).toLocaleDateString()}
                                </td>
                                <td>{formatCurrency(payout?.payout_amount)}</td>
                                <td
                                  className="viewleadcolor"
                                  onClick={() => handlePayoutApi(payout, paidInvoiceData[index])}
                                >
                                  View
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )) :  <td colspan='5' style={{textAlign : "center"}}><NoDataFound/></td>}
            </tbody>
          </table>
        </div>
      )}

      {(activeTab === "Paid" || activeTab === "Earned") && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Invoice No</th>
                <th>Raised Date</th>
                <th>Notes</th>
                <th>Amount</th>
                <th>Invoice</th>
                <th>Breakup</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              { paidInvoiceData && paidInvoiceData.length>0 ? paidInvoiceData.map((data, index) => (
                
                <React.Fragment key={index}>
                  <tr>
                    <td>{data.invoice_no || '--'}</td>
                    <td>{new Date(data.created_at).toLocaleString()}</td>
                    <td>{data.no_of_credit>0 ? <a onClick={()=>toggleSideBar(data,true)}>{data.no_of_credit} Credit Note(s)</a>:"-" }{data.no_of_debit>0 ? <>
                      , <a onClick={()=>toggleSideBar(data,true)}>{data.no_of_debit} Debit Note(s)</a>
                    </>:"-"}</td>
                    <td>{formatCurrency(+data?.payout_amount) || '--'}</td>
                     

                    <td>
                      <button style={{background: 'white', border: 'none'}} onClick={() => {handleGenerateInvoice(data)}}>
                        <i className="ic-content_copy icons-normal"></i>
                      </button>
                        
                    </td>
                    <td onClick={() => handleInvoiceData(index , data.id)}>
                      {expandedRow === index ? "-" : "+"}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan="6">
                        <table>
                          <thead style={{ background: "#ccc" }}>
                            <tr>
                              <th style={{ color: "#000" }}>Lead ID</th>
                              <th style={{ color: "#000" }}>Name</th>
                              <th style={{ color: "#000" }}>Product Type</th>
                              <th style={{ color: "#000" }}>Disb. Amount</th>
                              <th style={{ color: "#000" }}>Date</th>
                              <th style={{ color: "#000" }}>Payout Amt</th>
                              <th style={{ color: "#000" }}>Breakup</th>
                            </tr>
                          </thead>
                          <tbody>
                            {schemeData.map((payout, subIndex) => (
                              <tr key={subIndex}>
                                <td>{payout.payouts.partnerleads.lead_id}</td>
                                <td>{payout.payouts.partnerleads.customer_name}</td>
                                <td>{payout.products}</td>
                                <td>{payout.payouts.partnerleads.disbursal_amount}</td>
                                <td>{new Date(payout.payouts.partnerleads.disbursal_date).toLocaleDateString()}</td>
                                <td>{payout.payout_amount}</td>
                                <td
                                  className="viewleadcolor"
                                  onClick={() => handlePayoutApi(data)}
                                >
                                  View
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  
                    
                
                </React.Fragment> 
              )) :
              <td colspan='5' style={{textAlign : "center"}}><NoDataFound/></td>}
            </tbody>
          </table>
        </div>
      )}

      <SideBar isOpen={showSideBar} toggleSideBar={toggleSideBar} width={600} title={"Credit/Debit Note Details"}>
        <MyEarningNotesDetail bankList={bankList} notesData={notesData} togglePopup={toggleNotesPopup} activeTab={activeTab} />
      </SideBar>
      <Modal isOpen={showView} togglePopup={togglePopup} width={800}>
        <MyEarningsPopup invoiceData={invoiceData} projectedData={projectedData} />
      </Modal>
      <Modal isOpen={showPopUp} togglePopup={toggleNotesPopup} width={800}>
        <NotesPopUp notesData={notesData} selectedLeadId={selectedLeadId} bankList={bankList} selectedInvoiceId={selectedInvoiceId} />
      </Modal>
    </div>
  );
};

export default MyEarningListTable;