import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import secureStorage from '../../config/encrypt';
import MasterService from '../../service/MasterService';
import { useCleverTap } from '../../saathi-web/CleverTapContext';
import { RoleAccess } from '../../config/constant';
import Brandlogo from '../elements/Brandlogo';

const NewNavbar = (props) => {
    const [langOption, setLangOption] = useState([]);
    const [dealerIdHash, setDealerIdHash] = useState("");
    const [navbarOpen, setNavbarOpen] = useState(false);

    const history = useHistory();
    
    useEffect(() => {
        const dealer_id_hash = localStorage.getItem("dealer_hash_id");
        setDealerIdHash(dealer_id_hash);
        
        let localLangData = secureStorage.getItem('langOption');
        if (!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    setLangOption(langData);
                }
            });
        } else {
            setLangOption(localLangData);
        }
    }, []);

    const handleLogout = () => {
        if (typeof props.logoutUser === 'function') {
            props.logoutUser();
        }
    };

    const handleSchemeClick = () => {
        history.push('/scheme-details');
    };

    const handleTrackEvent = () => { 
        // Track event if necessary
    };

    const handleMenu = () => {
        setNavbarOpen(!navbarOpen);
        document.body.classList.add("overflow-hidden")
    };

    const CloseMenu = () => {
        setNavbarOpen(false);
        document.body.classList.remove("overflow-hidden")
    };

    const help_and_support = RoleAccess("Help and Support");

    let name = "Admin";
    let role, profileTitle, userType, dealerName, stock_category, parent_partner_id, is_payout_applicable = [];
    
    if (secureStorage.getItem('loginUserInfo')) {
        let loginResponse = secureStorage.getItem('loginUserInfo');
        if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
            name = loginResponse.data.user_data.name;
            role = loginResponse.data.user_data.role;
            userType = loginResponse.data.user_data.role_type;
            parent_partner_id = loginResponse.data.user_data.parent_partner_id;
            is_payout_applicable = loginResponse.data.user_data.is_payout_applicable || 0;
        }
        if (loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0]) {
            let dealer = loginResponse.data.dealer_data[0];
            if (Array.isArray(dealer.stock_category)) stock_category = dealer.stock_category;
            dealerName = `${dealer.organization}`;
        }
        profileTitle = [dealerName, userType].filter(v => v).join(" | ");
    }

    return (
        <>
        
            <div className="hidepcheader">
                <div className="header">
                    <div className="headericon">
                        <div className="menuflex">
                            <div style={{ cursor: "pointer" }} onClick={handleMenu}>
                                <img
                                    src={require(`../../saathi-web/images/menu.png`)}
                                    alt="menu"
                                    width={24}
                                    height={24}
                                />
                            </div>
                            <div>
                                <Brandlogo />
                            </div>
                        </div>
                    </div>

                    <nav className={navbarOpen ? "navbar navbaropen" : "navbar"}>
                        <ul className="navbar-nav">
                            <>
                                <li className='closemenunew' onClick={CloseMenu}>&times;</li>
                                <li onClick={CloseMenu}>
                                    <NavLink to="/my-saathi-earnings" activeClassName="active">
                                        <img
                                            src={require(`../../../src/saathi-web/images/myearnings.svg`)}
                                            alt="earnings"
                                        />
                                        My earnings
                                    </NavLink>
                                </li>
                                <li onClick={CloseMenu}>
                                    <NavLink to="/saathi-leads" activeClassName="active">
                                        <img
                                            src={require(`../../../src/saathi-web/images/myleads.svg`)}
                                            alt="leads"
                                        />
                                        My Leads
                                    </NavLink>
                                </li>
                                <li onClick={CloseMenu}>
                                    <NavLink to="/my-saathi-teams?type=sub_partner" activeClassName="active">
                                        <img
                                            src={require(`../../../src/saathi-web/images/myteam.svg`)}
                                            alt="teams"
                                        />
                                        My Teams
                                    </NavLink>
                                </li>
                                <li onClick={CloseMenu}>
                                    <NavLink to="/saathi-tools" activeClassName="active" onClick={handleTrackEvent}>
                                        <img
                                            src={require(`../../../src/saathi-web/images/mytools.svg`)}
                                            alt="tools"
                                        />
                                        My Tools
                                    </NavLink>
                                </li>
                                {help_and_support === 1 && (
                                    <li onClick={CloseMenu}>
                                        <NavLink to="/help-support" activeClassName="active" onClick={handleTrackEvent}>
                                            <img
                                                src={require(`../../../src/saathi-web/images/headphones.svg`)}
                                                alt="tools"
                                            />
                                            Help & Support
                                        </NavLink>
                                    </li>
                                )}
                            </>
                            {(parent_partner_id === 0 || (parent_partner_id > 0 && is_payout_applicable === 1)) && (
                                <ul className="payout-menu-item">
                                    <li className="payout-menu-card" onClick={CloseMenu}>
                                        <div>
                                            <img
                                                src={require(`../../../src/saathi-web/images/rupees.svg`)}
                                                alt="tools"
                                            />
                                            <span>Payout Schemes</span>
                                        </div>
                                        <div onClick={handleSchemeClick}>Check</div>
                                    </li>
                                </ul>
                            )}
                        </ul>

                        <div style={{ borderTop: "1px solid #6952BD" }}>
                        <ul className="navbar-nav" >
                            <li onClick={CloseMenu}>
                                <NavLink to={`/my-account-profile?${dealerIdHash}`} activeClassName="active">
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <img src={require(`../../../src/saathi-web/images/user.svg`)} alt="user" width={40} height={40} />
                                            <div>{name}</div>
                                        </div>
                                        <img src='https://static.ambak.com/saathi/1725257002719.svg' alt='user' width={16} height={16} />
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    </nav>
                        {navbarOpen &&  (
                            <aside className={`${"navbackdrop"} ${"navbackdropopen"}`} onClick={handleMenu}></aside>
                        )}
                </div>
            </div>

            <div className="hidemobileheader">
                <header className="mainHeader header-fixed clearfix">
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 40, marginTop: 32 }}>
                        <Brandlogo />
                    </div>
                    <nav className={props.navClass}>
                        <ul className="navbar-nav">
                            <>
                                <li>
                                    <NavLink to="/my-saathi-earnings" activeClassName="active">
                                        <img
                                            src={require(`../../../src/saathi-web/images/myearnings.svg`)}
                                            alt="earnings"
                                        />
                                        My earnings
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/saathi-leads" activeClassName="active">
                                        <img
                                            src={require(`../../../src/saathi-web/images/myleads.svg`)}
                                            alt="leads"
                                        />
                                        My Leads
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/my-saathi-teams?type=sub_partner" activeClassName="active">
                                        <img
                                            src={require(`../../../src/saathi-web/images/myteam.svg`)}
                                            alt="teams"
                                        />
                                        My Teams
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/saathi-tools" activeClassName="active" onClick={handleTrackEvent}>
                                        <img
                                            src={require(`../../../src/saathi-web/images/mytools.svg`)}
                                            alt="tools"
                                        />
                                        My Tools
                                    </NavLink>
                                </li>
                                {help_and_support === 1 && (
                                    <li>
                                        <NavLink to="/help-support" activeClassName="active" onClick={handleTrackEvent}>
                                            <img
                                                src={require(`../../../src/saathi-web/images/headphones.svg`)}
                                                alt="tools"
                                            />
                                            Help & Support
                                        </NavLink>
                                    </li>
                                )}
                            </>
                            {(parent_partner_id === 0 || (parent_partner_id > 0 && is_payout_applicable === 1)) && (
                                <ul className="payout-menu-item">
                                    <li className="payout-menu-card">
                                        <div>
                                            <img
                                                src={require(`../../../src/saathi-web/images/rupees.svg`)}
                                                alt="tools"
                                            />
                                            <span>Payout Schemes</span>
                                        </div>
                                        <div onClick={handleSchemeClick}>Check</div>
                                    </li>
                                </ul>
                            )}
                        </ul>
                        <div style={{ borderTop: "1px solid #6952BD" }}>
                                <ul className="navbar-nav" >
                                    <li>
                                        <NavLink to={`/my-account-profile?${dealerIdHash}`} activeClassName="active">
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <img src={require(`../../../src/saathi-web/images/user.svg`)} alt="user" width={40} height={40} />
                                                    <div>{name}</div>
                                                </div>
                                                <img src='https://static.ambak.com/saathi/1725257002719.svg' alt='user' width={16} height={16} />
                                            </div>
                                        </NavLink>
                                    </li>
                                </ul>

                                {/* Uncomment and adjust if you need dropdown for profile */}
                                {/* <div className="dropdown" title={profileTitle || ''}>
                                    <a href="" style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ marginRight: 10 }}>
                                            <img src={require(`../../../src/saathi-web/images/user.svg`)} alt="user" width={40} height={40} />
                                        </span>
                                        {props.t('menu.hi')} {name}<span className="caret"></span>
                                    </a>
                                    <ul className="dropdown-menu">
                                        {role === 'dealercentral' && 
                                            <li>
                                                <NavLink to={"/dealer/" + dealerIdHash + "/basic-details"} activeClassName="active">
                                                    {props.t('menu.my_account')}
                                                </NavLink>
                                            </li>
                                        }
                                        <li onClick={handleLogout}>
                                            <NavLink to="" className="headermenulogout">{props.t('menu.logout')}</NavLink>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                    </nav>
                </header>
            </div>
        </>
    );
}

export default withTranslation('common')(NewNavbar);
