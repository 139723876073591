import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || "",
      id: props.id || "",
      name: props.name || "",
      type: props.type || "text", // default type should be "text" instead of "type"
      error: props.error || "",
      label: props.label || "",
      maxLength: props.maxLength || "",
      required: props.required || false,
      placeholder: props.placeholder || props.label,
      is_label_required: props.is_label_required || false,
      form_field: props.form_field || false,
      autoFocus: props.autoFocus || false,
      minDate: props.minDate || "" // add minDate to state
    };
  }

  changeValue = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    if (this.state.type === "date" && this.state.minDate && new Date(value) < new Date(this.state.minDate)) {
      value = ""; // Reset the value if it's less than minDate
      this.setState({ error: `Date cannot be before ${this.state.minDate}` });
    } else {
      this.setState({ error: "" });
    }

    if (/^[0-9]+$/.test(this.props.min)) value = Math.max(parseFloat(this.props.min), parseFloat(value));
    if (/^[0-9]+$/.test(this.props.max)) value = Math.min(parseFloat(this.props.max), parseFloat(value));
    
    event.target.value = value;
    this.setState({ value });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(event);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ 
      error: nextProps.error,
      required: nextProps.required,
      value: nextProps.value,
      minDate: nextProps.minDate || this.state.minDate // update minDate
    });
  }

  onKeyPress = (event) => {
    this.props.onKeyPress && this.props.onKeyPress(event);
    if (event.which === 13) {
      // Handle Enter key press
    }
  }

  onPasteDisallow = (event) => {
    event.preventDefault();
  }

  onCopyDisallow = (event) => {
    event.preventDefault();
  }

  handleDateChange = (date) => {
    const { name } = this.state;
    this.setState({ value: date });
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { name, value: date } });
    }
  }

  render() {
    const { active, value, error, label, id, type, name, required, maxLength, is_label_required, form_field, placeholder, minDate } = this.state;
    const { predicted, locked, children } = this.props;

    let fieldClassName = `form-field ${(locked ? active : active || value) && "active"} ${locked && !active && "locked"}`;
    if (error) fieldClassName += ` field-error`;

    return (
      <div className={!form_field ? fieldClassName : ""}>
        {active && value && predicted && predicted.includes(value) && <p className="predicted">{predicted}</p>}
        {!is_label_required ? <label htmlFor={id}>{label} {required && <span className="required" style={{ color: 'red' }}>*</span>}</label> : ""}
        
        {/* Conditionally render DatePicker if type is 'date' */}
        {type === "date" ? (
          <DatePicker
            id={id}
            selected={value ? new Date(value) : null}
            onChange={this.handleDateChange}
            className="form-control"
            placeholderText={placeholder}
            dateFormat="dd/MM/yyyy"
            minDate={minDate ? new Date(minDate) : null} // Apply minDate for date picker
            autoFocus={this.props.autoFocus}
          />
        ) : (
          <input
            id={id}
            className="form-control"
            type={type}
            value={value || ""}
            name={name}
            placeholder={placeholder}
            onChange={this.changeValue}
            disabled={this.props.disabled}
            onKeyPress={this.onKeyPress}
            onFocus={() => !locked && this.setState({ active: true })}
            onBlur={() => !locked && this.setState({ active: false })}
            maxLength={maxLength}
            autoComplete={this.props.autocomplete || ''}
            readOnly={this.props.readOnly}
            onPaste={this.props.RestrictOnPaste === 1 ? this.onPasteDisallow : ''}
            onCopy={this.props.RestrictOnCopy === 1 ? this.onCopyDisallow : ''}
            autoFocus={this.props.autoFocus}
            min={type === 'date' ? minDate : undefined} // Only use min attribute for date type
          />
        )}

        {children}
        {error && <span className="error show">{error}</span>}
      </div>
    );
  }
}

export default InputField;
