import React, { useEffect, useState } from "react";
import sfaService from "../../service/SfaService";
import secureStorage from "../../config/encrypt";

const ContactUs = () => {
  const [mappedData, setMappedData] = useState([]); 

  
  useEffect(() => {

    let loginResponse = secureStorage.getItem('loginUserInfo');

    const fetchData = async () => {
      try {
        const response = await sfaService.getMappedPartner({ "partner_id":loginResponse.data.dealer_id});
        setMappedData(response.data); // Assuming response.data contains the actual data
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors, e.g., set an error state
      }
    };

    fetchData();
  }, []); 
  
  return (
    <>
      {
        mappedData.length && mappedData[0]?.mapped_yoddha?.name &&

        <div className="maincardbm">
          <div className="mainheaadingbm">Your dedicated RM</div>
          <div className="subheadingbm">{mappedData[0]?.mapped_yoddha?.name}</div>
          <div className="subheadingbm">{mappedData[0]?.mapped_yoddha?.role_name}</div>
          <div className="subheadingbm">+91 {mappedData[0]?.mapped_yoddha?.mobile}</div>
          <div className="subheadingbm">{mappedData[0]?.mapped_yoddha?.email}</div>
        
        </div>
      }


      {
              mappedData[0]?.mapped_manager?.name &&
              
            <div className="maincardbm">
              <div className="mainheaadingbm">Senior Point of Contact</div>
              <div className="subheadingbm">{mappedData[0]?.mapped_manager?.name}</div>
              <div className="subheadingbm">{mappedData[0]?.mapped_manager?.role_name}</div>
              <div className="subheadingbm">+91 {mappedData[0]?.mapped_manager?.mobile}</div>
              <div className="subheadingbm">{mappedData[0]?.mapped_manager?.email}</div>
            </div>

      }
      {
            mappedData[0]?.support_agent?.agentName &&

            <div className="maincardbm">
              <div className="mainheaadingbm">{mappedData[0]?.support_agent?.agentName}</div>
              <div className="subheadingbm">{mappedData[0]?.support_agent?.agentNumber}</div>
              <div className="subheadingbm">Working Hours: Mon-Sat, 10 AM - 7 PM</div>
              <div className="subheadingbm">{mappedData[0]?.support_agent?.agentEmail}</div>
            </div>
      }


     
    </>
  );
}

export default ContactUs;
