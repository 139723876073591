import React, { useEffect, useRef, useState, useCallback } from "react";
import { executeGraphQLMutationFinex, executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import { CRIF_CONSENT_INITIATE, GET_CIBIL_DETAILS, GET_CRIF_INFO } from "../../../queries/sangam/cibil.gql";
import { toast } from "react-toastify";

const OtpVarified = (props) => {
  const {
    createdLeadId,
    formData,
    mobile,
    panNumber,
    leadData,
    user_id,
    partner_id,
    dob,
    handleDataFromVerificationCibil,
    handleBack,
  } = props;

  const [otp, setOtpState] = useState(["", "", "", ""]);

  useEffect(() => {
    if (createdLeadId || leadData?.id) {
      handleFetchCibilReport();
    }
  }, [createdLeadId, leadData]);

  const handleInput = useCallback(
    (e, index) => {
      const value = e.target.value;
      if (!/^[0-9]?$/.test(value)) return;

      const newOtp = [...otp];
      newOtp[index] = value;

      setOtpState(newOtp);

      if (value && index < 3) {
        focusNextInput(index);
      }

      if (!value && index > 0) {
        focusPreviousInput(index);
      }

      const currentOtp = newOtp.join("");
      if (currentOtp.length === 4) {
        handleDataFromVerificationCibil("otp", currentOtp);
      }
    },
    [otp, handleDataFromVerificationCibil]
  );

  const focusNextInput = (index) => {
    const nextInput = document.getElementById(`${index + 1}_otp`);
    if (nextInput) nextInput.focus();
  };

  const focusPreviousInput = (index) => {
    const prevInput = document.getElementById(`${index - 1}_otp`);
    if (prevInput) prevInput.focus();
  };

  const formatDobToReadable = useCallback((dob) => {
    if (!dob) return "";
    
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    let day, month, year;
    
    if (dob.includes("/")) {
      [day, month, year] = dob.split("/");
    } else if (dob.includes("-")) {
      [year, month, day] = dob.split("-");
    }
    
    return `${day} ${months[parseInt(month) - 1]}, ${year}`;
  }, []);

  const callSendConsignmentOtp = useCallback(async (createdLeadId = 0) => {
    try {
      const variables = {
        cibilCrifInput: {
          pan_card: formData?.pan_number||leadData?.customer?.pancard_no,
          mobile: Number(formData?.mobile_number||leadData?.customer?.mobile),
          first_name: formData?.first_name||leadData?.customer?.first_name ,
          last_name: formData?.last_name||leadData?.customer?.last_name,
          dob: formatDobToReadable(formData?.dob),
          partner_id: `${partner_id || 1}`,
          user_id: user_id,
          lead_id: createdLeadId || leadData.id,
        },
      };

      const resp = await executeGraphQLMutationFinex(CRIF_CONSENT_INITIATE(), variables);
      if (resp?.data?.cibil_crif_consent_initiate?.status === 200 && resp?.data?.cibil_crif_consent_initiate?.data?.success) {
        handleDataFromVerificationCibil("crif_ref", resp.data.cibil_crif_consent_initiate.data?.data?.refId);
      } else if (resp?.data?.cibil_crif_consent_initiate?.errors) {
        throw new Error(resp?.data?.cibil_crif_consent_initiate?.errors?.message);
      }
    } catch (error) {
      console.error("Error in sending OTP:", error);
      toast.error(error.message || "Unable to send OTP for consent.");
    }
  }, [panNumber, mobile, formData, leadData, dob, partner_id, user_id, handleDataFromVerificationCibil, formatDobToReadable]);

  const formatDobToApiFormat = useCallback((dob) => {
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dob)) {
      return dob;
    }

    const months = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04",
      May: "05", Jun: "06", Jul: "07", Aug: "08",
      Sep: "09", Oct: "10", Nov: "11", Dec: "12",
    };

    const [day, month, year] = dob.replace(",", "").split(" ");
    if (!day || !month || !year) return "Invalid date";

    return `${day}/${months[month]}/${year}`;
  }, []);

  const handleFetchCibilReport = useCallback(async () => {
    try {
      const data = {
        pan_card: formData?.pan_number,
        first_name: formData?.first_name,
        middle_name: formData?.middle_name || '',
        last_name: formData?.last_name,
        dob: formatDobToApiFormat(dob),
        gender: leadData?.gender,
        mobile: formData?.mobile_number,
        partner_id: String(partner_id),
        user_id: user_id,
        partner_name: "partner_id",
        is_consigned: 0,
        lead_id: leadData?.id,
        update_concent: 1,
      };

      const response = await executeGraphQLMutationFinex(GET_CIBIL_DETAILS, {
        cibilDetailsInput: data,
        type: "saathi",
        id: 1,
      });

      if (response.data?.get_cibil_details) {
        const gqlRes = await executeGraphQLQueryFinex(GET_CRIF_INFO(data.pan_card, 'crif_report', data.mobile, data.partner_id, data.lead_id, data.user_id));
        if (gqlRes?.data?.cibil_info?.crif_report?.cta === 'Success' && gqlRes?.data?.cibil_info?.crif_report?.consent === 1 && gqlRes?.data?.cibil_info?.crif_report?.score && gqlRes?.data?.cibil_info?.crif_report?.report) {
          handleDataFromVerificationCibil("crif_report", gqlRes.data.cibil_info.crif_report);
        } else {
          await callSendConsignmentOtp(createdLeadId);
        }
      } else {
        throw new Error("Internal server error");
      }
    } catch (error) {
      console.error("Error fetching CIBIL report:", error);
      toast.error(error.message || "Something went wrong while fetching the CIBIL report.");
    }
  }, [panNumber, formData, dob, leadData, mobile, partner_id, user_id, handleDataFromVerificationCibil, formatDobToApiFormat, callSendConsignmentOtp, createdLeadId]);

  return (
    <div className="otpcard">
      <div className="leadheading">OTP Verification</div>
      <div>
        <div className="otpenter">
          Please enter 4 digit OTP sent on <span>{`${(formData?.mobile_number).slice(0, 4)} ****`}</span>
          <span className="otpagainsend" onClick={handleBack}> EDIT</span>
        </div>
      </div>
      <div className="otpcontainer">
        {[...Array(4)].map((_, index) => (
          <input
            id={`${index}_otp`}
            key={index}
            type="number"
            maxLength="1"
            autoComplete="off"
            min="0"
            max="9"
            onChange={(e) => handleInput(e, index)}
          />
        ))}
      </div>
      <div className="otpresend">
  <div>Didn’t receive the OTP?</div>
  <div className="otpagainsend" onClick={handleFetchCibilReport} style={{ cursor: "pointer", color: "blue" }}>
    Resend
  </div>
</div>
    </div>
  );
};

export default OtpVarified;