import axios from 'axios';
import { API_URL } from '../../config/constant';
import { InventoryService } from '../../service';
import { toast } from 'react-toastify';
import { GET_ACCESS_FEATURES, executeGraphQLQueryFinex } from '../../common/executeGraphQLQuery';


export const postApiUtility = async (URL, body, headers) => {
    headers = headers || {};
    headers['Authorization'] = headers['Authorization']  ? headers['Authorization']  : getToken();
    headers['Content-Type'] = 'application/json';
    headers['source'] = 'saathi-web';
    URL = (URL[0] == '/') ? URL.substr(1) : URL;
    const data = await axios.post(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    return data;
}

const getToken = () => {
    const userInfo = getUserInfo();
    const { token } = userInfo;
    return token ? token : '';
}

export const  getUserInfo = () => {
    const userInformation = localStorage.getItem("loginUserInfo");
    let userInfo = {};
    if (userInformation) {
      try {
        const parsedUserInformation = JSON.parse(userInformation);
        if (parsedUserInformation?.user_id) {
          userInfo = {
            user_id: parsedUserInformation?.user_id,
            dealer_id: parsedUserInformation?.dealer_id,
            token: parsedUserInformation?.token,
            mobile: parsedUserInformation?.mobile,
            dealer_id_hash: parsedUserInformation?.dealer_id_hash,
            user_type: parsedUserInformation?.user_type,
          };
        }
        return userInfo;
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
      }
    } else {
      return false;
    }
  };


  export const fileUploader = async (file) => {
    const userInfo = getUserInfo();
    const { user_id } = userInfo;
    
    if (!file) {
      toast.error("Please choose a file first!");
      return;
    } else {
      const formData = new FormData();
      formData.append("dealer_id", user_id);
      formData.append("upload_type", "dealer_docs");
      formData.append("images", file);
      try {
        const response = await axios.post(
          "https://api-stage.ambak.com/core/commonservice/docs_upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      
        if (response.data.status === 200) {
          const fileUrl = response?.data?.data[0];
      
          const responseFromSigned = await InventoryService.getSignedUrl(
            0,
            fileUrl.file_url,
            {
              headers: {
                Accept: "application/json, text/plain, */*",
              },
            }
          );
      
          if (responseFromSigned?.data) {
            fileUrl.file_url = responseFromSigned?.data;
          }
      
          toast.success("File uploaded successfully");
          return fileUrl;
        }
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        toast.error("File upload failed. Please try again.");
      }
      
    }
  };


  export const getUserAccess = async (user) => {

    let role = 56;
    // const userType = user;
    if (user?.user_type === 1) { // 1 = "Partner"
      role = 14;
    }
    if (user?.user_type === 60) { // 60 = "sub_partner"
      role = 15;
    }
    if (user?.user_type === 61) { // 61 = "team_member"
      role = 16;
    }

    try {
      let data;
      await executeGraphQLQueryFinex(GET_ACCESS_FEATURES(role)).then(
        (response) => {
          if (
            response &&
            response.data &&
            response.data.get_role_access_features
          ) {
            data = response.data.get_role_access_features;

            localStorage.setItem("userAccess", JSON.stringify(data));
            modifyLocalUserData(user);
            localStorage.setItem("userAccess", JSON.stringify(data));
          }
        }
      );
    } catch (err) {
      return {
        status: false,
        response: err,
      };
    }
  };


  const modifyLocalUserData = (user) => {
    try {
      let localUser = JSON.parse(localStorage.getItem('loginUserInfo'));
    localUser = {
      ...localUser,
      name: user?.name ?? '',
      user_type: user?.user_type ?? '1',
      parent_partner_id: user?.parent_partner_id,
      user_data: {
        ...localUser?.user_data,
        name: user?.name ?? '',
      }
    }
    localStorage.setItem('loginUserInfo', JSON.stringify(localUser));
    } catch (error) {
      console.log("error inside modifyLocalUserData");
    }
   }