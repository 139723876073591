import Axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { DealerService } from "../../service";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { GET_TICKETS_CONVERSATION } from "../../queries/threads.gql";
import generalService from "../../service/generalService";

const ChatSupport = ({currentChat,partnerId,setCurrentChat,currentConversation}) => { 


  const [message, setMessage] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [conversation, setConversation] = useState([]);

  const chatContainerRef = useRef(null);

  // Handle message input change
  const handleInputChange = (event) => {
    setMessage(event.target.value);
    const textarea = event.target;
    textarea.style.height = "28px"; 
    textarea.style.height = `${Math.min(textarea.scrollHeight, 70)}px`;
  };

  const handleback = () =>{
  //  chatfalse(false)
  setCurrentChat([])
  }

  
  const sendMessage = async() => {

    const response = await addNoteToTicket(currentChat.id, message, false,true,ticketData.requester_id);

    if(response){
      let payload = {
        ticket_id:currentChat.id.toString(),
        partner_id:partnerId,
        message:message.trim()
    }
    await generalService.freshDeskHelpAndSupportLogs(payload)
    }

    if (message.trim()) {
      console.log("mes",message,conversation)
      setConversation((prevMessages) => [
        ...prevMessages,
        response,
      ]);
      setTimeout(() => {
        
        setMessage("");

      }, 500);
    }
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get date components
    const day = date.getDate();
    const year = date.getFullYear() - 1; // Adjust the year to 2024
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];

    // Determine day suffix
    const daySuffix = 
        day % 10 === 1 && day !== 11 ? "st" :
        day % 10 === 2 && day !== 12 ? "nd" :
        day % 10 === 3 && day !== 13 ? "rd" : "th";

    return `${day}${daySuffix} ${month},${year}`;
}


  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  // Scroll to the bottom whenever the messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [conversation]);

  useEffect(() => {
   
    setConversation(currentConversation)
  }, []);


  useEffect(() => {
    getTicketDetails(currentChat.id)

  }, []);



  async function addNoteToTicket(ticketId, noteBody, privateNote,incoming,requester_id) {
    const url = `https://ambak-help.freshdesk.com/api/v2/tickets/${ticketId}/notes`;
    const username = "9ivut8pZhJTuW4yIZY";
    const password = "X"; // Replace with your password
    
    const body = JSON.stringify({
      body: noteBody,
      private: privateNote,
      user_id:requester_id,
     incoming
    });
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString("base64")}`
        },
        body: body
      });
  
      const data = await response.json();
      console.log("Response:", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  async function getTicketDetails(ticketId) {
    const url = `https://ambak-help.freshdesk.com/api/v2/tickets/${ticketId}`;
    const username = "9ivut8pZhJTuW4yIZY";
    const password = "X"; // Replace with your password
  
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString("base64")}`
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      
      setTicketData(data)
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
    
  return (
    <>
      <div className="chatmaincontianer">
        {/* Top section */}
        <div className="chatboxcard">
          <div className="chatissue">
            <i className="ic-arrow_back arrowsize" onClick={handleback}></i> {currentChat?.custom_fields.cf_type_of_issue}
          </div>
          <div className="flexid">
            <div className="flexdate">
              <span style={{ paddingLeft: 30 }}>
                <img
                  src={require(`../../../src/saathi-web/images/no-ticket.svg`)}
                  width={16}
                  height={12}
                  alt="ticket"
                />
              </span>
              #{currentChat?.id}
            </div>
            <div className="flexdate">
              <span>
                <img
                  src={require(`../../../src/saathi-web/images/dateicon.svg`)}
                  width={14}
                  height={14}
                  alt="date"
                />
              </span>
              {formatDate(currentChat?.created_at)}
            </div>
          </div>
        </div>
        <div className="datefixes">{formatDate(currentChat?.created_at)}</div>

        {/* Middle section (Scrollable chat area) */}
        <div className="chatmiddle" ref={chatContainerRef}>
          {/* Display all messages */}
          {conversation?.map((msg, index) => (
            <div
              key={index}
              className={msg.incoming ? "flexendchat" : ""}
            >
              <div className={msg.incoming ? "send_messs" : "recievd_messs"}>
                {msg.body_text}
              </div>
            </div>
          ))}
        </div>

        {/* Bottom section */}
        <div className="chatbottom">
          <div className="foot_cont">
            <div className="send_input_cont">
              <div className="send_input">
                <textarea
                  value={message}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type your message..."
                />
              </div>
              <div className="send_arrow" onClick={sendMessage}>
                <img
                  src={require(`../../../src/saathi-web/images/sendicon.svg`)}
                  alt="icon"
                  width={20}
                  height={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatSupport;
