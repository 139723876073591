import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router';
import InputField from '../../../view/elements/Input';
import './WebsiteSettings.css';
import Select from "react-select"
import { toast } from 'react-toastify';
import generalService from '../../../service/generalService';
import secureStorage from '../../../config/encrypt';
import Axios from 'axios';
import MasterService from '../../../service/MasterService';
import FileInput from '../../NewLogin/Components/Input/FileInput';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { executeGraphQLMutationFinex } from '../../../common/executeGraphQLQuery';
import { BankLogo } from '../../../queries/payout.gql';
import { validate } from 'graphql';
import PageLoader from '../../../view/elements/PageLoader';


const API_ENDPOINTS = {
    SAVE_HEADER: '/api/website-settings/header',
    SAVE_HERO: '/api/website-settings/hero',
    SAVE_TESTIMONIALS: '/api/website-settings/testimonials',
    SAVE_SERVICES: '/api/website-settings/services',
    SAVE_ABOUT: '/api/website-settings/about',
    SAVE_CALCULATOR: '/api/website-settings/calculator',
    SAVE_FOOTER: '/api/website-settings/footer',
    SAVE_DOMAIN: '/api/website-settings/domain'
};

const TOAST_CONFIG = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
};

const WebsiteSettings = (props) => {

    const updateStateIntoRedux = props.updateStateIntoRedux;

    useEffect(() => {
        const updateReduxData = async () => {
          const loginResponse = secureStorage.getItem("loginUserInfo");
          if (loginResponse) {
            updateStateIntoRedux("LOGIN_USER", loginResponse);
          }
        };
        updateReduxData();
      }, [updateStateIntoRedux]);
  
    

    const history = useHistory();
    const [loginInfo, setLoginInfo] = useState(null);

    const [activeTab, setActiveTab] = useState("General Settings");
    const [catActiveTab, setCatActiveTab] = useState(null); 
    const [dealer_logo , set_logo] = useState("")
    const [bank_list , set_bank_list] = useState([]);
    const [website_content , set_website_content] = useState([])
    const [headerState, setHeaderState] = useState({
        logo: null,
        navigation: []
    });
    const [subHerSection , setSubHeroSection] = useState({
        average_rating : "",
        total_rating : "",
        map_url : ""
    })
    console.log("??????" , subHerSection);
    
    const [heroState, setHeroState] = useState({
        heading: '',
        bodyText: '',
        features: [''],
        googleRating: '',
        reviewCount: '',
        map: null,
        contactDetails: {
            phone: '',
            email: ''
        }
    });

    const [testimonialState, setTestimonialState] = useState([{ 
         name: '', image: null, rating: 0, review: '' 
    }]);
    const [servicesState, setServicesState] = useState({
        categories: [],
        categoryOptions: [
            { value: 'Home Loans', label: 'Home Loans' },
            { value: 'Loan Against Property', label: 'Loan Against Property' },
            { value: 'Personal Loans', label: 'Personal Loans' },
            { value: 'Business Loan', label: 'Business Loan' },
            { value: 'Car Loan', label: 'Car Loan' }
        ],
        serviceDetails: {}
    });

    const [aboutState, setAboutState] = useState({
        title : '',
        content: '',
        stats: [{
            label: '',
            value: '',
            icon: null
        }]
    });
    
    const [calculatorState, setCalculatorState] = useState({
        calculators: {
            emiCalculator: false,
            balanceTransferCalculator: false
        },
        buttonActive: false,
        buttonText: ''
    });
    
    const [footerState, setFooterState] = useState({
        partnerLogos: [null],
        quickLinks: [{ name: '', link: '' }],
        others: [{ name: '', link: '' }],
        address: {
            companyName: '',
            details: ''
        },
        poweredBy: ''
    });
    const [domainState, setDomainState] = useState({
        theme: '',
        domainType: '',
        domain: '',
        logo: null,
        instagram_url : '',
        facebook_url : '',
        twitter_url : '',
        whatsapp_url : '',
        privacy_policy : '',
        terma_and_condition : '',
        office_address : '',
        contact_number : ''
    });

    const [pageLoading, setPageLoading] = useState(false);

    const navigationOptions = useMemo(() => [
        {
            value: "/",
            label: "Home",
            name: "Home",
            link: "/"
        },
        {
            value: "/services",
            label: "Our Services",
            name: "Our Services",
            link: "/services"
        },
        {
            value: "/about",
            label: "About Us",
            name: "About Us",
            link: "/about"
        },
        {
            value: "/tracker",
            label: "Application Tracker",
            name: "Application Tracker",
            link: "/tracker"
        },
        {
            value: "/calculators",
            label: "Calculators",
            name: "Calculators",
            link: "/calculators"
        }
    ], []);

    const themeOptions = useMemo(() => [
        { value: 'default', label: 'Default' },
        { value: 'modern', label: 'Modern' },
        { value: 'classic', label: 'Classic' },
        { value: 'minimal', label: 'Minimal' }
    ], []);

    const domainTypeOptions = useMemo(() => [
        { value: 'subdomain', label: 'Subdomain' },
        { value: 'custom', label: 'Custom Domain' }
    ], []);

    const handleTabClick = useCallback((tab) => {
        setActiveTab(tab);
    }, []);

    const updateState = useCallback((setState, field, value) => {
        setState(prev => ({ ...prev, [field]: value }));
    }, []);
    
    const updateNestedState = useCallback((setState, parent, field, value) => {
        setState(prev => ({
            ...prev,
            [parent]: {
                ...prev[parent],
                [field]: value
            }
        }));
    }, []);
    
    useEffect(() => {
        const fetchMicrositeContent = async () => {
            try {
                const userInformation = localStorage.getItem("loginUserInfo");
                const parsedUserInformation = JSON.parse(userInformation);
                const dealer_id = parsedUserInformation?.dealer_id;
    
                if (!dealer_id) {
                    console.warn("Dealer ID not found");
                    return;
                }
    
                const res = await MasterService.post(
                    "partner/customer-microsite/get-microsite-content",
                    { partner_id: dealer_id }
                );
    
                const data = res?.data?.data;
                if (!data) return;
    
                const { header_logo, menu_list, home, testimonial, our_services, about_us, calculator, social_link, privacy_policy, terms_and_conditions, logo, theme, domain_type, domain } = data;
    
                set_website_content(data);
    
                setHeaderState({
                    logo: header_logo ?? null,
                    navigation: menu_list?.map(({ title, type }) => ({
                        name: title,
                        link: `/${type}`,
                        label: title,
                        value: type,
                    })) ?? [],
                });
    
                setHeroState({
                    heading: home?.title ?? "",
                    bodyText: home?.highlighted_text ?? "",
                    features: home?.keys ?? [""],
                    googleRating: home?.rating?.avg_rating ?? "",
                    reviewCount: home?.rating?.total_rating ?? "",
                    contactDetails: {
                        phone: home?.partner_number ?? "",
                        email: home?.partner_email ?? "",
                    },
                });
                setSubHeroSection({
                    average_rating : home?.rating?.avg_rating ?? "",
                    total_rating : home?.rating?.total_rating ?? "",
                    map_url : home?.rating?.map_url ?? ""
                })
    
                setTestimonialState(
                    testimonial?.map(({ name, profile_image, rating, review }) => ({
                        name,
                        image: profile_image,
                        rating,
                        review,
                    })) ?? []
                );
    
                if (our_services) {
                    setServicesState((prevState) => {
                        const categories = [];
                        const serviceDetails = {};
    
                        our_services.forEach(({ keys }) => {
                          return ( keys?.forEach(({ type, title, details, banner_url, bank_list }) => {
                                
                                categories.push({ value: type, label: title });
                            
                                serviceDetails[type] = {
                                    title,
                                    description: details ?? "",
                                    image: banner_url ?? "",
                                    buttonText: "",
                                    bankingPartners: bank_list?.map(({ bank_name, bank_logo , id}) => ({
                                        bank_name: bank_name,
                                        bank_logo: bank_logo,
                                        id: id,
                                    })) ?? [],
                                };
                            })
                            )
                        });
    
                        return { ...prevState, categories, serviceDetails };
                    });
                }
    
                setAboutState({
                    content: about_us?.description ?? "",
                    stats: about_us?.keys ?? [],
                });
    
                setCalculatorState({
                    calculators: {
                        emiCalculator: calculator?.emi_calculator?.active ?? false,
                        balanceTransferCalculator: calculator?.balance_transfer_calculator?.active ?? false,
                    },
                    buttonActive: calculator?.button?.active ?? false,
                    buttonText: calculator?.button?.text ?? "",
                });
    
                setFooterState((prev) => ({
                    ...prev,
                    partnerLogos: [null],
                    quickLinks: [{ name: "", link: "" }],
                    others: [{ name: "", link: "" }],
                    address: { companyName: "", details: "" },
                    poweredBy: "",
                }));
    
                setDomainState({
                    theme: data.theme ?? "",
                    domainType: data.domain_type?.toLowerCase() ?? "",
                    domain: data.domain ?? "",
                    logo: data.header_logo ?? null,
                    instagram_url: data.social_link?.find(link => link.instagram_url)?.instagram_url ?? "",
                    facebook_url: data.social_link?.find(link => link.facebook_url)?.facebook_url ?? "",
                    twitter_url: data.social_link?.find(link => link.twitter_url)?.twitter_url ?? "",
                    whatsapp_url: data.social_link?.find(link => link.whatsapp_url)?.whatsapp_url ?? "",
                    privacy_policy: data.privacy_policy ?? "",
                    terms_and_condition: data.terms_and_conditions ?? "",
                    office_address: data.office_address ?? ""
                });

                setCalculatorState({
                    calculators: {
                        emiCalculator: calculator?.emi_calculator?.active?? false,
                        balanceTransferCalculator: calculator?.balance_transfer_calculator?.active?? false,
                    },
                    buttonActive: calculator?.button?.active?? false,
                    buttonText: calculator?.button?.text?? "",
                })
    
            } catch (error) {
                console.error("Error fetching microsite content:", error);
            }
        };
    
        fetchMicrositeContent();
    }, []);
    


    useEffect(() => {
      getBankList()
    } , [])
    const getBankList = async () => {
        const res = await executeGraphQLMutationFinex(BankLogo)
        set_bank_list(res?.data?.masterdata?.bank_list)
    }
    const saveSection = useCallback(async (endpoint, state, successMessage) => {
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(state)
            });
            
            if (response.ok) {
                toast.success(successMessage, TOAST_CONFIG);
            } else {
                throw new Error(`Failed to save: ${response.statusText}`);
            }
        } catch (error) {
            toast.error(error.message, TOAST_CONFIG);
            console.error('Save error:', error);
        }
    }, []);

    // Section-specific handlers
    const handleNavigationChange = useCallback((selected) => {
        
        setHeaderState(prev => ({ ...prev, navigation: selected || [] }));
    }, []);

    console.log(headerState,"headerState");
    
    const handleHeroInput = useCallback((field, value, index = null) => {
        setHeroState(prev => {
            if (index !== null) {
                const newFeatures = [...prev.features];
                newFeatures[index] = value;
                return { ...prev, features: newFeatures };
            } else {
                return { ...prev, [field]: value };
            }
        });
    }, []);
    
    const handleContactChange = useCallback((field, value) => {
        updateNestedState(setHeroState, 'contactDetails', field, value);
    }, [updateNestedState]);
    
    // Optimized array item handlers with useCallback
    const addItemToArray = useCallback((setState, arrayName, initialItem = '') => {
        setState(prev => ({
            ...prev,
            [arrayName]: [...prev[arrayName], initialItem]
        }));
    }, []);
    
    const removeItemFromArray = useCallback((setState, arrayName, index) => {
        setState(prev => ({
            ...prev,
            [arrayName]: prev[arrayName].filter((_, i) => i !== index)
        }));
    }, []);
    
    // Handle image uploads with proper cleanup
    const handleImageUpload = useCallback((setState, field, file) => {
        // Clean up previous object URL if it exists to prevent memory leaks
        setState(prev => {
            if (prev[field] && prev[field].preview) {
                URL.revokeObjectURL(prev[field].preview);
            }
            
            // Create a new file object with preview URL for display
            const newFile = file ? {
                ...file,
                preview: URL.createObjectURL(file)
            } : null;
            
            return { ...prev, [field]: newFile };
        });
    }, []);
    
    // Service section specific handlers
    const handleCategoryChange = useCallback((selectedOptions) => {
        const selectedCategories = selectedOptions || [];
        
        setServicesState(prev => {
            const newServiceDetails = {};
            
            selectedCategories.forEach(category => {
                if (prev.serviceDetails[category.value]) {
                    newServiceDetails[category.value] = prev.serviceDetails[category.value];
                } else {
                    newServiceDetails[category.value] = {
                        title: '',
                        description: '',
                        image: null,
                        buttonText: '',
                        bankingPartners: [{
                            name: '',
                            logo: null
                        }]
                    };
                }
            });
            
            return {
                ...prev,
                categories: selectedCategories,
                serviceDetails: newServiceDetails
            };
        });
    }, []);

    // Save functions
    const saveHeaderSection = useCallback(async() => {
        // saveSection(
        //     API_ENDPOINTS.SAVE_HEADER, 
        //     headerState, 
        //     'Header section saved successfully'
        // );
        const variables ={
            partnerId: loginInfo?.dealer_id,
            menu_details: headerState.navigation.map(item => ({
                name: item.name,
                link: item.link
            }))
        }
      const res =   await generalService.saveWebsiteSettingsHeaderDetails(variables)
      if (res?.data?.status == 200) {
        toast.success("Header section updated successfully")
      }
      else {
        toast.error("Something went wrong")
      }
    }, [headerState, saveSection]);
    

    const saveSubHeroSection =  async () => {
        const userInformation = localStorage.getItem("loginUserInfo");
        const parsedUserInformation = JSON.parse(userInformation);
        const dealer_id = parsedUserInformation?.dealer_id;
        const reqObj = {
            partnerId : dealer_id,
            avg_rating : subHerSection.average_rating,
            total_rating : subHerSection.total_rating,
            map_url : subHerSection.map_url
        }
        const res = await MasterService.post("partner/customer-microsite/add-hero-subsection" ,  reqObj)
        if (res?.data?.status == 200) 
        {
            toast.success("Sub Hero section updated successfully")

        }
        else {
            toast.error("Something went wrong")
        }
        
    }
    const saveHeroSection = useCallback(async() => {
  
        const variables = {  
            partnerId: loginInfo?.dealer_id,
            title: heroState.heading,
            highlighted_text: heroState.bodyText,
            features: heroState.features
        }
        const res = await generalService.saveWebsiteSettingsHeroSection(variables)
        if(res?.data?.status === 200) {
            toast.success("Hero section updated successfully");
        }
        else {
         toast.error("Something went wrong");
        }
    }, [heroState, saveSection]);
    
    const saveTestimonialSection = useCallback(async() => {
        setPageLoading(true);
        try {
            const variables = {
                partnerId: loginInfo?.dealer_id,
                testimonials: testimonialState.map(item => ({
                    name: item.name,
                    profile_image: item.image,
                    rating: item.rating,
                    review: item.review
                }))
            }
            const res = await generalService.saveWebsiteSettingsTestimonials(variables)
            
            if(res?.data?.status === 200) {
                toast.success("Testimonial section updated successfully", TOAST_CONFIG);
            } else {
                toast.error(res?.data?.message || "Something went wrong", TOAST_CONFIG);
            }
        } catch (error) {
            console.error("Error saving testimonials:", error);
            toast.error("Failed to save testimonials", TOAST_CONFIG);
        } finally {
            setPageLoading(false);
        }
    }, [testimonialState, loginInfo?.dealer_id]);
    
    const saveServicesSection = useCallback(async () => {
        setPageLoading(true);
        try {
            const modifiedRes = transformServiceDetails(servicesState?.serviceDetails);
            const userInformation = localStorage.getItem("loginUserInfo");
            const parsedUserInformation = JSON.parse(userInformation);
            const dealer_id = parsedUserInformation?.dealer_id;
            modifiedRes['partner_id'] = dealer_id;
            
            const apiRes = await MasterService.post("partner/customer-microsite/save-partner-service-details", modifiedRes)
            
            if(apiRes?.data?.status === 200) {
                toast.success("Services section saved successfully", TOAST_CONFIG);
            } else {
                toast.error(apiRes?.data?.message || "Something Went Wrong", TOAST_CONFIG);
            }
        } catch (error) {
            console.error("Error saving services:", error);
            toast.error("Failed to save services", TOAST_CONFIG);
        } finally {
            setPageLoading(false);
        }
    }, [servicesState]);

  
    
    const saveAboutSection = useCallback(async () => {
        setPageLoading(true);
        try {
            const userInformation = localStorage.getItem("loginUserInfo");
            if (!userInformation) {
                toast.error("User information not found", TOAST_CONFIG);
                return;
            }
        
            const parsedUserInformation = JSON.parse(userInformation);
            const user_id = parsedUserInformation?.dealer_id;
        
            setAboutState(prevState => ({
                ...prevState,
                partner_id: user_id
            }));
        
            const response = await generalService.saveWebsiteSettingsAboutUsDetails({
                ...aboutState,
                partner_id: user_id
            });
            
            if (response?.data?.status === 200) {
                toast.success("About section saved successfully", TOAST_CONFIG);
            } else {
                toast.error(response?.data?.message || "Something went wrong", TOAST_CONFIG);
            }
        } catch (error) {
            console.error("Error saving about section:", error);
            toast.error("Failed to save about section", TOAST_CONFIG);
        } finally {
            setPageLoading(false);
        }
    
    }, [aboutState]);
    
    
    const saveCalculatorSection = useCallback(async() => {
        // saveSection(
        //     API_ENDPOINTS.SAVE_CALCULATOR, 
        //     calculatorState, 
        //     'Calculator section saved successfully'
        // );
        const variables = {
            partnerId: loginInfo?.dealer_id,
            emi_calculator_active: calculatorState.calculators.emiCalculator,
            bt_calculator_active: calculatorState.calculators.balanceTransferCalculator,
            button_active: calculatorState.buttonActive,
            button_text: calculatorState.buttonText
        }
        const res = await generalService.saveWebsiteSettingsCalculatorDetails(variables)
        if(res?.data?.status === 200) {
            toast.success("Calculator section updated successfully");
        } else {
            toast.error("Something went wrong");
        }
    }, [calculatorState, saveSection]);
    
    const saveFooterSection = useCallback(() => {
        saveSection(
            API_ENDPOINTS.SAVE_FOOTER, 
            footerState, 
            'Footer section saved successfully'
        );
    }, [footerState, saveSection]);
    
    // ... existing code ...

const saveDomainSettings = useCallback(async () => {
    const userInformation = localStorage.getItem("loginUserInfo");
    if (!userInformation) {
        return Promise.reject("User information not found");
    }
    
    const parsedUserInformation = JSON.parse(userInformation);
    const user_id = parsedUserInformation?.dealer_id;
    
    // Log the dealer_logo to verify it has a value
    console.log("Dealer Logo before save:", dealer_logo);
    
    const reqObj = {
        "domain_theme": domainState.theme,
        "domain_type": domainState.domainType,
        "domain": domainState.domain,
        "partnerId": user_id,
        "logo": dealer_logo || domainState.logo, // Use dealer_logo if available, fallback to domainState.logo
        "instagram_url": domainState.instagram_url,
        "twitter_url": domainState.twitter_url,
        "facebook_url": domainState.facebook_url,
        "whatsapp_url": domainState.whatsapp_url,
        "privacy_policy": domainState.privacy_policy,
        "terms_and_condition": domainState.terms_and_condition,
        "office_address": domainState.office_address,
        "contact_number": domainState.contact_number
    }

    // Log the final request object
    console.log("Request object:", reqObj);

    const res = await MasterService.post("/partner/customer-microsite/save-partner-template-details", reqObj)
    if(res?.data?.status == 200) {
        return toast.success("Data Update Successfully")
    } else {
        return toast.error("Something went wrong")
    }
   
}, [domainState, dealer_logo]); 

    useEffect(() => {
        const fetchLoginInfo = async () => {
            const response = await secureStorage.getItem('loginUserInfo').data;
            setLoginInfo(response);
        };
        fetchLoginInfo();
    }, []);

    const handleLogoFileChange = async (e, type, category) => {
        const { files } = e.target;
    
        if (!files || files.length === 0) {
            console.log("No file selected");
            return Promise.reject("No file selected");
        }
    
        const userInformation = localStorage.getItem("loginUserInfo");
        if (!userInformation) {
            console.log("User information not found in localStorage");
            return Promise.reject("User information not found");
        }
    
        const parsedUserInformation = JSON.parse(userInformation);
        const user_id = parsedUserInformation?.user_id;
    
        if (!user_id) {
            console.log("User ID not found");
            return Promise.reject("User ID not found");
        }
    
        const formData = new FormData();
        formData.append("dealer_id", user_id);
        formData.append("upload_type", "dealer_docs");
        formData.append("images", files[0]);
    
        try {
            const response = await MasterService.post("core/commonservice/docs_upload", formData);
            const fileUrl = response?.data?.data?.[0]?.file_url;
            
            if (!fileUrl) {
                throw new Error("File URL is missing in the response");
            }
            
            set_logo(response?.data?.data?.[0]?.file_url)
            toast.success("File Upload Success");
            console.log("File uploaded successfully:", response);
    
            return fileUrl; // Return file URL so it can be used in `.then()`
        } catch (error) {
            console.error("Error while uploading the file:", error);
            toast.error("Error while uploading the file");
            return Promise.reject(error); // Return error for handling in `.catch()`
        }
    };
    console.log("||||||" , dealer_logo)


    const handleFileChange = async (e, type, category) => {
        setPageLoading(true);
        
        try {
            const { files } = e.target;
        
            if (!files || files.length === 0) {
                console.log("No file selected");
                toast.error("No file selected", TOAST_CONFIG);
                setPageLoading(false);
                return Promise.reject("No file selected");
            }
        
            const userInformation = localStorage.getItem("loginUserInfo");
            if (!userInformation) {
                console.log("User information not found in localStorage");
                toast.error("User information not found", TOAST_CONFIG);
                setPageLoading(false);
                return Promise.reject("User information not found");
            }
        
            const parsedUserInformation = JSON.parse(userInformation);
            const user_id = parsedUserInformation?.user_id;
        
            if (!user_id) {
                console.log("User ID not found");
                toast.error("User ID not found", TOAST_CONFIG);
                setPageLoading(false);
                return Promise.reject("User ID not found");
            }
        
            const formData = new FormData();
            formData.append("dealer_id", user_id);
            formData.append("upload_type", "dealer_docs");
            formData.append("images", files[0]);
        
            const response = await MasterService.post("core/commonservice/docs_upload", formData);
            const fileUrl = response?.data?.data?.[0]?.file_url;
            
            if (!fileUrl) {
                throw new Error("File URL is missing in the response");
            }

            toast.success("File Upload Success", TOAST_CONFIG);
            console.log("File uploaded successfully:", response);
            setPageLoading(false);
            return fileUrl;
        } catch (error) {
            console.error("Error while uploading the file:", error);
            toast.error("Error while uploading the file", TOAST_CONFIG);
            setPageLoading(false);
            return Promise.reject(error);
        }
    };    
    
    function transformServiceDetails(serviceDetails) {
        const output = {
            partner_id: 1,
            details: [
                {
                    service_type_title: "Home-Loan",
                    service_sub_types: []
                }
            ]
        };
        
        for (const [serviceType, data] of Object.entries(serviceDetails)) {
            if (!data.title && !data.description && !data.image && !data.buttonText) {
                continue; // Skip empty entries
            }
            
            output.details[0].service_sub_types.push({
                title: serviceType,
                details: [
                    {
                        heading: data.title,
                        details: data.description,
                        banner_image: data.image || "", // Handle null or missing images
                        bank_details: data.bankingPartners || []
                    }
                ]
            });
        }
        
        return output;
    }

    useEffect(() => {
        if (servicesState.categories.length > 0 && catActiveTab === null) {
            setCatActiveTab(servicesState.categories[0].value);
        }
    }, [servicesState.categories]);
    
    
    
    return (
        <div className='mytoolscard'>
            {pageLoading && <PageLoader />}
            
            <div className="backoffer" onClick={() => history.push("/saathi-tools")} style={{ marginBottom: 20 }}>
                <i className="ic-arrow_back" style={{ fontSize: 20, cursor: "pointer" }}></i>
                <div className="commonheading">Website Settings</div>
            </div>

            <div className="payoutcalculator">
                <div className="newtabsContainer">
                    <ul className="newtabs">
                        {["General Settings", "Domain Settings"].map((tab) => (
                            <li
                                key={tab}
                                className={`newtabsItem ${activeTab === tab ? "active" : ""}`}
                            >
                                <div className="newtabContent" onClick={() => handleTabClick(tab)}>
                                    {tab}
                                    <span className="newtabSpan"></span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {
                    activeTab === "General Settings" ?

                        <div className='generalSettingsContent'>
                            <section style={{ marginBottom: "40px" }}>
                                <h2>Header</h2>
                                <div className='row'>
                                    <div className="col-md-7">
                                        <div className="form-input-new-container">
                                        <label>Navigation</label>
                                            <Select
                                                isMulti
                                                value={headerState.navigation}
                                                onChange={handleNavigationChange}
                                                options={navigationOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                      headerState.navigation.map((item,index)=>(
                                        <div key={index} className="col-md-2" style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                          
                                            <div className="form-input-new-container">
                                            <label>{item.name}</label>
                                                <InputField
                                                    type="text"
                                                    value={item.link}
                                                    onChange={(e) => {
                                                        const updatedNavigation = [...headerState.navigation];
                                                        updatedNavigation[index] = {
                                                            ...item,
                                                            link: e.target.value
                                                        };
                                                        setHeaderState(prev => ({
                                                            ...prev,
                                                            navigation: updatedNavigation
                                                        }));
                                                    }}
                                                    className="form-input-new"
                                                    placeholder="Enter link URL"
                                                />
                                            </div>
                                        </div>
                                      ))  
                                    }
                                </div>
                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveHeaderSection}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>


                            <section>
                                <h2>Hero Section</h2>
                                <div className='row'>
                                    <div className="col-md-3">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Heading"
                                                value={heroState.heading}
                                                onChange={(e) => handleHeroInput('heading', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter heading text"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                 label="Body Text"
                                                value={heroState.bodyText}
                                                onChange={(e) => handleHeroInput('bodyText', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter body text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                <div className='row form-input-new-container'>
                                    <div className="features-inputs col-md-3">
                                        {heroState.features.map((value, index) => (
                                            <div key={index}  className='feature-flex'>
                                                <InputField
                                                    type="text"
                                                    label="Features"
                                                    value={value}
                                                    onChange={(e) => handleHeroInput('features', e.target.value, index)}
                                                    className="form-input-new"
                                                    placeholder="Enter feature"
                                                />
                                                {heroState.features.length > 0 && (
                                                    <span
                                                        className="remove-field"
                                                        onClick={() => removeItemFromArray(setHeroState, 'features', index)}
                                                    >
                                                        ×
                                                    </span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <button
                                        className="add-new-field-btn"
                                        onClick={() => addItemToArray(setHeroState, 'features')}
                                        style={{marginLeft:16}}
                                    >
                                        + Add Feature
                                    </button>

                                      
                                </div>
                                </div>
                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveHeroSection}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>

                            <section>
                                <h2>Hero Sub-section</h2>
                               
                                <div className="row">
                                    {/* Average Rating Field */}
                                    <div className="col-md-3">
                                      
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="number"
                                                label="Average Rating"
                                                value={subHerSection.average_rating}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Validate that input is a number and between 1-5
                                                    if (/^\d*\.?\d*$/.test(value)) { // Allows only numbers and decimal
                                                        const numValue = parseFloat(value);
                                                        if (value === "" || (numValue >= 0 && numValue <= 5)) {
                                                            setSubHeroSection(prev => ({ ...prev, average_rating: value }));
                                                        } else {
                                                            toast.error("Rating must be between 0 and 5", {
                                                                position: "top-right",
                                                                autoClose: 3000
                                                            });
                                                        }
                                                    }
                                                }}
                                                className="form-input-new"
                                                placeholder="Enter Google rating (0-5)"
                                                min="0"
                                                max="5"
                                                step="0.1"
                                            />
                                        </div>
                                    </div>

                                    {/* Total Rating Count Field */}
                                    <div className="col-md-3">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Total Count"
                                                value={subHerSection.total_rating}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setSubHeroSection(prev => ({ ...prev, total_rating: value }));
                                                  }}
                                                className="form-input-new"
                                                placeholder="e.g., Based on 585 Ratings"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Office Location"
                                                value={subHerSection.map_url}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setSubHeroSection(prev => ({ ...prev, map_url: value }));
                                                  }}
                                                className="form-input-new"
                                                placeholder="Enter your office location"
                                            />
                                        </div>
                                    </div>
        </div>

                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveSubHeroSection}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>

                            <section>
                                <h2>Services Section</h2>
                                <div className='row'>
                                    <div className="col-md-fit">
                                        <div className="form-input-new-container">
                                            <label>Categories</label>
                                            <Select
                                                isMulti
                                                value={servicesState.categories}
                                                onChange={handleCategoryChange}
                                                options={servicesState.categoryOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Select service categories"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="cattabs">
                                    {servicesState.categories.map((category, index) => (
                                        <button
                                            key={index}
                                            className={`cattab ${catActiveTab === category.value ? 'active' : ''}`}
                                            onClick={() => setCatActiveTab(category.value)}
                                        >
                                            {category.label}
                                        </button>
                                    ))}
                                </div>

                                {servicesState.categories.map((category, index) => (
                                    catActiveTab === category.value && (
                                        <div key={index} className="service-details">
                                            <h5>{category.label} Details</h5>
                                            <div className='row'>
                                                <div className='form-input-new-container'>
                                                <div className="col-md-6">
                                                    <InputField
                                                        type="text"
                                                        label="Enter service title"
                                                        value={servicesState.serviceDetails[category.value]?.title || ''}
                                                        onChange={(e) => updateNestedState(setServicesState, 'serviceDetails', category.value, { ...servicesState.serviceDetails[category.value], title: e.target.value })}
                                                        className="form-input-new"
                                                        placeholder="Enter service title"
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                <div className="form-input-new-container">
                                                <label>Logo</label>
                                                    <div className="image-upload">
                                                        <FileInput
                                                            name="uploadFile"
                                                            id="uploadFile"
                                                            onChange={(e) => 
                                                                handleFileChange(e, "file_upload", category)
                                                                    .then((file_url) => {
                                                                        updateNestedState(setServicesState, 'serviceDetails', category.value, { 
                                                                            ...servicesState.serviceDetails[category.value], 
                                                                            image: file_url 
                                                                        });
                                                                    })
                                                            }    
                                                            fileUrl={servicesState.serviceDetails[category.value]?.image}        
                                                            placeholder="Upload service image"
                                                            required
                                                        />
                                                        <input
                                                            id={`service-image-${index}`}
                                                            type='file'
                                                            onChange={(e) => handleImageUpload(setServicesState, 'serviceDetails', e.target.files[0])}
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <textarea
                                                        value={servicesState.serviceDetails[category.value]?.description || ''}
                                                        onChange={(e) => updateNestedState(setServicesState, 'serviceDetails', category.value, { ...servicesState.serviceDetails[category.value], description: e.target.value })}
                                                        className="form-input-new textarea"
                                                        placeholder="Enter service description"
                                                    />
                                                </div>
                                            </div>

                                            <div className="banking-partners">
                                                <h5>Banking Partners</h5>
                                                <Select
                                                    isMulti
                                                    value={servicesState.serviceDetails[category.value]?.bankingPartners || []}
                                                    onChange={(selectedOptions) =>
                                                        updateNestedState(
                                                            setServicesState,
                                                            "serviceDetails",
                                                            category.value,
                                                            {
                                                                ...servicesState.serviceDetails[category.value],
                                                                bankingPartners: selectedOptions,
                                                            }
                                                        )
                                                    }
                                                    options={bank_list.map((bank) => ({
                                                        id: bank.id,
                                                        bank_name: bank.banklang.bank_name,
                                                        bank_logo: bank.logo,
                                                    }))}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    getOptionLabel={(option) => (
                                                        <div className="bank-option">
                                                            <img
                                                                src={option.bank_logo}
                                                                alt={option.bank_name}
                                                                style={{ width: 20, height: 20, marginRight: 10 }}
                                                            />
                                                            {option.bank_name}
                                                        </div>
                                                    )}
                                                    getOptionValue={(option) => option?.id?.toString()} // Ensure correct value handling
                                                />
                                            </div>
                                        </div>
                                    )
                                ))}

                                
                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveServicesSection}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>

                            <section>
                                <h2>About Us</h2>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <textarea
                                            value={aboutState.title}
                                            onChange={(e) => updateState(setAboutState, 'title', e.target.value)}
                                            className="form-input-new textarea"
                                            placeholder="Enter Content Title"
                                            rows={6}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <textarea
                                            value={aboutState.content}
                                            onChange={(e) => updateState(setAboutState, 'content', e.target.value)}
                                            className="form-input-new textarea"
                                            placeholder="Enter about us content"
                                            rows={6}
                                        />
                                    </div>
                                </div>

                                <div className="stats-section">
                                    <h5>Statistics</h5>
                                    {aboutState.stats.map((stat, index) => (
                                        <div key={index} className="testimonial-block">
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <InputField
                                                        type="text"
                                                        value={stat.label}
                                                        onChange={(e) => updateState(setAboutState, 'stats', aboutState.stats.map((s, i) => i === index ? { ...s, label: e.target.value } : s))}
                                                        className="form-input-new"
                                                        placeholder="Enter statistic label"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <InputField
                                                        type="text"
                                                        value={stat.value}
                                                        onChange={(e) => updateState(setAboutState, 'stats', aboutState.stats.map((s, i) => i === index ? { ...s, value: e.target.value } : s))}
                                                        className="form-input-new"
                                                        placeholder="Enter statistic value"
                                                    />
                                                </div>
                                            </div>
                                            {aboutState.stats.length > 1 && (
                                                <span
                                                    className="remove-field"
                                                    onClick={() => updateState(setAboutState, 'stats', aboutState.stats.filter((_, i) => i !== index))}
                                                >
                                                    ×
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                    <button
                                        className="add-new-field-btn"
                                        onClick={() => addItemToArray(setAboutState, 'stats')}
                                    >
                                        + Add Statistic
                                    </button>
                                </div>

                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveAboutSection}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>

                            <section>
                                <h2>Testimonials</h2>
                                {testimonialState?.map((testimonial, testimonialIndex) => (
                                        <div className="review-item">
                                    <div key={testimonialIndex}>
                                            <div className='row'>
                                            <div className="review-field col-md-4">
                                                <InputField
                                                    id={`testimonial-name-${testimonialIndex}`}
                                                    type="text"
                                                    label="Reviwer Name"
                                                    value={testimonial.name}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setTestimonialState((prev) =>
                                                          prev.map((item, index) =>
                                                            index === testimonialIndex ? { ...item, name: value } : item
                                                          )
                                                        );
                                                      }}
                                                    className="form-input-new"
                                                    placeholder="Enter reviewer name"
                                                />
                                            </div>

                                            <div className="review-field col-md-4">
                                                <div className="image-upload">
                                                    <InputField
                                                        type="text"
                                                        label="Upload Image"
                                                        value={testimonial.image ? testimonial.image : ''}
                                                        className="form-input-new"
                                                        placeholder="Upload reviewer image"
                                                        disabled
                                                    />
                                                    <button
                                                        className="upload-button"
                                                        style={{marginTop:27}}
                                                        onClick={() => document.getElementById(`review-image-${testimonialIndex}`).click()}
                                                    >
                                                        Upload
                                                    </button>
                                                    <input
                                                        id={`review-image-${testimonialIndex}`}
                                                        type='file'
                                                        onChange={(e) =>
                                                            handleFileChange(e, "file_upload", )
                                                                .then((file_url) => {
                                                                   setTestimonialState(prev => 
                                                                    prev.map((item, index) => 
                                                                        index === testimonialIndex ? { ...item, image: file_url } : item
                                                                    )
                                                                );
                                                                })
                                                        } 
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="review-field col-md-4">
                                                <InputField
                                                    type="number"
                                                    label="Rating"
                                                    value={testimonial.rating}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setTestimonialState(prev => 
                                                          prev.map((item, index) => 
                                                            index === testimonialIndex ? { ...item, rating: value } : item
                                                          )
                                                        );
                                                      }}
                                                    className="form-input-new"
                                                    placeholder="Rating (1-5)"
                                                    min="1"
                                                    max="5"
                                                />
                                            </div>

                                            <div className="review-field col-md-12">
                                                <textarea
                                                    value={testimonial.review}
                                                        onChange={(e) => {
                                                        const value = e.target.value;
                                                        setTestimonialState(prev => 
                                                          prev.map((item, index) => 
                                                            index === testimonialIndex ? { ...item, review: value } : item
                                                          )
                                                        );
                                                      }}
                                                    className="form-input-new textarea"
                                                    placeholder="Enter review text"
                                                />
                                            </div>
                                            </div>
                                        </div>

                                        {testimonialState.length > 1 && (
                                            <span
                                                className="remove-field"
                                                onClick={() => setTestimonialState(prev => prev.filter((_, i) => i !== testimonialIndex))}    
                                            >
                                                ×
                                            </span>
                                        )}
                                    </div>
                                ))}
                                <button
                                    className="add-new-field-btn"
                                    onClick={() =>setTestimonialState(prev => ([...prev, { name:"", image:null, rating:0, review:""}]))}
                                    style={{ marginTop: '20px' }}
                                >
                                    + Add Testimonial
                                </button>
                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveTestimonialSection}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>

                            <section>
                                <h2>Calculator Section</h2>
                                <div>
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <div className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    id="emiCalculator"
                                                    checked={calculatorState.calculators.emiCalculator}
                                                    onChange={(e) => updateNestedState(setCalculatorState, 'calculators', 'emiCalculator', e.target.checked)}
                                                    className="custom-checkbox"
                                                />
                                                <label htmlFor="emiCalculator">EMI Calculator</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    id="balanceTransfer"
                                                    checked={calculatorState.calculators.balanceTransferCalculator}
                                                    onChange={(e) => updateNestedState(setCalculatorState, 'calculators', 'balanceTransferCalculator', e.target.checked)}
                                                    className="custom-checkbox"
                                                />
                                                <label htmlFor="balanceTransfer">Balance Transfer Calculator</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                        <div className="checkbox-container">
                                            <input
                                                type="checkbox"
                                                id="buttonActive"
                                                checked={calculatorState.buttonActive}
                                                onChange={(e) => updateState(setCalculatorState, 'buttonActive', e.target.checked)}
                                                className="custom-checkbox"
                                            />
                                            <label htmlFor="buttonActive">Button Active</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{marginTop:20}} >
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Button Text"
                                                value={calculatorState.buttonText}
                                                onChange={(e) => updateState(setCalculatorState, 'buttonText', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter button text"
                                                disabled={!calculatorState.buttonActive}
                                            />
                                        </div>
                                    </div>
                                    </div>

                                   
                                </div>

                               

                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveCalculatorSection}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>

                        </div>
                        :
                        <div className='domainSettingsContent'>
                            <section>
                                <h2>Domain Settings</h2>
                                
                                <div className='row'>
                                      <div className="col-md-4">
                                     <div className="form-input-new-container">
                                        <label>Theme</label>
                                            <Select
                                                value={themeOptions.find(option => option.value === domainState.theme)}
                                                onChange={(selected) => updateState(setDomainState, 'theme', selected.value)}
                                                options={themeOptions}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                placeholder="Select theme"
                                            />
                                        </div>
                                    </div>
                              
                                        <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <label>Domain Type</label>
                                            <Select
                                                value={domainTypeOptions.find(option => option.value === domainState.domainType)}
                                                onChange={(selected) => updateState(setDomainState, 'domainType', selected.value)}
                                                options={domainTypeOptions}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                placeholder="Select domain type"
                                            />
                                        </div>
                                    </div>
                               
                                        <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Domain"
                                                value={domainState.domain}
                                                onChange={(e) => updateState(setDomainState, 'domain', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter domain name"
                                            />
                                        </div>
                                    </div>
                               
                                       
                                    <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Istagram handle"
                                                value={domainState.instagram_url}
                                                onChange={(e) => updateState(setDomainState, 'instagram_url', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter Instagram handle"
                                            />
                                        </div>
                                    </div>
                               
                                        <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Facebook handle"
                                                value={domainState.facebook_url}
                                                onChange={(e) => updateState(setDomainState, 'facebook_url', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter facebook handle"
                                            />
                                        </div>
                                    </div>
                                
                                        <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Twitter handle"
                                                value={domainState.twitter_url}
                                                onChange={(e) => updateState(setDomainState, 'twitter_url', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter twitter handle"
                                            />
                                        </div>
                                    </div>
                                
                                        <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Whatsapp Number"
                                                value={domainState.whatsapp_url}
                                                onChange={(e) => updateState(setDomainState, 'whatsapp_url', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter whatsapp handle"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Privacy Policy"
                                                value={domainState.privacy_policy}
                                                onChange={(e) => updateState(setDomainState, 'privacy_policy', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter Privacy Policy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Terms and Condition"
                                                value={domainState.terms_and_condition}
                                                onChange={(e) => updateState(setDomainState, 'terms_and_condition', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter terms and condition"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Office Address"
                                                value={domainState.office_address}
                                                onChange={(e) => updateState(setDomainState, 'office_address', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter Office Address"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-input-new-container">
                                            <InputField
                                                type="text"
                                                label="Contact Number"
                                                value={domainState.contact_number}
                                                onChange={(e) => updateState(setDomainState, 'contact_number', e.target.value)}
                                                className="form-input-new"
                                                placeholder="Enter Contact Number"
                                            />
                                        </div>
                                    </div>
                                        <div className="col-md-4">
                                    <div className="form-input-new-container">
                                            <label>Logo</label>
                                        <div className="image-upload">
                                                <FileInput
                                                        name="uploadFile"
                                                        id="uploadFile"
                                                        onChange={handleLogoFileChange}                                                   
                                                        placeholder="Upload service image"
                                                        required
                                                        />
                                                    <input
                                                        type='file'
                                                        onChange={(e) => handleImageUpload(setServicesState, 'serviceDetails', e.target.files[0])}
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                               
                                </div>
                                <div className="save-button-container">
                                    <button 
                                        className="save-section-btn"
                                        onClick={saveDomainSettings}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </section>
                        </div>
                }
            </div>




        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(WebsiteSettings)
);