import React, { useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { toast } from "react-toastify";
import MasterService from "../../service/MasterService";
import { postApiUtility } from "../Utility/user.utility";

const CompanyProfile = ({ profession, formData, setFormData, errors, handleUserType, gst_check }) => {
  const [panData, setPanData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'pan_no' && value.length === 10) {
      validatePan(value);
    }

    if (name === "spoc_mobile" || name === "dealership_contact") {
      const numericValue = value.replace(/\D/g, '');
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: numericValue
      }));
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: selectedOption ? selectedOption.id : ''
    }));
  };

  const selectedProfession = profession.find(
    (prof) => prof.id === formData.profession_id
  );

  const validatePan = async (pan_no) => {
    setLoading(true);
    const validatePanResponse = await postApiUtility('partner/partner/validate-PAN', {
      "pan_number": pan_no,
      "partner_id": formData.id
    })
    if(validatePanResponse?.data?.data?.message === 'New PAN') {
    try {
      const panDataResponse = await MasterService.get(`/partner/partner/pan-validation?pan_number=${pan_no}&partner_id=${formData.id}&user_id=${formData.user_id}`);
      const panData = panDataResponse.data.data;
      
      if (panDataResponse.status === 200) {
        toast.success("PAN Verified Successfully");
        setPanData(panData);
        setFormData((prevValues) => ({
          ...prevValues,
          dealership_contact: panData.mobile_no || prevValues.dealership_contact,
          dealership_email: panData.email || prevValues.dealership_email,
          name: panData.name || prevValues.name,
          aadhar_no: panData.maskedAadhaar || prevValues.maskedAadhaar,
        }));

        if (panDataResponse?.data?.data?.gstDetails?.data?.gstinList[0]?.gstin){
          gst_check(panDataResponse?.data?.data?.gstDetails?.data?.gstinList[0]?.gstin)
        }
        if (panData.applicant_type == "Individual" && panData.gst_required === true) {
          handleUserType ("company");
        } else if (panData.applicant_type == "Individual" && panData.gst_required === false) {
          handleUserType ("");
        } else if (panData.applicant_type == "company" && panData.gst_required === true) { 
          handleUserType ("company");
        } else if (panData.applicant_type == "company" && panData.gst_required === false) {  
          handleUserType ("company");
        }

      } else {
        toast.error("Invalid PAN");
      }
    } catch (error) {
      toast.error("PAN validation failed");
    } finally {
      setLoading(false);
    }
  }
  else {
    toast.error("Pan Already Exist")
    setLoading(false);
  }
  };

  return (
    <form>
      <div className="kycinfooverflow">
        <div className="row">
          <div className="col-md-4">
            <InputField
              type="text"
              name="name"
              id="name"
              placeholder="Company Name"
              label="Company Name"
              value={formData.name || ''}
              onChange={handleChange}
              error={errors.name}
              
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="dealership_contact"
              id="dealership_contact"
              placeholder=""
              label="Company Mobile Number*"
              value={formData.dealership_contact || ''}
              onChange={handleChange}
              error={errors.dealership_contact}
              maxLength={10}
              inputMode="numeric"
              pattern="[0-9]*"
              disabled={true}
            />
            {errors.dealership_contact && <div className="error">{errors.dealership_contact}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="dealership_email"
              id="dealership_email"
              placeholder="Company Email"
              label="Company Email Id*"
              value={formData.dealership_email || ''}
              onChange={handleChange}
              error={errors.dealership_email}
            />
            {errors.dealership_email && <div className="error">{errors.dealership_email}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="spoc_name"
              id="spoc_name"
              placeholder=""
              label="SPOC Name*"
              value={formData.spoc_name || ''}
              onChange={handleChange}
              error={errors.spoc_name}
            />
            {errors.spoc_name && <div className="error">{errors.spoc_name}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="spoc_mobile"
              id="spoc_mobile"
              placeholder=""
              label="SPOC Mobile*"
              value={formData.spoc_mobile || ''}
              onChange={handleChange}
              error={errors.spoc_mobile}
              maxLength={10}
              inputMode="numeric"
              pattern="[0-9]*"
            />
            {errors.spoc_mobile && <div className="error">{errors.spoc_mobile}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="spoc_email"
              id="spoc_email"
              placeholder=""
              label="Company SPOC Email*"
              value={formData.spoc_email || ''}
              onChange={handleChange}
              error={errors.company_spoc}
            />
            {errors.company_spoc && <div className="error">{errors.company_spoc}</div>}
          </div>
          <div className="col-md-4">
            <label>Profession Type*</label>
            <Select
              id="profession_id"
              name="profession_id"
              options={profession}
              value={selectedProfession}
              getOptionLabel={({ profession_name }) => profession_name}
              getOptionValue={({ id }) => id}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'profession_id')}
            />
            {errors.profession_id && <div className="error">{errors.profession_id}</div>}
          </div>
          
          
          
          {/* <div className="col-md-4">
            <InputField
              type="text"
              name="referrel_code"
              id="referrel_code"
              placeholder="Referral Code"
              label="Referral Code"
              value={formData.referrel_code || ''}
              onChange={handleChange}
              disabled
            />
          </div> */}
          
          
          <div className="col-md-4">
            <InputField
              type="text"
              name="pincode"
              id="pincode"
              placeholder="Pin Code"
              label="Pincode*"
              value={formData.pincode || ''}
              onChange={handleChange}
              error={errors.pincode}
            />
            {errors.pincode && <div className="error">{errors.pincode}</div>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default CompanyProfile;
