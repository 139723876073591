import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
//Custom component
import PrivateRoute from "./privateRoute";
import Header from "./../view/common/Header";
import Footer from "./../view/common/Footer";
import Login from "./../view/login/component/login";
import AddStock from "./../view/inventory/component/add-stock";
import ManageStock from "./../view/inventory/component/manage-stock";
import Dashboard from "../view/dashboard/dashboard";
import BuyerLead from "../view/enquiry/component/BuyerEnquiry";
import AddbuyerEnquires from "../view/enquiry/component/AddbuyerEnquires";
import AdminRoute from "./routeAdmin";
import EditListing from "./../view/dealer/component/EditListing";
import MyAccount from "./../view/dealerpages/MyAccount";
import AdminDealerList from "./../view/dealer/component/dealer-list-foradmin";
import ManagePartnerList from "./../view/dealer/component/partner-list";
import ForgotPassword from "./../view/login/component/ForgotPassword";
import ResetPassword from "./../view/login/component/ResetPassword";
import ChangePassword from "./../view/login/component/ChangePassword";
import Logout from "./../view/common/logout";
import NotFound from "../common/NotFound";

/* dealer detail */
import DealerDetail from "./../view/dealerDetail/component/MyAccount";
import AddScheme from "./../view/scheme/AddScheme";
import SchemeListing from "./../view/scheme/SchemeListing";
import PartnerSchemeAdd from "../view/dealerDetail/PartnerSchemeAdd";
import PayoutListing from "../view/payout/PayoutListing";
import Email from "../view/login/component/Email";
import DeleteAccount from "../view/deleteAccount/DeleteAccount";
import secureStorage from "./../config/encrypt";
import BookingPoint from "../view/booking/BookingPoint";
import AddPayoutPoint from "../view/booking/AddPayoutPoint";
import BankOnboarding from "../view/mis/BankOnboarding";
import MisList from "../view/mis/MisList";
import PartnerDashboard from "../view/dealer/component/PartnerDashboard";
import SaathiDashboard from "../saathi-web/SaathiDashboard";
import MyLeadList from "../saathi-web/MyLeads/MyLeadList";
import SaathiPartnerDetails from "../saathi-web/MyLeads/SaathiPartnerDetails";
import LeadDetail from "../saathi-web/MyLeads/LeadDetail";
import CustomerDetail from "../saathi-web/MyLeads/CustomerDetail";
import IncomeDetail from "../saathi-web/MyLeads/IncomeDetail";
import CoApplicantIncome from "../saathi-web/MyLeads/CoApplicantIncome";
import PropertyDetail from "../saathi-web/MyLeads/PropertyDetail";
import MoveToStatus from "../saathi-web/MyLeads/MoveToStatus";
import MoveToApproved from "../saathi-web/MyLeads/MoveToApproved";
import MoveToDisbursed from "../saathi-web/MyLeads/MoveToDisbursed";
import CoApplicantDetail from "../saathi-web/MyLeads/CoApplicantDetail";
import CoApplicantDetailsTabs from "../saathi-web/MyLeads/CoApplicantDetailsTabs";
import LeadDetailTabs from "../saathi-web/MyLeads/LeadDetailTabs";
import CustomerDetailsTabs from "../saathi-web/MyLeads/CustomerDetailsTabs";
import LoanDetails from "../saathi-web/MyLeads/customer-details/LoanDetails";
import MyEarningsList from "../saathi-web/MyEarnings/MyEarningsList";
import MyTeamsList from "../saathi-web/MyTeam/MyTeamsList";
import otpLogin from "../view/login/component/otp-login";
import login from "./../view/login/component/login";
import ReAssignLeadList from "../saathi-web/MyLeads/ReAssignLeadList";
import MyTeamDashboard from "../saathi-web/MyTeam/MyTeamDashboard";
import NewLogin from "../saathi-web/NewLogin/MainNewLogin";
import MyAccountProfile from "../saathi-web/MyAccount/MyAccountProfile";
import NoAccess from "../common/NoAccess";
import { RoleAccess } from "../config/constant";
import ProtectedRoute from "./protected-routes";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import MyTools from '../saathi-web/my-tools/MyTools';
import LoanOffer from '../saathi-web/my-tools/component/loanoffer/LoanOffer';
import MyToolCibilMain from "../saathi-web/mytool-cibil/MyToolCibilMain";
import MyCreditReports from "../saathi-web/mytool-cibil/MyCreditReports";
import PayoutCalculator from "../saathi-web/my-tools/PayoutCalculator/PayoutCalculator";
import NewPayoutCalculator from "../saathi-web/my-tools/PayoutCalculator/NewPayoutCalculator";
import EmiCalculator from "../saathi-web/my-tools/EmiCalculator/EmiCalculator";
import ApproveInvoice from "../saathi-web/MyEarnings/NotesComponent/ApproveInvoice";
import PayoutScheme from "../saathi-web/payout/component/PayoutScheme";
import APFsearch from "../saathi-web/my-tools/APFsearchEngine/APFsearch";
import NewHomeCibil from "../saathi-web/mytool-cibil/NewCibil/NewHomeCibil";
import HelpAndSupport from "../saathi-web/HelpSupport/HelpAndSupport";
import FreshdeskCreateTicket from "../saathi-web/FreshDeskCreateTicket";
import WebsiteSettings from "../saathi-web/my-tools/WebsiteSettings/WebsiteSettings";
class CustomRouter extends Component {
  constructor(props) {
    super(props);
    this.state = { ht: 500 };
  }
  componentDidMount() {
    this.setState({
      ht: window.innerHeight - 127,
    });
    window.addEventListener(
      "resize",
      () => {
        this.setState({
          ht: window.innerHeight - 127,
        });
      },
      false
    );
  }

  render() {
    const isLoggedIn = this.props.user_loggedin;

    const my_earnings = RoleAccess("My Earnings");
    const team_list = RoleAccess("My teams");
    const my_account = RoleAccess("User profile");




    return (
      <Router>
        {isLoggedIn === 1 ? <Header /> : ""}
        {/* <Header />  */}
        <div style={{ minHeight: this.state.ht }}  className={isLoggedIn == 1 ? "dashboardbody" : null}>
          <Route path="/" exact component={NewLogin} />
          <Route path="/login" component={NewLogin} />
          <PrivateRoute path="/deletepartneraccount" component={DeleteAccount} />
          <PrivateRoute path="/partnerlogin" component={Login} />
          <PrivateRoute path="/partner-list" component={ManagePartnerList} />
          <PrivateRoute path="/forgot" component={ForgotPassword} />
          <PrivateRoute path="/reset-password" component={ResetPassword} /> 
          <PrivateRoute path="/change-password" component={ChangePassword} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/partner-dashboard" component={PartnerDashboard} />
          <PrivateRoute path="/add-stock" exact component={AddStock} />
          <PrivateRoute path="/add-stock/:used_car_stock_id" component={AddStock} />
          <PrivateRoute path="/manage-stock" component={ManageStock} />
          <PrivateRoute path="/add-truck" exact component={AddStock} />
          <PrivateRoute path="/add-truck/:used_car_stock_id" component={AddStock} />
          <PrivateRoute path="/manage-truck" component={ManageStock} />
          <PrivateRoute exact path="/add-lead" component={AddbuyerEnquires} />
          <PrivateRoute
            exact
            path="/lead/:car_id_hash?"
            component={BuyerLead}
          />
          <PrivateRoute path="/edit-dealer-list" component={EditListing} />
          <PrivateRoute path="/my-account" component={DealerDetail} />
          <PrivateRoute path="/admin-dealer-list" component={AdminDealerList} />
          <PrivateRoute path="/dealer/:dealer_id_hash" component={DealerDetail} />
          <PrivateRoute path="/logout" component={Logout} />
          <PrivateRoute path="/admin" component={AdminRoute} />
          <PrivateRoute path="/schemes/list" component={SchemeListing} />
          <PrivateRoute path="/schemes/addscheme" component={AddScheme} />
          <PrivateRoute path="/scheme/:scheme_id_hash/:tab" component={AddScheme} />
          <PrivateRoute path="/partner/addscheme" component={PartnerSchemeAdd} />
          <PrivateRoute path="/partner/:dealer_id_hash" component={PartnerSchemeAdd} />
          <PrivateRoute path="/payout/list" component={PayoutListing} />
          <PrivateRoute path="/payout/points" component={BookingPoint} />
          <PrivateRoute path="/payout/addpayoutpoint" component={AddPayoutPoint} />
          <PrivateRoute path="/payout/edit/:id" component={AddPayoutPoint} />
          <PrivateRoute path="/payout/bank-onboarding" component={BankOnboarding} />
          <PrivateRoute path="/payout/mis-list" component={MisList} />
          {/* <Route path="/saathi-dashboard" component={SaathiDashboard} /> */}
          <PrivateRoute
            path="/saathi-dashboard"
            element={<PrivateRoute component={SaathiDashboard} />}
          ></PrivateRoute>
          <Route path="/my-saathi-earnings" render={(props) => my_earnings === 1 ? ( <ProtectedRoute component={MyEarningsList} access={my_earnings} {...props}/>) : (<Redirect to="/no-access" />)}/>{" "}
          <Route path="/myteam-dashboard" render={(props) => team_list === 1 ? ( <ProtectedRoute component={MyTeamDashboard} access={team_list} {...props}/>) : (<Redirect to="/no-access" />)}/>{" "}
          <Route path="/my-saathi-teams-kyc-profile" render={(props) => team_list === 1 ? ( <ProtectedRoute component={MyAccountProfile} access={team_list} {...props}/>) : (<Redirect to="/no-access" />)}/>{" "}

          <Route path="/my-saathi-teams" render={(props) => team_list === 1 ? ( <ProtectedRoute component={MyTeamsList} access={team_list} {...props}/>) : (<Redirect to="/no-access" />)}/>{" "}
          <PrivateRoute path="/saathi-leads" component={MyLeadList} />
          {/* <Route path="/bankscheme/:scheme_id_hash/:tab" element={<ProtectedRoute component={BankScheme} access={add_bank_scheme} />} /> */}
          <Route path="/my-account-profile" render={(props) => my_account === 1 ? ( <ProtectedRoute component={MyAccountProfile} access={my_account} {...props}/>) : (<Redirect to="/no-access" />)}/>{" "}
          <PrivateRoute path="/saathi-re-assign-leads" component={ReAssignLeadList} />
          <PrivateRoute  path="/saathi-details-page/:lead_id" component={SaathiPartnerDetails} />
          {/* <Route path="/lead-detail" element={<PrivateRoute component={LeadDetail} />} ></Route> */}
          <PrivateRoute path="/lead-detail/:lead_id" component={LeadDetail} />
          <PrivateRoute path="/customer-detail/:lead_id" component={CustomerDetail} />
          <PrivateRoute
            path="/co-applicant-detail/:lead_id"
            component={CoApplicantDetail}
          />
          <PrivateRoute path="/income-detail/:lead_id" component={IncomeDetail} />
          <PrivateRoute path="/property-detail/:lead_id" component={PropertyDetail} />
          <PrivateRoute path="/loggedin/:lead_id" component={MoveToStatus} />
          <PrivateRoute path="/approved/:lead_id" component={MoveToApproved} />
          <PrivateRoute path="/disbursed/:lead_id" component={MoveToDisbursed} />
          <PrivateRoute
            path="/co-applicant-income/:lead_id"
            component={CoApplicantIncome}
          />
          <PrivateRoute path="/saathi-tools" component={MyTools} />
          <PrivateRoute path="/loanOffer" component={LoanOffer} />    
          <PrivateRoute path="/my-tool-cibilnew/:lead_id" component={NewHomeCibil} /> 
          <PrivateRoute path="/my-tool-cibil/" component={NewHomeCibil} /> 
          <PrivateRoute path="/my-credit-reports" component={MyCreditReports} />   
          {/* <PrivateRoute path="/payout-calculator" component={PayoutCalculator} />  */}
          <PrivateRoute path="/payout-calculator" component={NewPayoutCalculator} />         
          <PrivateRoute path="/emi-calculator" component={EmiCalculator} />  
          <PrivateRoute path="/apf-search" component={APFsearch} />    
          <PrivateRoute path="/approve-invoice" component={ApproveInvoice} />
          <PrivateRoute path="/scheme-details" component={PayoutScheme} />     
          <PrivateRoute path="/help-support" component={HelpAndSupport} />    
{/* <Route path="/lead-detail/:lead_id" component={LeadDetail} /> */}
          <Route path="/no-access" component={NoAccess} />
          <PrivateRoute path="/website-settings" component={WebsiteSettings} />     


          <Route path="/freshdesk-ticket" component={FreshdeskCreateTicket} />
        </div>
        {/* {isLoggedIn == 1 ? <Footer /> : ''} */}
        {/* <Footer /> */}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_loggedin: state.userLoggedIn,
  };
};
export default connect(mapStateToProps, null)(CustomRouter);