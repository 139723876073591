import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import { Link, useLocation } from "react-router-dom";
import InputField from "../../../view/elements/Input";
import Modal from "../../../view/common/Modal";
import ApproveInvoiceOtp from "../ApproveInvoiceOtp";
import { FINEX_BACKEND_API_KEY, API_URL, API_KEY_PARTNER } from "../../../config/constant";
import { executeGraphQLQuery ,executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { HANDLE_INVOICE_GENERATION, CREDIT_DEBIT_REGENERATION, INVOICE_REGENERATION,SEND_INVOICE_OTP } from "../../../queries/payout.gql";
import { toast } from "react-toastify";
import iIcon from "../../images/i-icon.png";
import PageLoader from "../../../view/elements/PageLoader";
import { format } from "crypto-js";



const ApproveInvoice = (props) => {  
  const updateStateIntoRedux = props.updateStateIntoRedux;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [invoiceGenerated, setInvoiceGenerated] = useState(queryParams.get("url"));
  const [inputAddress, setInputAddress] = useState(queryParams.get("address") != 'undefined'? queryParams.get("address") : queryParams.get("partner_address"));
  const [approveOtp, setApproveOtp] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceId: "",
    creditNo: "",
    creditDate: "",
    registeredAddress: "",
    invoiceApproval: "",
    invoiceAddress: "",
    invoiceDate: "",
    address: "",
    invoiceType: "",
    paymentStatus: "",
  });
  
  const [pdfUrl, setPdfUrl] = useState("");
  const [readonly, setReadonly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noteDate, setNoteDate] = useState(queryParams.get("invoice_date"));
  const closeModal = () => setApproveOtp(false);
  
   const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === "creditDate") {
        const { min, max } = getCurrentMonthRange();
        if (value < min || value > max) {
          toast.error("Please select a date within the current month.");
          return;
        }
      }
      if (name === "registeredAddress") {
        setInputAddress(value);
      }

      setInvoiceDetails((prevDetails) => {
        if (name === "creditDate") {
          return {
            ...prevDetails,
            creditDate: formatDate(value),
            invoiceDate: formatDate(value),
          };
        } else {
          return {
            ...prevDetails,
            [name]: value,
          };
        }
      });
    }
    function getQueryParams(url) {
      const params = new URLSearchParams(url.split('?')[1]);
      const queryObj = {};
      
      params.forEach((value, key) => {
          queryObj[key] = value;
      });
      
      return queryObj;
  }
   

  

  useEffect(() => {
    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    
    const pdfUrlParam = queryParams.get("url");
    const invoiceIdParam = queryParams.get("invoice_id");
    const invoiceNoParam = queryParams.get("invoice_no");
    const createdAtParam = queryParams.get("created_at");
    const invoiceApprovalParam = queryParams.get("invoice_approval");
    const invoiceAddressParam = queryParams.get("invoice_address");
    const addressParam = queryParams.get("address") != 'undefined'? queryParams.get("address") : queryParams.get("partner_address");
    const invoiceDateParam = queryParams.get("invoice_date");
    const partnerAddressParam = queryParams.get("partner_address");
    const invoiceTypeParam = queryParams.get("invoice_type");
    const paymentStatus = queryParams.get("paid_status");

    if (invoiceApprovalParam === "1") {
      setReadonly(true);
    }
    if (pdfUrlParam) {
      setPdfUrl(decodeURIComponent(pdfUrlParam));
    } else {
      setPdfUrl("");
    }
    const invoice_details  = {
      invoiceId: invoiceIdParam || "N/A",
      creditNo: invoiceNoParam || "N/A",
      creditDate: formatDate(decodeURIComponent(invoiceDateParam.indexOf("/") > -1 ? invoiceDateParam : createdAtParam)),
      registeredAddress: addressParam.indexOf("null") > -1 ? partnerAddressParam : addressParam || "N/A",
      invoiceApproval: invoiceApprovalParam || "N/A",
      invoiceAddress: invoiceAddressParam || "N/A",
      address: addressParam || "N/A",
      invoiceDate: decodeURIComponent(invoiceDateParam) || "N/A",
      invoiceType: invoiceTypeParam || "N/A",
      paymentStatus: paymentStatus || "N/A",
      partner_id : queryParams.get("partner_id"),
      partner_name : queryParams.get("partner_name"),
    };
     setInvoiceDetails(invoice_details);
    // Update Redux state for logged-in user
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      updateStateIntoRedux("LOGIN_USER", loginResponse);
    }
  }, [location.search, updateStateIntoRedux]);

  const handleGenerateInvoice = async () => {
    const variables = {
      UpdateInvoiceStatus: {
        id: Number(invoiceDetails.invoiceId),
        paid_status: "invoice_created"
      },
    };
    
    try {

    const result = executeGraphQLMutation( HANDLE_INVOICE_GENERATION, variables);
      
      if (result.data?.update_invoice_status?.status) {
          setInvoiceGenerated(true);
      } else {
        toast.error(`Failed to generate invoice: ${result.data?.update_invoice_status?.msg || "Unknown error"}`);
      }
    } catch (error) {
      toast.error("An error occurred while generating the invoice.");
    }
  };

  const handleOpenOtp = async () => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));    
    
    try {
      const partnetId = loginUserInfo.user_data.dealer_id;
      const invoiceId = invoiceDetails.invoiceId;
      const mobile = loginUserInfo.user_data.mobile;
      const hash = "TSHS687363GS";
      const result = await executeGraphQLQuery(SEND_INVOICE_OTP(partnetId, invoiceId, mobile, hash));

      if (result && result.data?.send_invoice_otp?.status) {
        toast.success(`OTP sent successfully! Message: ${result.data.send_invoice_otp.msg || "Please check your SMS"}`);
        setApproveOtp(true);
        document.body.classList.add("overflow-hidden");
      } else {
        toast.error(`Failed to send OTP: ${result.data?.send_invoice_otp?.msg || "Unknown error"}`);
      }
    } catch (error) {
      toast.error("An error occurred while sending the OTP.");
    }
  };
 
  const handleCloseOtp = () => {
    setApproveOtp(false);
    document.body.classList.remove("overflow-hidden");
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    if (dateString.indexOf("/") > -1) {
      const parts = dateString.split("/");
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return dateString; // Assume already in `YYYY-MM-DD` format.
  };  

  const handleReGenerateInvoice = async () => {
  
    try {
      setIsLoading(true);
      let result = {};
        if(invoiceDetails.invoiceType === 'invoice'){
          const loginResponse = secureStorage.getItem("loginUserInfo");
          result = await executeGraphQLQuery(INVOICE_REGENERATION(
            invoiceDetails.invoiceId, 
            invoiceDetails.creditNo, 
            loginResponse.data.dealer_id, 
            (invoiceDetails.registeredAddress === inputAddress) ? 0 : 1,
            invoiceDetails.invoiceDate, 
            inputAddress
            ));
        }
        else{
          const variables = {
            UpdateCreditDebitPdf: {
              invoice_id: Number(invoiceDetails.invoiceId),
              invoice_no: invoiceDetails.creditNo,
              note_date: invoiceDetails.creditDate,
              address: inputAddress,
            },
        };
     
        result = await executeGraphQLMutation(CREDIT_DEBIT_REGENERATION, variables);
      }

      if(result.data?.update_invoice?.status == 1){
        setInvoiceGenerated(true);
        setPdfUrl(result.data?.update_invoice?.invoice_url); 
      }
      else if (result.data?.update_credit_debit_pdf?.status ) {
        setInvoiceGenerated(true);
      } else {
        toast.error(`Failed to re-generate invoice: ${result.data?.update_credit_debit_pdf?.msg ||result.data?.update_invoice?.msg || "Unknown error"}`);
      }
      setIsLoading(false);

    } catch (error) {
      toast.error("An error occurred while re-generating the invoice.");
    }
  };
  
  const getCurrentMonthRange = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
    return {
      min: firstDay.toISOString().split("T")[0], // Format as YYYY-MM-DD
      max: lastDay.toISOString().split("T")[0],
    };
  };

  const noteType = invoiceDetails.invoiceType !== 'undefined' ? invoiceDetails.invoiceType.charAt(0).toUpperCase() + invoiceDetails.invoiceType.slice(1) : "";
  
  return(
    <>
      <div className="LeadContainer">
        <div className="approvecard">
          <Link to="/my-saathi-earnings">
            <div>
              <img
                src={require(`../../../../src/saathi-web/images/left arrow.svg`)}
                alt="Back"
              />
            </div>
          </Link>
          <div>
            {noteType == "Invoice" ? noteType : noteType + "  Note"} - <span>{String(invoiceDetails.creditNo).slice(-5)}</span>
          </div>
        </div>
        {invoiceDetails.invoiceType && 
        <div className="approveinovicecard">
          <div className="approveleftcard">
            <div className="emptycontent">
              {isLoading ? (<PageLoader />) :
                pdfUrl && pdfUrl !== "#" ? (
                  <iframe
                    src={pdfUrl}
                    title="Invoice PDF"
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                  ></iframe>
                ) : (
                  <div>
                    <img src="https://static.ambak.com/saathi/1727502314187.svg" alt="no data" />
                  </div>
                )
              }
              
            </div>
          </div>

          <div className="approverigthcard">
            <div style={{ padding: "25px" }}>
              <h5>{noteType == "Invoice" ? noteType + " Details" : noteType + "  Note Details"}</h5>
             
              {(invoiceDetails.partner_id == secureStorage.getItem("loginUserInfo").data.dealer_id) ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <InputField
                        type="text"
                        name="creditNo"
                        className="form-input-new"
                        title={`${noteType} No.`}
                        placeholder={`${noteType} No.`}
                        label={`${noteType} No.`}
                        value={invoiceDetails.creditNo}
                        onChange={handleInputChange}
                        readOnly={readonly}
                        min={getCurrentMonthRange().min}
                        max={getCurrentMonthRange().max}
                      />
                    </div>
            
                    <div className="col-md-12">
                      <InputField
                        type="date"
                        name="creditDate"
                        className="form-input-new"
                        title="Date"
                        placeholder=""
                        label="Date"
                        value={invoiceDetails.creditDate}
                        onChange={handleInputChange}
                        readOnly={readonly}
                      />
                    </div>
                    <div className="col-md-12">
                      <InputField
                        type="text"
                        name="registeredAddress"
                        className="form-input-new"
                        title="Registered Address"
                        placeholder="Registered Address"
                        label="Registered Address"
                        value={inputAddress}
                        onChange={handleInputChange}
                        readOnly={readonly}
                      />
                    </div>
                  </div>
                  {!readonly && (
                    <>
                      {!pdfUrl || pdfUrl == "#" ? (
                        <div style={{ marginBottom: 50 }}>
                          <button className="generateinvoice" onClick={handleReGenerateInvoice}>
                            Update {noteType == "Invoice" ? noteType : noteType + "  Note"}
                          </button>
                        </div>
                      ) : (
                        <div className="generateinvoiceafter">
                          <button className="regenerateinvoice" onClick={handleReGenerateInvoice}>
                            Update {noteType == "Invoice" ? noteType : noteType + "  Note"}
                          </button>
                          <button className="generateinvoice" onClick={handleOpenOtp}>
                            Approve {noteType == "Invoice" ? noteType : noteType + "  Note"}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="vendor-message">
                  <div className="message-left">
                    <img className="i-icon" src={iIcon} alt="i" />
                  </div>
                  <div className="message-right">
                    <div>
                      <p className="vendor-text">This {noteType === "Invoice" ? "invoice" : noteType.toLowerCase() + " note"} is raised to your vendor - {invoiceDetails.partner_name + " (" + invoiceDetails.partner_id + ") "}
                        so only they can edit and approve it
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="queryraise" style={{ display: 'none' }}>
              <img
                src="https://static.ambak.com/saathi/1731911524225.png"
                alt="query"
                width={20}
                height={20}
              />
              <div>Raise Query</div>
            </div>
          </div>

          <div>
            <Modal isOpen={approveOtp} togglePopup={handleCloseOtp} width={600}>
              <ApproveInvoiceOtp invoice_id={invoiceDetails.invoiceId} note_type={noteType} credit_no={invoiceDetails.creditNo} payment_status={invoiceDetails.paymentStatus} closeModal={closeModal}/>
            </Modal>
          </div>
        </div>}
      </div>
  </>
);
};


const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(ApproveInvoice)
);
