import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import { useCleverTap } from '../../CleverTapContext';
import Formsy from "formsy-react";
import FormsyInputField from "../../../view/elements/FormsyInputField";
import InputField from "../../../view/elements/Input";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import BottomModal from "../../../view/common/BottomModal";
import { executeGraphQLQuery, executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import { BankLogo, Payout_Calculator} from "../../../queries/payout.gql";
import { handlePriceInputIndian } from "../../MyLeads/helpers/helpers";


const NewPayoutCalculator = (props) => {
    const { updateStateIntoRedux } = props;
    const [showSearchDiv, setShowSearchDiv] = useState(true);
    const [showAgeCalculator, setShowAgeCalculator] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [openBanksMob, setOpenBanksMob] = useState(false);
    const [partnerId, setPartnerId] = useState('');


    const [loanAmount, setLoanAmount] = useState("");
    const [payoutData, setPayoutData] = useState([]);

    const [productType, setProductType] = useState(null);
    const [fulfilmentType, setFulfilmentType] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [masterData, setMasterData] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const trackEvent = useCleverTap();
    const history = useHistory();

    const handleOpenBank = () => {
        setOpenBanksMob(true);
        // alert("Popup opened!");
    };

    const handlecloseBank = () => {
        setOpenBanksMob(false);
    };
    const handleCloseModal = () => {
        setOpenModal(false)
        document.body.classList.remove("overflow-hidden");
    }
    const handleOpenModal = () => {
        // alert("HHHHHHHH")
        setOpenModal(true)
        document.body.classList.add("overflow-hidden");
    }

    const ALL_OPTIONS = {
        PRODUCT_TYPE: [
            { label: "Home Loan", value: "1" },
            { label: "Loan Against Property", value: "2" },
        ],
        FULFILMENT_TYPE: [
            { label: "I will do it myself", value: "self" },
            { label: "I will refer to Ambak", value: "ambak" },
        ],
      
    };
    

    const handleback = async () => {
        history.push(`/saathi-tools`);
    }
    const handleSelectChange = (data, field) => {
        switch (field) {
            case "loan_amount":
                const numericValue = data.replace(/\D/g, ""); 
                setLoanAmount(numericValue);
                break;
    
            case "product_type":
                setProductType(data);
                break;
    
            case "fulfilment_type":
                setFulfilmentType(data);
                break;
    
            default:
                break;
        }
    };
    
    
    

    
    let idsToFilter = [];
    
    const handleSeachChange = () => {
        idsToFilter = [];
        if(searchText === ""){
            calculatepayout();
            return;
        }
        //console.log("MasterData", masterData);
        let dataCopy = Object.keys(masterData);
        //console.log("data copy", dataCopy);
        //console.log("payoutData", payoutData);
        const filteredData = dataCopy.filter((bankName) => {
            
            // console.log("Bank Name:", bankName, typeof bankName);
            if (bankName.toLowerCase().includes(searchText.toString().toLowerCase())) {
                //console.log(bankName)
                idsToFilter.push(masterData[bankName]);
            }
        });
        //console.log("Payout",payoutData);
        calculatepayout();
        // console.log("data copy", dataCopy);

        // console.log("Filtered payoutData:", payoutData);
        //console.log("Filtered Data:", idsToFilter);
        
    };

    const handleKeyChange = (e) => {
        if (e.keyCode === 13) {
            handleSeachChange();
        }
    }
    
    let bankToId = [];
    const calculatepayout = async (type) => {

        try{
           
            const integerAmount=parseInt(loanAmount.replace(/\D/g, ''), 10) || 0;
            //console.log("This worked", integerAmount, fulfilmentType, productType);
            const login = secureStorage.getItem("loginUserInfo");

            let partner_id = "";
            if(login){
                partner_id = login.data.dealer_id;
            }
            let result = {};
            //console.log("This worked", integerAmount, fulfilmentType.value, partner_id, productType.value);
            
            if(type!= "reset" && Object.keys(idsToFilter).length > 0){
                //console.log("TThis rann 22222", typeof idsToFilter);
                result = await executeGraphQLQuery(Payout_Calculator(integerAmount, fulfilmentType.value, partner_id, productType.value, idsToFilter));
            }
            else{
                //console.log("This rann 11111");
                setSearchText("");
                idsToFilter = [];
                result = await executeGraphQLQuery(Payout_Calculator(integerAmount, fulfilmentType.value, partner_id, productType.value));
            }
            
            //console.log("Result:", result); 


            if (result){
                try{

                const masterResult = await executeGraphQLQueryFinex(BankLogo);
               

                //console.log(" Result:", result);
                const payout_data = result.data.payout_calculator_v1.map(payout => {
                const bankLogos = masterResult.data.masterdata.bank_list.reduce((acc, bank) => {
                    acc[bank.id] = bank.logo;
                    return acc;
                }, {});

                

                return {
                    ...payout,
                    bank_logos: bankLogos
                };
                });
                
                //console.log('local Payout Data:', typeof payout_data, payout_data);

                
                
                Object.keys(payout_data).forEach(key => {
                    
                    payout_data[key].banks_name = payout_data[key].banks_name.split(",");
                });

                // console.log('Local Payout Data Length:', payout_data.length);
                //console.log('Local Payout Data:', payout_data);

                const final_list = {};

                for (const entry in payout_data){
                    const id_list= payout_data[entry].banks;
                    const logos = payout_data[entry].bank_logos;
                    const banks_name = payout_data[entry].banks_name;
                    const pay_per = payout_data[entry].pay_per;
                    const pay_amt = payout_data[entry].payout_amount;
                    const is_on_payin = payout_data[entry].is_on_payin;
                    const highlights = payout_data[entry].highlights;
                    
                    id_list.forEach((id, index) => {
                        if(Object.keys(payoutData).length === 0){
                            bankToId[banks_name[index]] = id;
                        }
                        
                        

                        final_list[banks_name[index]] = [
                            logos[id],
                            pay_per,
                            pay_amt,
                            id,
                            is_on_payin,
                            highlights
                        ];

                    });
                }
                
                
                //console.log("Final List:", final_list);
                // console.log("Final List Length:",typeof Object.keys(final_list));
                const filteredFinalList = Object.keys(final_list).reduce((acc, key) => {
                    if (key !== "undefined" && key.trim() !== "") {
                        acc[key] = final_list[key];
                    }
                    return acc;
                }, {});
                if(Object.keys(payoutData).length === 0){
                    setMasterData(bankToId);
                    
                }
                setPayoutData(filteredFinalList);
                

                } catch (error) {
                    console.error("Error calculating payout:", error);
                }
            }
        } catch (error) {
            
            console.error("Error calculating payout:", error);
        }

        setShowSearchDiv(false);
        setShowAgeCalculator(true);
    }

    
    const BacktoCalPayout = () => {
        setShowSearchDiv(true);
        setShowAgeCalculator(false);
    }
    const EmiCheck = () => {
        history.push("/emi-calculator");
    }
    const handleSchemeClick = () => {
        history.push('/scheme-details');
    };



    const validate = () => {
        let errors = {};
      
        if (!loanAmount.trim()) {
          errors.loanAmount = "Loan Amount is required";
        }
        if (!productType) {
          errors.productType = "Product Type is required";
        }
        if (!fulfilmentType) {
          errors.fulfilmentType = "Fulfilment Type is required";
        }
      
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
      };
    useEffect(() => {
        trackEvent("page_loaded", "payout_cal_landing");
        const updateReduxData = async () => {
            const loginResponse = secureStorage.getItem("loginUserInfo");
            if (loginResponse) {
                updateStateIntoRedux("LOGIN_USER", loginResponse);
                setPartnerId(loginResponse.data.dealer_id)
            }
        };
        updateReduxData();
    }, [updateStateIntoRedux]);
    return (
        <>
            <div className="PayoutCalmain">
                <div className="payoutCalLeft">
                    {showSearchDiv && (
                        <>
                            <div className="leadheadingPYT">
                                <i className="ic-arrow_back" style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }} onClick={handleback}></i>
                                Payout Calculator</div>
                            <div className="newpayoutheading">Your Earnings on <span style={{ color: "#EE6F39" }}> your fingertips</span></div>
                            <div className="PayoutlistCont" style={{ display: "flex" }}>
                                <div className="payoutlistone">
                                    <div>
                                        <img src="https://static.ambak.com/saathi/1726573806997.svg" />
                                    </div>
                                    <div>Estimate your Payouts in one simple step.</div>
                                </div>

                                <div className="payoutlisttwo">
                                    <div>
                                        <img src="https://static.ambak.com/saathi/1726573876017.svg" />
                                    </div>
                                    <div>Analyse the bank which helps you earn the most.</div>
                                </div>

                               
                            </div>
                            <div className="PayoutlistCont1">
                            <div className="payoutlistthree">
                                    <div>
                                        <img src="https://static.ambak.com/saathi/1726573912153.svg" />
                                    </div>
                                    <div>Know your exact payout percentage & scheme for each bank.</div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "30px" }}>
                                <div style={{ marginBottom: "30px" }}>
                                    <Formsy>
                                        <div className="col-md-6" key={"first_name"}>
                                            <FormsyInputField
                                                id="loan_amount"
                                                name="loan_amount"
                                                type={"text"}
                                                value={loanAmount? "₹   "+handlePriceInputIndian(loanAmount) : ""}  
                                                placeholder="Enter Loan Amount"
                                                label={"Loan Amount"}
                                                onChange={(e) => handleSelectChange(e.target.value.replace(/[^0-9]/g, ""), "loan_amount")} 
                                                validations={false}
                                                validationError={formErrors.loanAmount ? formErrors.loanAmount : ""}
                                                readOnly={false}
                                                required={!!formErrors.loanAmount} // Set required dynamically
                                            />
                                            {/* {formErrors.loanAmount && <span style={{ color: 'red' }} className="error-msg-txt">{formErrors.loanAmount}</span>} */}
                                        </div>
                                    </Formsy>
                                    <div className="col-md-6">
                                        <label>Product Type</label>
                                        <Select
                                            id="product_type"
                                            options={ALL_OPTIONS["PRODUCT_TYPE"]}
                                            placeholder="Select Product Type"
                                            onChange={(data) => {
                                                handleSelectChange(data, "product_type");
                                                setFormErrors({ ...formErrors, productType: "" });
                                            }}
                                            name="product_type"
                                            value={productType || ""}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                        />
                                        {formErrors.productType && <p style={{ color: 'red' }} className="errormeasscal">{formErrors.productType}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row mobilinput">

                                <div className="col-md-6">
                                    <label>Fulfilment Type</label>
                                    <Select
                                        id="fulfilment_type"
                                        options={ALL_OPTIONS["FULFILMENT_TYPE"]}
                                        placeholder="Fulfilment Type"
                                        onChange={(data) => {
                                            handleSelectChange(data, "fulfilment_type")
                                            setFormErrors({ ...formErrors, fulfilmentType: "" });
                                        }}
                                        name="fulfilment_type"
                                        value={fulfilmentType || ""}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                    />
                                     {formErrors.fulfilmentType && <p style={{ color: 'red' }} className="errormeasscal">{formErrors.fulfilmentType}</p>}
                                </div>
                            </div>
                                
                           
                            <button className="payoutcalculate"
                                // disabled ={
                                //     !loanAmount ||
                                //     !productType ||
                                //     !fulfilmentType
                                // }
                                onClick={() =>{

                                    if (validate()) {
                                        calculatepayout();
                                      }
                                    }}>
                                Calculate now
                            </button>
                        </>
                    )}

                    {showAgeCalculator && (
                        <>
                            <div className="payoutTop">
                                <div className="leadheadingPT">
                                    <i className="ic-arrow_back" style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }} onClick={BacktoCalPayout}></i>
                                    Payout Details
                                </div>
                                <div className="searchMaindiv">
                                    <div className="col-md-7">
                                        <div className="sibhh">
                                            
                                            <InputField
                                                id="search_text"
                                                key={"hardcoded_key"}
                                                
                                                type="string"
                                                name="search_text"
                                                value={searchText || ""}
                                                onChange={(e) => setSearchText(e.target.value)}
                                                className="form-input-new"
                                                title={"SEARCH_TEXT"}
                                                placeholder={"Search your tickets..."}
                                                onKeyDown={handleKeyChange}
                                                
                                                
                                            />
                                            
                                            <div className="searchiconforword">
                                                    
                                           
                                                <i
                                                onClick={handleSeachChange}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleSeachChange();
                                                    }
                                                }}
                                                className="ic-search" style={{ fontSize: 20 }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="savebtnDiv">
                                            <button className="accountnextbtn"
                                            disable ={!searchText}
                                            onClick = {() => {
                                               
                                                calculatepayout("reset");
                                            }}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="EnterloanamtCont">
                                <div className="col-md-4 fontsiz14" style={{ borderRight: "1px solid rgb(230, 230, 230)" }}>
                                   
                                    <InputField
                                        type="text"
                                        name="pan_card"
                                        id="pan_card"
                                        placeholder="Enter Loan Amount"
                                        label="Loan Amount"
                                        value={loanAmount? "₹   "+handlePriceInputIndian(loanAmount) : ""}
                                        onChange={(e) => handleSelectChange(e.target.value.replace(/[^0-9]/g, ""), "loan_amount")}
                                        required={true}
                                        error={""}
                                        //maxLength="10"
                                        //readOnly={true}
                                    />
                                </div>
                                <div className="col-md-4" style={{ borderRight: "1px solid rgb(230, 230, 230)" }} >
                                    <label>Product Type</label>
                                    <Select
                                        id="product_type"
                                        placeholder = "Select Product Type"
                                        options={ALL_OPTIONS["PRODUCT_TYPE"]}
                                        onChange={(data) => handleSelectChange(data, "product_type")}
                                        name="product_type"
                                        value={productType || ""}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                    />
                                </div>
                                <div className="col-md-4" style={{ borderRight: "1px solid rgb(230, 230, 230)" }} >
                                    <label>Fulfilment Type</label>
                                    <Select
                                        id="fufilment_type"
                                        placeholder = "Select Fulfilment Type"
                                        options={ALL_OPTIONS["FULFILMENT_TYPE"]}
                                        onChange={(data) => handleSelectChange(data, "fulfilment_type")}
                                        name="fulfilemnt_type"
                                        value={fulfilmentType|| ""}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                    />
                                </div>
                                <div className="savebtnDiv">
                                    <button className="accountnextbtn" onClick={() => {
                                        
                                        if(!loanAmount){
                                            alert("Please enter Loan Amount");
                                            return;
                                        }
                                        calculatepayout()
                                        }}>
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className="MobContMain">
                                <div className="MobileEnterloanamtCont">
                                    <div className="amontdiv">
                                        <div>
                                            <div className="Textspanpyt">Loan amount</div>
                                            {"₹ "+handlePriceInputIndian(loanAmount)}
                                            
                                        </div>
                                        <div>
                                            {/* "onjdsf" */}
                                            <div className="Textspanpyt">Fulfilment Type</div>
                                            
                                             {JSON.stringify(fulfilmentType.label)}

                                            
                                        </div>
                                    </div>
                                    <div className="amontdiv">
                                        <div>
                                            <div className="Textspanpyt">Product Type</div>
                                            <div>{JSON.stringify(productType.label)}</div>
                                        </div>
                                        <div onClick={handleOpenModal}>
                                            <img
                                                src={require("../../../../src/saathi-web/images/Editpen.svg")}
                                                alt="not"
                                                width={30}
                                                height={30}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <BottomModal isOpen={openModal} closeModal={handleCloseModal}>
                                    {/* <div className="mobileDeatilsInput"> */}
                                    <h4>Edit Deatails</h4>
                                    <div className="row" style={{ marginTop: "0px" }}>
                                        <div style={{ marginBottom: "0px" }}>
                                            <Formsy>
                                                <div className="col-md-2" key={"first_name"}>
                                                    <FormsyInputField
                                                        id={"ca_first_name"}
                                                        name={"ca_first_name"}
                                                        type={"text"}
                                                        value={loanAmount? "₹   "+handlePriceInputIndian(loanAmount) : ""}
                                                        placeholder=""
                                                        label={"Loan Amount"}
                                                        onChange={(e) => handleSelectChange(e.target.value.replace(/[^0-9]/g, ""), "loan_amount")}
                                                        validations={false}
                                                        validationError={""}
                                                        
                                                        required={true}
                                                    />
                                                </div>
                                            </Formsy>
                                            <div className="col-md-2" style={{ marginTop: "20px" }}>
                                                <label>Product Type</label>
                                                <Select
                                                    id="product_type"
                                                    options={ALL_OPTIONS["PRODUCT_TYPE"]}
                                                    placeholder = "Select Product Type"
                                                    onChange={(data) => { handleSelectChange(data, "product_type"); }}
                                                    name="employment_type"
                                                    value={productType}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.value}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "10px" }}>

                                        <div className="col-md-2">
                                            <label>Fulfilment Type</label>
                                            <Select
                                            id="fulfilment_type"
                                            placeholder = "Select Fulfilment Type"
                                            options={ALL_OPTIONS["FULFILMENT_TYPE"]}
                                            onChange={(data) => handleSelectChange(data, "fulfilment_type")}
                                            name="fulfilment_type"
                                            value={fulfilmentType}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                        />
                                            {/* <div className="col-md-1" style={{ paddingRight: "0" }}>
                                                <div className="more-filter" onClick={handleOpenBank}>
                                                    <label className="morefilterbtnmob" htmlFor="toggleCheckbox">
                                                        <span >
                                                            <img
                                                                src={require("../../../../src/saathi-web/images/filtericon.svg")}
                                                                alt="not"
                                                                width={16}
                                                                height={16}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <button onClick={() => {handleCloseModal(); calculatepayout() }} className="payoutcalculate">
                                        {/* <div className="payoutcalculate"> */}
                                            Ok! Got it
                                        {/* </div> */}
                                    </button>
                                    
                                </BottomModal>
                                {openBanksMob && (
                                    <div className="bankaContScreenMobl">
                                        <div className="tooltipContainerpyt1">
                                            <div className="searchBANKCONT">
                                                <h1> <i className="ic-arrow_back"
                                                    style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }}
                                                    onClick={handlecloseBank}>
                                                </i>Select Banks</h1>
                                                <div className="MAINbankContCHECK">
                                                    <div className="bankContCHECK">
                                                        <div className="checkCont1">
                                                            <input
                                                                type="checkbox"
                                                                name="select_all"
                                                                id="select_all"
                                                                checked={false}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                        <span>HDFC Bank</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="clearbtnspytCont1">
                                                <button className="clrbtnpyt">Clear All</button>
                                                <button className="searchbtnpyt">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="searchContPYT">
                                    <div className="col-md-5" style={{ paddingRight: "0", paddingLeft: "0", display:"flex" }}>
                                        <div className="sibhh">
                                            
                                            <InputField
                                                id="search_text"
                                                key={"hardcoded_key"}
                                                type="text"
                                                name="search_text"
                                                value={searchText || ""}
                                                onChange={(e) => setSearchText(e.target.value)}
                                                className="form-input-new"
                                                title={"SEARCH_TEXT"}
                                                placeholder={"Search here"}
                                            // readOnly={true}
                                            />
                                            <div className="searchiconforword">

                                                    <i onClick={()=>{
                                                        if(!loanAmount){
                                                            alert("Please enter Loan Amount");
                                                            return; 
                                                        }
                                                        handleSeachChange();
                                                    }} 
                                                    className="ic-search" style={{ fontSize: 20 }}></i>
                                             </div>
                                        </div>
                                        <div className="col-md-3" style={{ paddingRight: "0", paddingLeft: "0"}}>
                                        <div className="savebtnDiv">
                                            <button className="accountnextbtnMob"
                                            disable ={!searchText}
                                            onClick = {() => {
                                                if(loanAmount === ""){
                                                    alert("Please enter Loan Amount");
                                                    return;
                                                }
                                                

                                                calculatepayout("reset");
                                            }}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-1" style={{ paddingRight: "0" }}>
                                        <div className="more-filter" onClick={handleOpenBank}>
                                            <label className="morefilterbtnmob" htmlFor="toggleCheckbox">
                                                <span >
                                                    <img
                                                        src={require("../../../../src/saathi-web/images/filtericon.svg")}
                                                        alt="not"
                                                        width={16}
                                                        height={16}
                                                    />
                                                </span>
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* <ReactTooltip
                                id="tooltip-dateFilter"
                                className="no-blur-tooltip"
                                place="bottom"
                                effect="solid" // ensures there's no fade/blur effect
                                backgroundColor="#fff"
                                textColor="#000"
                                clickable
                                getContent={() => (
                                    <>
                                        <div className="tooltipContainerpyt">
                                            {
                                            <h1>Select Banks</h1>
                                            {Object.entries(payoutData).map(([bankName, _]) => (
                                            <React.Fragment>
                                                <div className="checkCont">
                                                    <input
                                                        type="checkbox"
                                                        name="select_all"
                                                        id="select_all"
                                                        checked={""}
                                                        onChange={""}
                                                    />
                                                    <span>{bankName}</span>
                                                </div>
                                                
                                            </React.Fragment>
                                            ))}
                                            <div className="clearbtnspytCont">
                                                    <button className="clrbtnpyt">
                                                        Clear All
                                                    </button>
                                                    <button className="searchbtnpyt">
                                                        Search
                                                    </button>
                                                </div>
                                        </div>
                                    </>
                                )}

                            /> */}
                            <ReactTooltip
                                id="tooltip-dateFilter"
                                className="no-blur-tooltip"
                                place="bottom"
                                effect="solid" // ensures there's no fade/blur effect
                                backgroundColor="#fff"
                                textColor="#000"
                                clickable
                                getContent={() => (
                                    <>
                                        <div className="tooltipContainerpyt">
                                            {/* //############# */}
                                            <h1>Select Banks</h1>
                                            {Object.entries(payoutData).map(([bankName, _]) => (
                                            <React.Fragment>
                                                <div className="checkCont">
                                                    <input
                                                        type="checkbox"
                                                        name="select_all"
                                                        id="select_all"
                                                        checked={""}
                                                        onChange={""}
                                                    />
                                                    <span>{bankName}</span>
                                                </div>
                                                
                                            </React.Fragment>
                                            ))}
                                            <div className="clearbtnspytCont">
                                                    <button className="clrbtnpyt">
                                                        Clear All
                                                    </button>
                                                    <button className="searchbtnpyt">
                                                        Search
                                                    </button>
                                                </div>
                                        </div>
                                    </>
                                )}

                            />
                               <div className="MainBottomCont">
                                {Object.entries(payoutData).map(([bankName, [logo, payoutPercentage, payoutAmount, _, is_on_payin, highlights]]) => (
                                    <div className="PayoutbankContmain" key={bankName}>
                                        <div className="PayoutbankCont">
                                            <div className="LeftBankAMt">
                                                <img
                                                    src={logo}
                                                    //alt={}
                                                    width={22}
                                                    height={22}
                                                    style={{ marginRight: "12px" }}
                                                />
                                                {bankName}
                                            </div>
                                            <div className="RightBankAMt">
                                              {  is_on_payin === 0 &&(
                                                    <div>
                                                        Payout Amount
                                                        <div className="amountPercnt">
                                                            {"₹ "+handlePriceInputIndian(payoutAmount)}
                                                        </div>

                                                    </div>
                                                )}
                                                    
                                                <div>
                                                    Payout %
                                                    <div className="amountPercnt">
                                                        {payoutPercentage}% {is_on_payin === 1 ? "on Ambak collection": ""}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {highlights.length > 0 && (
                                        <div className="liContDiv">
                                            {Object.values(highlights).map((highlight, index) => (
                                            <ul className="ULCONT" key={index}> {/* Add a unique key for each <ul> */}
                                                <li>{highlight}</li>
                                            </ul>
                                            ))}
                                        </div>
                                        )}

                                            
                                        
                                    </div>
                                ))}
                                </div>
                            <div className="bottomContFix">
                                <div className="bulbContflx">

                                    <div>
                                        <img
                                            className="bulbimgRes"
                                            src={require("../../../../src/saathi-web/images/3DLAMP.svg")}
                                            alt="not"
                                            width={22}
                                            height={22}
                                        />
                                    </div>
                                    <div>
                                        The amounts mentioned are based on max possible slab for your scheme. They are subject to business volume done with Ambak. <br />
                                    </div>
                                </div>
                                <div className="underlinetext" onClick={handleSchemeClick}> Check all scheme details here → </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="payoutRight">
                    <div className="verificationheadingmain">Other Tools</div>
                    <ul className="otherlinkshow1">
                        <li>
                            <Link to="#">
                                <div className="otherlinkslist">
                                    <img
                                        src="https://static.ambak.com/saathi/1723193405707.png"
                                        alt="home"
                                        width={60}
                                        height={60}
                                    ></img>
                                    <div className="cibiltextwidth" onClick={EmiCheck}>EMI Calculator</div>
                                    <div>
                                        <img
                                            src="https://static.ambak.com/saathi/1724140240138.png"
                                            alt="icon"
                                            width={12}
                                            height={14}
                                        ></img>
                                    </div>
                                </div>
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
    updateStateIntoRedux: (actionType, stateData) =>
        dispatch({ type: actionType, componentState: stateData }),
});
export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(NewPayoutCalculator)
);