import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { FINEX_MASTER } from "../../queries/scheme.gql";
import FileInput from "../NewLogin/Components/Input/FileInput";
import { fileUploader } from "../Utility/user.utility";
import { toast } from "react-toastify";

const BankDetailsProfile = (props) => {
  const { 
    errors, 
    docList, 
    getOnChangeValue, 
    kyc_save_value, 
    set_kyc_save_value, 
    dealer_hash_id, 
    HandleKycData, 
    bankDetails 
  } = props; 
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [bankList, setBankList] = useState([]);
  const [fileUploadArr, setFileUploadArr] = useState([]);
  const [checque, setChecque] = useState(props.docsImages?.cancel_checque || "");
  const [formData, setFormData] = useState({
    beneficiary_name: bankDetails?.beneficiary_name || "",
    account_number: bankDetails?.bank_account_no || "",
    ifsc_code: bankDetails?.bank_ifsc_code || "",
    upi_id: bankDetails?.upi_id || "",
    cancel_checque: "",
    bank_name: bankDetails?.bank_id || "",
  });

  useEffect(() => {
    const getBankList = async () => {
      try {
        const masterData = await executeGraphQLQueryFinex(FINEX_MASTER());
        setBankList(masterData.data.masterdata.bank_list);
      } catch (err) {
        console.error("Error fetching bank list:", err);
      }
    };

    let result = docList.find((data) => data.kyc_master_id === 4);
    if (result) {
      if (result.verified_status === "1") {
        setVerificationStatus("Verified");
      } else if (result.verified_status === "0") {
        if (result.reject_reason !== null) {
          setVerificationStatus("Rejected");
        } else {
          setVerificationStatus("Under Review");
        }
      } else {
        setVerificationStatus("Under Review");
      }
    }
  
    getBankList();
  }, [docList]);

  useEffect(() => {
    HandleKycData(formData, fileUploadArr, checque);
  }, [formData, fileUploadArr, checque]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = name === "ifsc_code" ? value.toUpperCase() : value;

    if (name === "account_number") {
      const numericValue = value.replace(/\D/g, '');
      setFormData((prev) => ({
        ...prev,
        [name]: numericValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    }

    set_kyc_save_value((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));

    getOnChangeValue({ ...kyc_save_value, [name]: formattedValue });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      bank_name: selectedOption ? selectedOption.bank_id : "",
    }));
    set_kyc_save_value((prev) => ({
      ...prev,
      bank_name: selectedOption ? selectedOption.bank_id : "",
    }));
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      try {
        const response = await fileUploader(files[0]);
        setChecque(response.file_url);

        setFileUploadArr((prevState) => [
          ...prevState,
          {
            dealer_id_hash: dealer_hash_id,
            kyc_master_id: 4, // Assuming 4 is for Cancel Cheque
            file_name: response.file_name,
            file_url: response.file_url,
            verified_status: "1",
          },
        ]);

        setFormData((prev) => ({
          ...prev,
          [name]: response.file_url,
        }));

        set_kyc_save_value((prev) => ({
          ...prev,
          [name]: response.file_url,
        }));
      } catch (err) {
        toast.error("Failed to upload file");
        console.error(err);
      }
    }
  };

  const bankOptions = bankList.map((bank) => ({
    bank_id: bank.banklang.bank_id,
    bank_name: bankDetails?.bank_name || bank.banklang.bank_name,
  }));

  const selectedBankValue = bankOptions.find(
    (option) => option.bank_id === Number(formData?.bank_name)
  ) || null;

  return (
    <div className="myaccountpadding">
      {formData.account_number  && verificationStatus === "Verified" ? <div className="verificationStatus webverificationStatus">Verified</div> :  verificationStatus === "Rejected"  ? <div className="RejectedStatus"  style={{marginLeft : "10%"}}>Rejected</div> : <div className="pendingStatus pendingstatusmobile" style={{marginLeft : "10%"}}>Under Review</div>}
      <h6 className="kycheadingmain">Bank Details</h6>
      <div>Bank details are critical to ensure payouts are done to the correct account.</div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-md-4">
          <InputField
            type="text"
            name="beneficiary_name"
            id="beneficiary_name"
            placeholder="Beneficiary Name"
            label="Beneficiary Name"
            value={formData.beneficiary_name}
            onChange={handleChange}
            required={true}
            error={errors.beneficiary_name}
          />
        </div>
        <div className="col-md-4">
          <InputField
            type="text"
            name="account_number"
            id="account_number"
            placeholder="Account Number"
            label="Account Number"
            value={formData.account_number}
            onChange={handleChange}
            required={true}
            error={errors.account_number}
          />
        </div>
        <div className="col-md-4">
          <label>Bank Name <span style={{ color: 'red' }}>*</span></label>
          <Select
            id="bank_name"
            name="bank_name"
            options={bankOptions}
            value={selectedBankValue}
            getOptionLabel={(option) => option.bank_name}
            getOptionValue={(option) => option.bank_id}
            onChange={handleSelectChange}
            styles={{
              control: (base) => ({
                ...base,
                borderColor: errors.bank_name ? 'red' : base.borderColor,
              }),
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <InputField
            type="text"
            name="ifsc_code"
            id="ifsc_code"
            placeholder="IFSC Code"
            label="IFSC Code"
            value={formData.ifsc_code}
            onChange={handleChange}
            required={true}
            error={errors.ifsc_code}
          />
        </div>
        <div className="col-md-4">
          <InputField
            type="text"
            name="upi_id"
            id="upi_id"
            placeholder="UPI ID"
            label="UPI ID"
            value={formData.upi_id}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label>Upload Cancel Cheque</label>
          <FileInput
            name="cancel_checque"
            id="cancel_checque"
            placeholder="Cancel Cheque"
            onChange={handleFileChange}
            fileUrl={formData.cancel_checque || checque}
            required={true}
            error={errors.cancel_checque}
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetailsProfile;
