import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Brandlogo from "../elements/Brandlogo";
import secureStorage from './../../config/encrypt';
import { connect } from 'react-redux';
import MasterService from './../../service/MasterService';
import NewNavbar from './NewNavbar';
import "../../saathi-web/saathi-web-style/header.css";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [redirect, setRedirect] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    logoutUser();
    secureStorage.removeItem('loginUserInfo');
    setRedirect(1);
    props.updateStateIntoRedux('LOGOUT_USER', '');
  };

  const logoutUser = () => {
    MasterService.post('account/user/logout', {})
      .then((res) => {
     //   console.log("logout");
        window.location.reload();
      })
      .catch(function (err) {
        console.error("searchError", err);
      });
  };

  useEffect(() => {
    // componentDidMount logic goes here (if needed)
  }, []);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle('body-hidden');
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  if (redirect === 1) {
    return <Redirect to="/saathi-leads" />;
  }

  const styles = {}; // Add your styles object if needed

  return (
     // <header className="mainHeader header-fixed clearfix">
      //   <span  className={ this.state.menuOpen ? "ic-clearclose hamburger-icon" : "ic-menu hamburger-icon" } open={this.state.menuOpen} onClick={()=>this.handleMenuClick()}></span>
      //   <Brandlogo />
      //   <NewNavbar logoutUser = {this.logout}  navClass={ this.state.menuOpen ? "open" : "" } style={styles.container}/>
        
      //   <div className={ this.state.menuOpen ? "modal-backdrop" : "modal-backdrop hide" } onClick={()=>this.handleMenuClick()}></div>
      // </header>
    
      
    <NewNavbar
      logoutUser={logout}
      navClass={menuOpen ? "open" : ""}
      style={styles.container}
    />
    
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  };
};

export default connect(null, mapDispatchToProps)(Header);
