import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import { GET_DETAILS_BY_PANCARD_UPDATE } from "../../../queries/sangam/cibil.gql";
import NewInputField from "../../../view/elements/NewInputField";
import { useCleverTap } from "../../CleverTapContext";
import Loader from "../../../view/elements/PageLoader";

const CreateLead = (props) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [formData, setFormData] = useState({
    loan_type: "",
    loan_sub_type: [],
    required_loan: "",
    employment_type: null,
    monthly_salary: "",
  });
  const [panDetails, setPanDetails] = useState({});
  const [isPanFetched, setIsPanFetched] = useState(false); 
  const { panNumber, leadData, handleDataFromVerificationCibil, mobile ,partner_id} = props;
  const trackEvent = useCleverTap();
  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (e) => {
    setMobileNumber(e.target.value);
    handleDataFromVerificationCibil(e.target.value);
  };

  const handleTabClick = (loanType) => {
    setFormData({ ...formData, loan_type: loanType });
  };

  const handleLoanSubTypeChange = (event) => {
    const { value, checked } = event.target;
  
    const loanSubTypeArray = Array.isArray(formData.loan_sub_type) ? formData.loan_sub_type : [];
  
    const updatedLoanSubType = checked
      ? [...loanSubTypeArray, value]
      : loanSubTypeArray.filter((item) => item !== value);
  
    setFormData({ ...formData, loan_sub_type: updatedLoanSubType });
  };

  useEffect(() => {
    if (panDetails) {
      setIsLoading(true); 
  
      setFormData({
        ...formData,
        pan_number: panDetails?.pan||panNumber,
        mobile_number: panDetails?.mobile_no||mobile,
        first_name: panDetails?.firstName || panDetails?.lastName,
        last_name: panDetails?.lastName || panDetails?.firstName,
        dob: panDetails?.dob ? formatDateForInput(panDetails.dob) : "",
        email: panDetails?.email,
      });
  
      setIsLoading(false); // Stop loading after updating state
    }
  }, [leadData, panDetails]);

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      setIsLoading(true);
  
      handleDataFromVerificationCibil("form_data", formData);
  
      setIsLoading(false); 
    }
  }, [formData]);
  

  const getDetailsByPancard = async (panNumber) => {
    if (!panNumber || panNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit PAN number.");
      return;
    }

    setIsLoading(true); // Start loading
    try {
      const response = await executeGraphQLQueryFinex(
        GET_DETAILS_BY_PANCARD_UPDATE(panNumber)
      );

      if (
        response &&
        JSON.parse(response?.data?.get_details_by_pancard)?.status === 200
      ) {
        const details = JSON.parse(response.data.get_details_by_pancard).data.response;
        setPanDetails(details);
        setIsPanFetched(true);
        handleDataFromVerificationCibil("pan_details", details);
        handleDataFromVerificationCibil("date_of_birth", details?.dob);
        toast.success("PAN details fetched successfully!");
      } else {
        toast.error("Invalid PAN or no details found.");
        setIsPanFetched(false);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      console.error("API Error:", error.message);
      setIsPanFetched(false);
    } finally {
      setIsLoading(false); 
    }
  };


  const formatDateForInput = (dob) => {
    const [day, month, year] = dob.split("/");
    return `${year}-${month}-${day}`;
  };

  const handleDataChange = (key, value) => {
    if(key==="pan_number"){
      setIsPanFetched(false)
    }
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-sm-6">
          { isLoading ? <Loader/> : ''}

            <NewInputField
              type="text"
              name="pan_card"
              id="pan_card"
              placeholder=""
              label="Pan No"
              onChange={(e) => handleDataChange("pan_number", e.target.value)}
              value={formData?.pan_number}
              maxLength={10}
              asterisk={true}
              verified={
                isPanFetched ? (
                  <img
                    src={require(`../../../saathi-web/images/correct.png`)}
                    alt="verified"
                    width={20}
                    height={20}
                  />
                ) : (
                  <button
                    onClick={() => getDetailsByPancard(formData?.pan_number)}
                    style={{
                      background: "none",
                      border: "none",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Fetch
                  </button>
                )
              }
            />
          </div>
          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="pan_card"
              id="pan_card"
              placeholder=""
              label="Pan-Linked Mobile No."
              value={formData?.mobile_number}
              onChange={(e) => handleDataChange("mobile_number", e.target.value)}

              maxLength={10}
              asterisk={true}
            />
          </div>
          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="full_name"
              id="full_name"
              placeholder=""
              label="First Name"
              value={formData?.first_name}
              onChange={(e) => handleDataChange("first_name", e.target.value)}
              asterisk={true}
            />
          </div>
          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="last_name"
              id="last_name"
              placeholder=""
              label="Last Name"
              value={formData?.last_name}
              onChange={(e) => handleDataChange("last_name", e.target.value)}
              asterisk={true}
            />
          </div>
          <div className="col-sm-6">
            <NewInputField
              type="date"
              name="dob"
              id="dob"
              placeholder="DOB"
              label="DOB"
              value={formData?.dob}
              onChange={(e) => handleDataChange("dob", e.target.value)}
              asterisk={true}
            />
          </div>
          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="email"
              id="email"
              placeholder=""
              label="Email"
              value={formData?.email}
              onChange={(e) => handleDataChange("email", e.target.value)}
            />
          </div>
        </div>

        <div>Loan Type</div>

        <div className="cibilloanflex">
          {/* Loan Type Tabs */}
          <div
            className={`cibilhometab ${
              formData?.loan_type === "homeloan" ? "active" : ""
            }`}
            onClick={() => handleTabClick("homeloan")}
          >
            {formData?.loan_type === "homeloan" ? (
              <img
                src="https://static.ambak.com/saathi/1724129825863.svg"
                alt="home"
                width={20}
                height={20}
              />
            ) : (
              <img
                src="https://static.ambak.com/saathi/1724129398984.svg"
                alt="loan"
                width={20}
                height={20}
              />
            )}
            <span>Home Loan</span>
          </div>

          <div
            className={`cibilhometab ${
              formData?.loan_type === "lap" ? "active" : ""
            }`}
            onClick={() => handleTabClick("lap")}
          >
            {formData?.loan_type === "lap" ? (
              <img
                src="https://static.ambak.com/saathi/1724130094744.svg"
                alt="home"
                width={20}
                height={20}
              />
            ) : (
              <img
                src="https://static.ambak.com/saathi/1724129903084.svg"
                alt="loan"
                width={20}
                height={20}
              />
            )}
            <span>Lap</span>
          </div>
        </div>

        {/* Loan Sub-Type Checkboxes */}
        <div className="loanfulfillment">
          <div className="loaninput">
            <input
              type="checkbox"
              id="balancetransfer"
              value="1"
              checked={formData?.loan_sub_type?.includes("1")}
              onChange={handleLoanSubTypeChange}
            />
            <label htmlFor="balancetransfer">Balance Transfer</label>
          </div>
          <div className="loaninput">
            <input
              type="checkbox"
              id="top-up"
              value="2"
              checked={formData?.loan_sub_type?.includes("2")}
              onChange={handleLoanSubTypeChange}
            />
            <label htmlFor="top-up">Top-Up</label>
          </div>
          <div className="loaninput">
            <input
              type="checkbox"
              id="od"
              value="3"
              checked={formData?.loan_sub_type?.includes("3")}
              onChange={handleLoanSubTypeChange}
            />
            <label htmlFor="od">OD</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLead;