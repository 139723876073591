import React, { useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { Button } from "react-bootstrap";
import { executeGraphQLMutationFinex } from "../../../common/executeGraphQLQuery";
import { CRIF_REPORT_DOWNLOAD } from "../../../queries/sangam/cibil.gql";
import { toast } from "react-toastify";
import Loader from "../../../view/elements/PageLoader";

const ScoreCibil = (props) => {

const {otpErrors,crifReport,partner_id,mobile,user_id,crifRef,panNumber,leadData,formData,createdLeadId}=props;
  const [value, setValue] = useState(Number(crifReport?.score||0));
  const [isLoading, setIsLoading] = useState(false);

  const getCibilStatus = (score) => {
    if (score < 420) return { text: "Poor", color: "#FF0000" };    // Red
    if (score >= 420 && score < 540) return { text: "Average", color: "#FFA500" }; // Orange
    if (score >= 540 && score < 660) return { text: "Good", color: "#FFFF00" };    // Yellow
    if (score >= 660 && score < 780) return { text: "Very Good", color: "#008000" }; // Green
    if (score >= 780) return { text: "Excellent", color: "#006400" };  // Dark Green
  };


  const { text, color } = getCibilStatus(value);

  const downloadCrifReport = async () => {
    setIsLoading(true); // Start loading

    try {
        if (crifReport?.pdf_pathh && crifReport.pdf_pathh.endsWith(".pdf")) {
            window.open(crifReport.pdf_path, "_blank", "noopener,noreferrer");
        } else {
            const variables = {
                crifInput: { 
                    pan_card: formData?.pan_number||leadData?.customer?.pancard_no,
                    mobile: Number(formData?.mobile_number||leadData?.customer?.mobile),
                    crif_ref: crifRef,
                    partner_id: `${partner_id || 1}`,
                    user_id: user_id,
                    lead_id: leadData?.id||createdLeadId
                },
            };

            const resp = await executeGraphQLMutationFinex(CRIF_REPORT_DOWNLOAD(), variables);
            
            if (resp?.data?.cibil_crif_report_download?.pdf_path) {
                window.open(resp?.data?.cibil_crif_report_download?.pdf_path, "_blank", "noopener,noreferrer");
            } else {
                const errMsg = resp?.data?.cibil_crif_report_download?.data?.errors || 
                               resp?.data?.cibil_crif_report_download?.errors || 
                               `Unable to download CRIF report.`;
                toast.error(errMsg);
            }
        }
    } catch (error) {
        console.error("\n\n downloadCrifReport >> error => ", error);
        toast.error(`Error in generating report`);
    } finally {
        setIsLoading(false); // Stop loading
    }
};
  return (
    
    <div style={{ textAlign: "center" }}>
      {crifReport &&(
         <>
         <div className="scorecard">

          <ReactSpeedometer
            maxValue={900}
            minValue={300}
            value={Number(crifReport?.score)}
            valueFormat={"d"}
            needleColor="#473A94"
            ringWidth={50}
            dimensionUnit={100}
            segmentColors={["#FF8059", "#FFA951", "#FFCE4A", "#04B088"]}
            showLabels={false}
            hideSegmentLabels={false}
            segments={5}
            needleTransitionDuration={5000}
            needleTransition="easeBackOut"
            needleHeightRatio={0.8} />
          <div className="showcibilscore">

            <div style={{color: "#00000099"}}>300</div>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 24, fontWeight: "600" }}>{value}</div>
              <div style={{ fontSize: 18, fontWeight: "600", color: color }}>{text}</div>
            </div>
            <div style={{color: "#00000099"}}>900</div>
          </div>
        </div>
        <div className="cibilreportheading">Credit Report is Ready</div>
             
            <div className="cibilnewpara">Click on the Download button to access the full report with details of lender-wise loans and DPDs.</div>
          <div className="offerbtncommon">

            <button className="downloadcibil" disabled={isLoading} onClick={downloadCrifReport}>
              Download Report
            </button>
          </div>
          { isLoading ? <Loader/> : ''}
          </> 
          

      )
      }
       {/* Not Fetch Report This Show  */}
      {!crifReport && (
         <div>
         <img
             src={require(`../../../../src/saathi-web/images/notcibil.png`)}
             alt="thankyou"
             width={250}
             height={150}
         />
 
         <div className="notfetchcibil">Could Not Fetch Report</div>
         <div className="cibilreportheading">
            {otpErrors.length > 0 && (
              otpErrors.map((item, index) => (
                <div key={index}>{item}</div>
              ))
            )}
         </div>
 
       </div>
      )}
     
    
    </div>
  );
};

export default ScoreCibil;
