import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import MasterService from "../../service/MasterService";
import { toast } from "react-toastify";
import secureStorage from "../../config/encrypt";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getUserAccess, getUserInfo, postApiUtility } from "../Utility/user.utility";
import EditIcon from "./Components/Icons/EditIcon";
import { useCleverTap } from '../CleverTapContext';


const NewLogin = ({ setKycForm  , isOnboard1}) => {
  const [showOtp, setShowOtp] = useState(false);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [otpTimer, setOtpTimer] = useState(0);
  const history = useHistory();
  const [error, setError] = useState({});
  const [isOnboard, setIsOnboard] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const trackEvent = useCleverTap();

  useEffect(() => {
    if(localStorage.getItem('isLogin')) {
      localStorage.setItem('isLogin' , false);
      history.push('/saathi-leads');
     }
  },[])

  useEffect(() => {
    if (!otpTimer) return;
    const intervalId = setInterval(() => {
        setOtpTimer(otpTimer - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [otpTimer]);


  const handleOnChange = (e) => {
    const {id, value} = e.target;
    if(id === 'mobile') {
      setMobile(value);
    } else if (id === 'otp') {
      setOtp(value);
    }
  };

  const isValidMobileNumber = (number) => {
    const mobileNumberPattern = /^[6-9][0-9]{9}$/;
    return mobileNumberPattern.test(number);
  };
  
  const submitOtpForm = () => {
    // Track the button click event with CleverTap
    trackEvent("Element Clicked", isOtpSend ? 'verify-otp' : 'submit-otp');
    // Reset errors
    setError((prevState) => ({
      ...prevState,
      mobileError: false,
      otpError: false,
    }));
  
    if (!isOtpSend) {
      if (!isValidMobileNumber(mobile)) {
        setError((prevState) => ({
          ...prevState,
          mobileError: true,
        }));
        toast.error("Please enter a valid 10 digit mobile number");
        return; // Exit the function if mobile number is invalid
      }
      sendOTP(mobile);
      // ShowOtpFields();
      // setIsDisabled(true);
    } else if (isOtpSend) {
      if (otp.length < 6) {
        setError((prevState) => ({
          ...prevState,
          otpError: true,
        }));
        toast.error("Please enter a 6 digit valid OTP");
        return; // Exit the function if OTP is invalid
      }
      if (isOnboard) {
        handleRegiterUser();
      } else {
        handleVerifyOTP();
      }
    }
  };
  

  const sendOTP = () => {
    const params = {
      mobile: mobile,
      "source": "onboarding"
    };
    MasterService.post("/account/user/send_otp", params)
      .then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setIsOtpSend(true);
          setIsDisabled(true);
          ShowOtpFields();
          setOtpTimer(60)
          if(response?.data?.data?.is_onboard) {
            setIsOnboard(response.data?.data?.is_onboard);
            isOnboard1(response.data?.data?.is_onboard)

          }
        } else {
          if (response.data.error && response.data.error.length > 0) {
              response.data.error.forEach((element) => {
              toast.error(element);
            });
          } else if (response.data.message) {
            toast.error(response.data.message);
          } else {
            toast.error("Failed");
          }
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
  };

  const handleVerifyOTP = () => {
    const params = {
      mobile: mobile,
      otp: otp,
      kind: "1",
    };

    MasterService.post("/account/user/validate_otp", params)
      .then(async function (response) {
        if (response.data.status === 200) {
          secureStorage.setItem('token', response.data.data.token);
          localStorage.setItem('token', response.data.data.token);
          
          secureStorage.setItem('loginUserInfo', response.data);
          localStorage.setItem("loginUserInfo", JSON.stringify(response.data.data));
         
     
          
         await fetchUserData();
          if (response.data.data.user_data.user_type !== 1){
            window.location.href = "/saathi-leads"
          }
          // toast.success(response.data.message);
              
        } else {
          if (response.data.error && response.data.error.length > 0) {
            response.data.error.forEach((element) => {
              toast.error(element);
            });
          } else if (response.data.message) {
            setError(prevState => ({
              ...prevState,
              otpError: true,
            }));
            toast.error(response.data.message);
          } else {
            toast.error("Failed");
          }
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
  };

  const handleRegiterUser = () => {
    const requestData = {
        "mobile": mobile,
        "is_pan_verified": 2,
        "user_type": "1",
        "term_condition": true,
        "partner_id": 0,
        "user_id": 0,
        "created_by": 0,
        "created_by_type": 0,
        "source": "sathi",
        "otp": +otp,
        "step": 1,
        "updated_by" : 0
    }
    MasterService.post('/partner/partner/save-basic-details', requestData)
    .then(async response => {
        if (response && response.data.status == 200 && response.status == 200) {
            toast.success("Registration successful");
            const regData = response?.data?.data?.response;
            handleVerifyOTP(otp);
            // const modifiedData = {
            //   ...regData,
            //   name : regData?.name ?? null,
            //   dealer_hash_id: regData?.dealer_id_hash ?? null,
            //   user_data : {
            //     user_id : regData?.user_id,
            //     dealer_id : regData?.dealer_id,
            //     mobile : regData?.mobile,
            //     name : regData?.name ?? null,
            //     first_login_date : regData?.first_login_date ?? null,
            //   }
            // }
            // delete modifiedData.dealer_id_hash;
            // console.log("modifiedData-----------------",modifiedData);
            // secureStorage.setItem('loginUserInfo', modifiedData);
            // localStorage.setItem('dealer_hash_id', regData?.dealer_id_hash);
            // localStorage.setItem("loginUserInfo", JSON.stringify(modifiedData));
            // await fetchUserData();
        } else {
            toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
        }
    })
    .catch(error => console.log(error))
  }


  const fetchUserData = async () => {
    try {
      const userInfo = getUserInfo();
      const { user_id, dealer_id } = userInfo;
      const params = {
        user_id: user_id,
        dealer_id: dealer_id,
      }
      if(user_id) {
        const response = await postApiUtility('/dealer/user-manager/user_detail', params);
        if (response?.data?.status === 200) {
          const userData = response?.data.data;
          await getUserAccess(userData);
          localStorage.setItem('dealer_hash_id', userData?.dealer_id_hash);
        //  console.log (">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>" , userData.name)
          if(userData?.is_pan_verified === 1 || userData?.is_pan_skipped === '1' ) {
            if (userData?.name === null) {
              setKycForm();
              return;
            }
            else {
            toast.success('Login Success');
            setTimeout(() => {
              history.push('/saathi-leads');
            }, 1000);
          }
            // setKycForm();
          } else {
            setKycForm();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const ShowOtpFields = () => {
    setShowOtp(true);
  };

  const handleOnEdit = () => {
    setShowOtp(false);
    setOtp('');
    setOtpTimer(null);
    setIsOtpSend(false);
    setIsDisabled(false);
    setError(prevState => ({
     ...prevState,
      mobileError: false,
      otpError: false,
    }));
  };

  return (
    <>
      <div>
        <div>
          <div col-md-12>
            <InputField
              type="tel"
              name="mobile"
              id="mobile"
              label="Mobile"
              placeholder="Enter Mobile Number"
              maxLength="10"
              value={mobile}
              onChange={handleOnChange}
              required={true}
              error={error?.mobileError}
              disabled={isDisabled}
              autoFocus={true}
              // autoFocus={!isOtpSend ? true : false}
            >
             
            </InputField>
            {/* {error.mobileError && <p style={{color:'red'}}>Please enter valid mobile number</p>} */}
          </div>
          {showOtp && (
            <div col-md-12>
              <InputField
                type="tel"
                name="otp"
                id="otp"
                label="OTP"
                placeholder="Enter OTP"
                maxLength="6"
                value={otp}
                onChange={handleOnChange}
                error={error?.otpError}
                autoFocus={isOtpSend}
                required={true}
              />
            {/* {error.otpError && <p style={{color:'red'}}>Please enter valid otp</p>} */}
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isOtpSend ? (
              otpTimer > 0 ? (
                <span style={{ marginBottom: 10}}>
                  {"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}
                </span>
              ) : (
                <span 
                  style={{ cursor: 'pointer', marginBottom: 10 }} 
                  onClick={sendOTP}>Resend</span>
              )
            ) : null}
          </div>
          
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            id="send-otp"
            onClick={submitOtpForm}
          >
            {isOtpSend ? 'Verify' : 'Submit'}
          </button>
        </div>
      </div>
    </>
  );
};

export default NewLogin;