import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import { executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import { EMI_CALCULATOR, EMI_FORM_FIELD } from "../../../queries/sangam/leads.gql";
import { parse } from "graphql";
import { useHistory } from "react-router";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Pie } from "react-chartjs-2";

// ChartJS.register(ArcElement, Tooltip, Legend);

const EmiCalculator = (props) => {

  const history = useHistory();

  const { updateStateIntoRedux } = props;
  const [calculatedEmi, setCalculatedEmi] = useState({
    loan_amount: 10338053,
    roi: 5338053,
    loan_tenure: 43075,
  })
  const [activeTab, setActiveTab] = useState("yearly");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [formData, setFormData] = useState({
    loan_amount: { default: 0 },
    interest_rate: { default: 0 },
    loan_tenure: { default: 0 },
  });

  const handleTenureSlider = (e) => {
    const val = e.target.value
    setTenureSlider(parseFloat(val) || 0)
  }

  const handleInteresteSlider = (e) => {
    const val = e.target.value;
    setInterestValue(parseFloat(val) || 0)
  }

  const handleAmounteSlider = (e) => {
    setAmountValue(e.target.value)
  }

  const handleInput = (e) => {
    const name = e.target.name;
    let val = e.target.value;
    let numericValue = 0
    if (name === "loan_amount") {
      numericValue = parseInt(val.replace(/\D/g, ''), 10) || 0;
    }
    let data = {}
    setFormData((prevData) => ({ ...prevData, [name]: { ...prevData[name], default: name === "loan_amount" ? numericValue : val } }));
    if (name === "loan_amount") {
      data = {
        "loan_amount": numericValue,
        "roi": formData?.interest_rate?.default,
        "loan_tenure": formData?.loan_tenure?.default
      }
      setAmountValue(numericValue)
    }
    if (name === "loan_tenure") {
      data = {
        "loan_amount": formData?.loan_amount?.default,
        "roi": formData?.interest_rate?.default,
        "loan_tenure": val
      }
      setTenureSlider(val)
    }
    if (name === "interest_rate") {
      data = {
        "loan_amount": formData?.loan_amount?.default,
        "roi": val,
        "loan_tenure": formData?.loan_tenure?.default
      }
      setInterestValue(val)
    }
    handleSubmit(data)

  };

  const handleSubmit = async (data) => {
    executeGraphQLQueryFinex(EMI_CALCULATOR(data)).then((response) => {
      if (response?.data?.emi_calcultor) {
        let result = response.data.emi_calcultor
        if (result) {
          setCalculatedEmi((prevData) => ({
            ...prevData,
            loan_amount: result?.total_pay,
            roi: result?.interest_amount,
            loan_tenure: result?.emi
          }));
        }
      }
    })
      .catch((error) => {
        console.log(error, "error in getting the emi calculation")
      });
  }

  useEffect(() => {
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();
    fetchData();
  }, [updateStateIntoRedux]);

  const data = {
    labels: ['Principal Amount', 'Interest Amount'],
    datasets: [
      {
        data: [56, 44],
        backgroundColor: ['#6A1B9A', '#26A69A'],
        hoverBackgroundColor: ['#7B1FA2', '#2BBBAD'],
        borderWidth: 0,
      },
    ],
  };

  const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
    return formattedAmount.replace(/\.00$/, '');
  };

  const fetchData = async () => {
    try {
      const response = await executeGraphQLQueryFinex(EMI_FORM_FIELD);
      if (response?.data?.emi_cal_form) {
        let result = response.data.emi_cal_form?.emi_calulator;
        if (result) {
          setFormData((prevData) => ({
            ...prevData,
            loan_amount: result?.loan_amount,
            interest_rate: result?.interest_rate,
            loan_tenure: result?.loan_tenure
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };


  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  const [sliderTenure, setTenureSlider] = useState('20');
  const [sliderInterest, setInterestValue] = useState('8.4');
  const [sliderAmount, setAmountValue] = useState('5000000')
  const principal = calculatedEmi?.loan_amount || 0;
  const interest = calculatedEmi?.roi || 0;
  const principalAmount = ((formData?.loan_amount?.default) / principal) * 100
  const interestAmount = (principal - (formData?.loan_amount?.default || 0)) / principal * 100

  function updatePieChart(value2, value1) {
    let pie = document.getElementById('pie');
    let value1Text = document.getElementById('value1');
    let value2Text = document.getElementById('value2');

    if (!pie || !value1Text || !value2Text) {
      console.error("Pie chart element or percentage text elements are missing.");
      return;
    }

    let total = value1 + value2;
    if (total !== 100) {
      console.error("The sum of the values must equal 100.");
      return;
    }

    pie.style.background = `conic-gradient(#1FC593 0% ${value1}%, #66499D ${value1}% 0%)`;

    value1Text.textContent = `${value1}%`;
    value2Text.textContent = `${value2}%`;
  }

  useEffect(() => {
    const rangeInputs = document.querySelectorAll('input[type="range"].slider-progress');
    rangeInputs.forEach((e) => {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min === '' ? '0' : e.min);
        e.style.setProperty('--max', e.max === '' ? '100' : e.max);
        e.addEventListener('input', (event) => {
            e.style.setProperty('--value', event.target.value);
        });
    });
    return () => {
        rangeInputs.forEach((e) => {
        });
    };
}, [sliderAmount,sliderInterest,sliderTenure]);

  

  useEffect(() => {
    if (principal > 0 && interest > 0) {
      updatePieChart(
        Math.round(principalAmount),
        Math.round(interestAmount)
      );
    }
  }, [Math.round(principalAmount)
    , Math.round(interestAmount)]);

    const Emisearch = () =>{
      history.push("/saathi-tools");
    }


  return (
    <div className="mytoolscard">
      <div className="backoffer" onClick={Emisearch} style={{marginBottom:20}}>
          <i className="ic-arrow_back" style={{ fontSize: 20, cursor: "pointer" }}></i>
      <div className="commonheading">Emi Calculator</div>
      </div>
      <div className="payoutcalculator">
        <div className="payoutcard">
          <div className="flotleft">
            <div className="emibox">
              <div className="mainamount">
                <div>Loan Amount</div>
                <input
                  type="text"
                  id="loan_amount"
                  name="loan_amount"
                  min="0"
                  max="100000000"
                  value={formatCurrency(sliderAmount)}
                  onChange={handleInput}
                  class="input-field"
                  maxLength={13}
                />
              </div>
            </div>
            <div className="slidernew-container">
              <input
                type="range"
                id="loan_amount"
                name="loan_amount"
                min="500000"
                max="100000000"
                value={sliderAmount}
                className="styled-slider slider-progress"
                onChange={handleAmounteSlider}
                onMouseUp={handleInput}
              />
            </div>
            <div className="slidernew-container">
              <div className="slidernew-labels">
                <span style={{ fontWeight: 50 }}>5L </span>
                <span style={{ fontWeight: 50, marginLeft: 10 }}>1Cr</span>
                <span style={{ fontWeight: 50, marginLeft: 1 }}>2Cr</span>
                <span style={{ fontWeight: 50 }}>3Cr</span>
                <span style={{ fontWeight: 50 }}>4Cr</span>
                <span style={{ fontWeight: 50 }}>5Cr</span>
                <span style={{ fontWeight: 50 }}>6Cr</span>
                <span style={{ fontWeight: 50 }}>7Cr</span>
                <span style={{ fontWeight: 50 }}>8Cr</span>
                <span style={{ fontWeight: 50 }}>9Cr</span>
                <span style={{ fontWeight: 50 }}>10Cr</span>
              </div>
            </div>

            <div className="emibox">
              <div className="mainamount">
                <div>Interest Rate</div>
                <div class="input-wrapper">
                  <input
                    type="number"
                    id="interest_rate"
                    name="interest_rate"
                    value={sliderInterest}
                   disabled
                    class="input-field"
                  />
                  <span class="percentage-symbol">%</span>
                </div>
              </div>
            </div>
            <div className="slidernew-container">
              <input
                type="range"
                id="interest_rate"
                name="interest_rate"
                min="7"
                max="25"
                step="0.1"
                value={sliderInterest}
                className="styled-slider slider-progress"
                onChange={handleInteresteSlider}
                onMouseUp={handleInput}
              />
            </div>
            <div className="slidernew-container">
              <div className="slidernew-labels">
                <span style={{ fontWeight: 100, marginLeft: 10 }}>7</span>
                <span style={{ fontWeight: 100, marginLeft: 6 }}>9</span>
                <span style={{ fontWeight: 100 }}>11</span>
                <span style={{ fontWeight: 100 }}> 13</span>
                <span style={{ fontWeight: 100 }}>15</span>
                <span style={{ fontWeight: 100 }}>17</span>
                <span style={{ fontWeight: 100 }}>19</span>
                <span style={{ fontWeight: 100 }}>21</span>
                <span style={{ fontWeight: 100 }}>23</span>
                <span style={{ fontWeight: 100, marginRight: 5 }}>25</span>
              </div>
            </div>

            <div className="emibox">
              <div className="mainamount">
                <div className="tenureflex">
                  <div>Tenure</div>
                  {/* <div className="tenuretabs"> */}
                  {/* <div
                      className={`tenureyearly ${activeTab === "yearly" ? "active" : ""}`}
                      // onClick={() => handleTabClick("yearly")}
                    >
                      Yearly
                    </div>
                    <div
                      className={`tenuremonthly ${activeTab === "monthly" ? "active" : ""}`}
                      // onClick={() => handleTabClick("monthly")}
                    >
                      Monthly
                    </div> */}
                  {/* </div> */}
                </div>
                <div class="input-wrapper">
                  <input
                    type="number"
                    id="loan_tenure"
                    name="loan_tenure"
                    value={sliderTenure}
                    step="0.1"
                    disabled
                    class="input-field"
                  />
                  <span class="Year-symbol">Yr</span>
                </div>

              </div>
            </div>
            <div className="slidernew-container">
              <input
                type="range"
                id="loan_tenure"
                name="loan_tenure"
                step="0.1"
                min='5'
                max='40'
                maxLength={3}
                value={sliderTenure}
                onChange={handleTenureSlider}
                onMouseUp={handleInput}
                className="styled-slider slider-progress"
              />
            </div>
            <div className="slidernew-container">
              <div className="slidernew-labels">
                <span style={{ fontWeight: 100 }}>5</span>
                <span style={{ fontWeight: 100 }}>10</span>
                <span style={{ fontWeight: 100 }}>16</span>
                <span style={{ fontWeight: 100 }}> 22</span>
                <span style={{ fontWeight: 100 }}>28</span>
                <span style={{ fontWeight: 100 }}>34</span>
                <span style={{ fontWeight: 100, marginRight: 5 }}> 40</span>
              </div>
            </div>
          </div>

          <div className="flotright">
            <div className="emirightcard">
              <div className="loanflex">
                <div className="emichartcard">
                  <div className="principalamount">
                    <span></span>
                    Principal Amount
                  </div>
                  <div className="interestamount">
                    <span></span>
                    Interest Amount
                  </div>
                  <div className="pie-chart" style={{ marginTop: "50px" }}>
                    <div className="pie" id="pie"></div>
                    <div className="value-green" id="value1"></div>
                    <div className="value-purple" id="value2"></div>
                  </div>
                  {/* <Pie data={data} options={options} /> */}
                </div>
                <div className="emicommon">
                  <div className="loanemiamount">
                    <div className="loanemifont">Monthly Loan EMI</div>
                    <div className="loanpricefont">{formatCurrency(calculatedEmi?.loan_tenure)}</div>
                  </div>
                  <div className="loanemiamount">
                    <div className="loanemifont">Total Interest Payable</div>
                    <div className="loanpricefont">
                      {formatCurrency((calculatedEmi?.loan_amount || 0) - (formData?.loan_amount?.default || 0))}
                    </div>
                  </div>
                  <div className="loanemiamount">
                    <div className="loanemifont">Total Payable Amount Principle + Interest</div>
                    <div className="loanpricefont">{formatCurrency(calculatedEmi?.loan_amount)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(EmiCalculator)
);
