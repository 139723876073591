import React, { useEffect, useState, useMemo } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/coApplicantDetailsFormFields.json";
import { SAVE_CUST_DETAILS } from "../../queries/sangam/customer.gql";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import Formsy from "formsy-react";
import FormsyInputField from "../../view/elements/FormsyInputField";
import FormsySelect from "../../view/elements/FormsySelect";
import AdditionalIncome from "../../view/elements/AdditionalIncome";
import {
  AMOUNT_TYPE_OPTIONS,
  DISBURSE_REJECTION_ID,
} from "../../config/constant";
import { useHistory } from "react-router-dom";
import crypto from "../../config/crypto";
import Obligations from "./Obligations";

const FORM_FIELDS = FormFields["LOAN_DETAILS"];

const CoApplicantIncomes = ({ leadDetail, masterdata }) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [loanDetails, setLoanDetails] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState("coApplicant_0");
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState({});
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    {}
  );
  let history = useHistory();

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    options["company_type"] = options["company_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    options["ca_amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["additional_income_type"] = options["additional_income_type"]?.map(
      (ob) => ({
        ...ob,
        value: +ob.id,
      })
    );
    options["ca_filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));

    return options;
  }, [masterdata]);

  useEffect(() => {
    if (leadDetail) {
      let co_applicatndata = leadDetail?.co_applicant || [];
      const activeIndex = activeTab.split("_")[1];
      const newCoApplicants = co_applicatndata
        .filter((ob) => ob.ca_type == "financing")
        .map((data, index) => {
          let newAppObject = {};
          newAppObject.id = data.id || 0;
          newAppObject.ca_first_name = data.ca_first_name || "";
          newAppObject.ca_last_name = data.ca_last_name || "";
          newAppObject.ca_profession = data.ca_profession || 0;
          newAppObject.ca_salary_credit_mode = data.ca_salary_credit_mode || 0;
          newAppObject.ca_company_name = data.ca_company_name || "";
          newAppObject.ca_company_type = data.ca_company_type || "";
          newAppObject.ca_monthly_salary = data.ca_monthly_salary || "";
          newAppObject.ca_annual_income = data.ca_annual_income || "";
          newAppObject.ca_business_proof = data.ca_business_proof;
          newAppObject.ca_gross_monthly_salary =
            data.ca_gross_monthly_salary || "";
          newAppObject.ca_is_form_16_filled = data.ca_is_form_16_filled || "0";
          newAppObject.ca_is_itr_filled = data.ca_is_itr_filled || "0";
          newAppObject.ca_existing_emi_amount = data.ca_existing_emi_amount;
          newAppObject.ca_emi_ending_six_month = data.ca_emi_ending_six_month;
          newAppObject.ca_is_obligation = data.ca_is_obligation;
          newAppObject.ca_is_additional_income =
            data.ca_is_additional_income || "no";
          let ca_additionalIncome = [];
          if (data.ca_is_additional_income === "yes") {
            ca_additionalIncome = data.ca_additional_income?.map((obj) => {
              return {
                id: obj.id,
                lead_id: obj.lead_id,
                customer_id: obj.customer_id,
                customer_type: obj.customer_type,
                ca_income_type_id: obj.income_type_id,
                ca_amount_type: obj.amount_type,
                ca_amount: obj.amount,
                status: obj.status,
              };
            });
          }
          newAppObject.ca_additional_income = ca_additionalIncome;

          setAdditionalIncomeCount((current) => ({
            ...current,
            [`coApplicant_${index}`]: ca_additionalIncome.length,
          }));

          return newAppObject;
        });

      if (newCoApplicants[activeIndex]?.["ca_is_additional_income"] === "yes") {
        const co_app = newCoApplicants[activeIndex].ca_additional_income;
        for (let i = 0; i < co_app.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`ca_filtered_additional_income_type_${i + 1}`] =
            ALL_OPTIONS[`ca_filtered_additional_income_type_${i}`]?.filter(
              (obj) => obj.value !== co_app[i].ca_income_type_id
            );
        }
      }
      setLoanDetails(newCoApplicants);
      if (co_applicatndata.length === 0) newCoapplicant();
    }
  }, [leadDetail]);

  const newCoapplicant = () => {
    const newCoApplicants = [
      {
        ca_existing_emi_amount: "",
        ca_annual_income: "",
        ca_profession: "",
        ca_salary_credit_mode: "",
        ca_company_name: "",
        ca_company_type: "",
        ca_loan_amount: "",
        ca_tenure: "",
      },
    ];
    setLoanDetails(newCoApplicants);
  };

  const handleInputChange = (event) => {
    if (event.target.id) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedLoanDetails = [...loanDetails];
      updatedLoanDetails[activetabIndex][event.target.id] = event.target.value;
      setLoanDetails(updatedLoanDetails);
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedLoanDetails = [...loanDetails];
      updatedLoanDetails[activetabIndex][field_name] = data.value;
      setLoanDetails(updatedLoanDetails);
    }
  };

  const handleSubmit = () => {
    const tabIndex = activeTab.split("_")[1];
    let lead_id = leadDetail?.id || "";
    let newRecord = loanDetails.map((ob) => {
      let additionalIncome = [];
      if (ob.ca_additional_income?.length > 0) {
        additionalIncome = ob.ca_additional_income?.map((income) => {
          return {
            ...(income.id ? { id: income?.id } : {}),
            lead_id,
            customer_id: ob.id,
            customer_type: "co_applicant",
            income_type_id: income.ca_income_type_id || null,
            amount_type: income.ca_amount_type,
            amount: income.ca_amount,
            status: income.status,
          };
        });
      }

      return {
        id: ob.id,
        ca_profession: +ob.ca_profession,
        ca_salary_credit_mode: +ob.ca_salary_credit_mode,
        ca_company_name: ob.ca_company_name,
        ca_company_type: +ob.ca_company_type,
        ca_is_itr_filled: ob.ca_is_itr_filled,
        ca_is_form_16_filled: ob.ca_is_form_16_filled,
        ca_gross_monthly_salary: ob.ca_gross_monthly_salary,
        ca_monthly_salary: ob.ca_monthly_salary,
        ca_annual_income: ob.ca_annual_income,
        ca_business_proof: ob.ca_business_proof,
        ca_is_additional_income: additionalIncome.length > 0 ? "yes" : "no",
        ca_additional_income: additionalIncome,
        ca_existing_emi_amount: ob?.ca_existing_emi_amount || null,
        ca_emi_ending_six_month: ob?.ca_emi_ending_six_month || null,
        ca_is_obligation: ob?.ca_is_obligation || "",
      };
    });

    newRecord = newRecord[tabIndex] ? [newRecord[tabIndex]] : newRecord;
    let mutation = SAVE_CUST_DETAILS,
      variables = {
        api_called_by: 'saathi_web',
        partner_id: loginUserInfo?.dealer_id,
        LeadAdditionalInput: {
          lead_id: lead_id,
          co_applicant: newRecord,
        },
      };

    executeGraphQLMutationFinex(mutation, variables)
      .then((resp) => {
        let response = resp?.data?.LeadAdditionalInput || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let LoanDetailsForm = useMemo(() => {
    if (loanDetails) {
      let index = activeTab.split("_");
      index = index[1];
      if (
        !loanDetails[index]?.ca_existing_emi ||
        !loanDetails[index]?.ca_existing_emi ||
        loanDetails[index].ca_existing_emi == "yes"
      ) {
        return FORM_FIELDS;
      } else {
        return FORM_FIELDS.filter(
          (v) => !["ca_no_of_emi", "ca_existing_emi_amount"].includes(v.id)
        );
      }
    }
    return FORM_FIELDS;
  }, [loanDetails]);

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedLoanDetails = [...loanDetails];
      updatedLoanDetails[activetabIndex][field_name] =
        field_name === "ca_tenure" ? +data.value : data.value;
      if (field_name === "ca_profession") {
        let filteredAddInc = [];
        if (data.value === 1) {
          filteredAddInc = ALL_OPTIONS["additional_income_type"];
        } else {
          filteredAddInc = ALL_OPTIONS["additional_income_type"].filter(
            (obj) => obj.value === 1 || obj.value === 5
          );
        }
        ALL_OPTIONS["filtered_additional_income_type"] = filteredAddInc;
      }
      setLoanDetails(updatedLoanDetails);
    }
  };

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting =
        additionalIncomeFormFields[activeTab]?.filter(
          (obj) => obj.id === `ca_additional_income_type_${index}`
        ) || [];
      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[activeTab][
          additionalIncomeFormFields[activeTab].length - 1
        ]?.canAddMore
      ) {
        const dropDownObj = {
          id: `ca_additional_income_type_${index}`,
          name: `ca_additional_income_type_${index}`,
          readOnly: false,
          type: `dropdown`,
          label: `Additional income type`,
          required: false,
          validations: null,
          validationError: "",
          ApplyNumberformat: false,
          optionsKey: `ca_filtered_additional_income_type_${index}`,
        };
        setAdditionalIncomeCount((current) => ({
          ...current,
          [activeTab]: index + 1,
        }));
        setAdditionalIncomeFormFields((current) => ({
          ...current,
          [activeTab]: [
            ...(additionalIncomeFormFields[activeTab]
              ? additionalIncomeFormFields[activeTab]
              : []),
            dropDownObj,
          ],
        }));
      }
    }
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    const loanDetailClone = [...loanDetails];
    const activeIndex = activeTab.split("_")[1];
    loanDetailClone[activeIndex][name] = checked ? "1" : "0";
    setLoanDetails(loanDetailClone);
  };

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`ca_filtered_additional_income_type_${index + 1}`] =
        ALL_OPTIONS[`ca_filtered_additional_income_type_${index}`].filter(
          (obj) => obj.value !== data.value
        );
    }
  };

  const handleRemoveAdditionalIncome = (index) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...loanDetails];
    const existingAddIncomeField = [...additionalIncomeFormFields[activeTab]];
    const existingAddIncome = [
      ...loanDetails[activeTab.split("_")[1]]["ca_additional_income"],
    ];
    const removedEle = {
      ...existingAddIncome[index],
      status: "0",
    };
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[
        existingAddIncomeField.length - 1
      ].canAddMore = true;
    }
    existingAddIncome.splice(index, 1);
    existingAddIncome.push(removedEle);
    existingCo[activeIndex]["ca_additional_income"] = existingAddIncome;
    setAdditionalIncomeCount((current) => ({
      ...current,
      [activeTab]: additionalIncomeCount[activeTab] - 1,
    }));
    setAdditionalIncomeFormFields((current) => ({
      ...current,
      [activeTab]: existingAddIncomeField,
    }));
    setLoanDetails(existingCo);
  };

  const handleSetAdditionalIncomeData = (data) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...loanDetails];
    existingCo[activeIndex]["ca_additional_income"] = data;
    setLoanDetails(existingCo);
  };

  const rejectDisable =
    leadDetail?.reject_reason_id > 0 &&
    !DISBURSE_REJECTION_ID.includes(leadDetail.reject_reason_id) &&
    [2, 3].includes(leadDetail.status_id)
      ? true
      : false;
  const IS_LOST = leadDetail?.status_id == 5 ? true : false;

  const handleButtonClick = (data) => {
    let url = `/income-detail/${crypto.encode(leadDetail?.id)}`;
    history.push(url);
  };

  const handleRadioChange = (event) => {
    if (event.target) {
      const { id, name, checked } = event.target;
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...loanDetails];
      existingCo[activeIndex][name] = checked ? "yes" : "no";

      // add slight delay to state updates and component re-renders
      setTimeout(() => {
        setLoanDetails(existingCo);
      }, 0);
    }
  };

  const handleSetObligationData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...loanDetails];
      if (obj) {
        existingCo[activeIndex] = { ...existingCo[activeIndex], ...obj };
      } else {
        existingCo[activeIndex][key] = value;
      }
      setLoanDetails(existingCo);
    }
  };

  return (
    <>
      <div>
        <div className="customerflex">
          <h4 className="customerheading">Income Details</h4>
          <br></br>
        </div>
        <div className="tab-list">
          <div className="commontabs">
            <div>
              <div className="partnername">Primary</div>
              <div
                className={
                  activeTab === "partner_name"
                    ? "commontabsItem active"
                    : "commontabsItem"
                }
                onClick={() => handleButtonClick("Primary")}
              >
                <div
                  className="newtabContent"
                >
                  {leadDetail?.customer?.first_name}
                </div>
              </div>
            </div>

            {loanDetails.length ? (
              loanDetails.map((coApplicant, index) =>
                loanDetails[index].ca_first_name ? (
                  <div>
                    <div className="coapplicantname">{`coApplicant_${
                      index + 1
                    }`}</div>
                    <div
                      className={
                        activeTab === `coApplicant_${index}`
                          ? "commontabsItem active"
                          : "commontabsItem"
                      }
                      onClick={() => setActiveTab(`coApplicant_${index}`)}
                      eventkey={`coApplicant_${index}`}
                      active={activeTab === `coApplicant_${index}`}
                    >
                      <div className="newtabContent">
                        {" "}
                        {loanDetails[index].ca_first_name}{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <span>{`coApplicant_${index}`}</span>
                    <div
                      onClick={() => setActiveTab(`coApplicant_${index}`)}
                      eventkey={`coApplicant_${index}`}
                      active={activeTab === `coApplicant_${index}`}
                    >
                      Add New
                    </div>
                  </div>
                )
              )
            ) : (
              <div>No Co-applicant found </div>
            )}
          </div>
        </div>
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => setAllowSubmit(true)}
          onInvalid={() => setAllowSubmit(false)}
          onValidSubmit={handleSubmit}
        >
          <div className="row">
            {LoanDetailsForm.map((field) =>
              loanDetails.map(
                (loanDetailData, index) =>
                  `coApplicant_${index}` === activeTab &&
                  ((loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_annual_income") ||
                  (loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_company_type") ||
                  (loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_company_name") ||
                  (loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_salary_credit_mode") ||
                  (loanDetailData?.["ca_profession"] != 1 &&
                    field.name === "ca_monthly_salary") ||
                  (loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_annual_income") ||
                  (loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_company_type") ||
                  (loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_company_name") ||
                  (loanDetailData?.["ca_profession"] == 0 &&
                    field.name === "ca_salary_credit_mode") ||
                  (loanDetailData?.["ca_profession"] != 1 &&
                    field.name === "ca_monthly_salary") ||
                  (loanDetailData?.["ca_profession"] != 1 &&
                    field.name === "ca_gross_monthly_salary") ||
                  (loanDetailData?.["ca_profession"] == 1 &&
                    field.name === "ca_annual_income") ||
                  (loanDetailData?.["ca_profession"] == 2 &&
                    field.name === "ca_salary_credit_mode") ||
                  (loanDetailData?.["ca_profession"] == 3 &&
                    field.name === "ca_salary_credit_mode") ||
                  (loanDetailData?.["ca_profession"] == 3 &&
                    field.name === "ca_company_type") ||
                  (loanDetailData?.["ca_profession"] == 3 &&
                    field.name === "ca_company_name") ? null : [
                      "text",
                      "password",
                      "pattern-format",
                      "number-format",
                    ].includes(field.type) ? (
                    <fieldset className="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={loanDetailData && loanDetailData[field.name]}
                        placeholder=" "
                        thousandsGroupStyle="lakh"
                        thousandSeparator={","}
                        decimalSeparator={"."}
                        label={field.label}
                        onChange={
                          ["pattern-format", "number-format"].includes(
                            field.type
                          )
                            ? (data) =>
                                handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          loanDetailData[field.name]
                            ? field.validationError
                            : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) : field.type === "dropdown" ? (
                    <fieldset className="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder: field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value:
                            ALL_OPTIONS &&
                            ALL_OPTIONS[field.optionsKey] &&
                            ALL_OPTIONS[field.optionsKey].filter(
                              ({ value }) => value == loanDetailData[field.name]
                            )[0],
                          isDisabled: field.readOnly,
                        }}
                        required={field.required}
                        value={loanDetailData && loanDetailData[field.name]}
                        onChange={(data) =>
                          handleSelectChange(data, field.name)
                        }
                      />
                    </fieldset>
                  ) : null)
              )
            )}
          </div>

          {/* ADDITIONAL INCOME */}
          {loanDetails[activeTab.split("_")[1]]?.ca_profession != 0 && (
            <>
              {additionalIncomeFormFields[activeTab]?.map((data, index) => (
                <>
                  <div className="additionalincome" key={index}>
                    Additional Income - {index + 1}
                  </div>
                  <AdditionalIncome
                    index={index}
                    ALL_OPTIONS={ALL_OPTIONS}
                    setAdditionalIncomeType={handleAdditionalIncomeType}
                    removeAdditionalIncome={handleRemoveAdditionalIncome}
                    setAdditionalIncomeData={handleSetAdditionalIncomeData}
                    additionalIncomeData={
                      loanDetails[activeTab.split("_")[1]]?.[
                        "ca_additional_income"
                      ]
                    }
                    key={index}
                    isCoApp={true}
                  />
                </>
              ))}

              {additionalIncomeCount[activeTab] < 5 && (
                <div
                  className="coapplicantadd"
                  onClick={() =>
                    handleAddAdditionalIncome(additionalIncomeCount[activeTab])
                  }
                >
                  <span>+</span>
                  <div>Additional Income</div>
                </div>
              )}
            </>
          )}

          {loanDetails[activeTab.split("_")[1]]?.ca_profession != 0 && (
            <hr></hr>
          )}

          {/* OBLIGATION */}
          {loanDetails[activeTab.split("_")[1]]?.ca_profession != 0 && (
            <>
              <div className="is-obligation">
                <label>
                  {loanDetails[activeTab.split("_")[1]]?.ca_profession === 1
                    ? "Does the co-applicant have any EMI based obligations"
                    : "Does the co-applicant have any monthly obligations"}
                </label>
                <label className="toggle-label">No</label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="ca_is_obligation"
                    id="ca_is_obligation"
                    value="yes"
                    checked={
                      loanDetails[activeTab.split("_")[1]]?.[
                        "ca_is_obligation"
                      ] === "yes"
                        ? true
                        : false
                    }
                    onChange={handleRadioChange}
                  />
                  <span className="slider"></span>
                </label>
                <label className="toggle-label">Yes</label>
              </div>

              {loanDetails[activeTab.split("_")[1]]?.["ca_is_obligation"] ===
                "yes" && (
                <div style={{ marginBottom: "15px" }}>
                  <Obligations
                    setObligationDetails={handleSetObligationData}
                    customerType={"co_applicant"}
                    activeCoApplicant={loanDetails[activeTab.split("_")[1]]}
                    leadDetail={leadDetail}
                  />
                </div>
              )}
            </>
          )}

          {loanDetails[activeTab.split("_")[1]]?.ca_profession == 1 ? (
            <>
              <div className="property-identified">
                <label>Have you filled Form 16B ?</label>
                <label className="toggle-label">No</label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="ca_is_form_16_filled"
                    id="ca_is_form_16_filled"
                    value="Yes"
                    checked={
                      loanDetails[activeTab.split("_")[1]]
                        ?.ca_is_form_16_filled === "1"
                        ? true
                        : false
                    }
                    onChange={handleCheckBoxChange}
                  />
                  <span className="slider"></span>
                </label>
                <label className="toggle-label">Yes</label>
              </div>
            </>
          ) : loanDetails[activeTab.split("_")[1]]?.ca_profession == 2 ||
            loanDetails[activeTab.split("_")[1]]?.ca_profession == 3 ? (
            <>
              <div className="property-identified">
                <label>Have you filled ITR ?</label>
                <label className="toggle-label">No</label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="ca_is_itr_filled"
                    id="ca_is_itr_filled"
                    value="Yes"
                    checked={
                      loanDetails[activeTab.split("_")[1]].ca_is_itr_filled ===
                      "1"
                        ? true
                        : false
                    }
                    onChange={handleCheckBoxChange}
                  />
                  <span className="slider"></span>
                </label>
                <label className="toggle-label">Yes</label>
              </div>
            </>
          ) : null}

          {loanDetails[activeTab.split("_")[1]]?.ca_profession == 3 ? (
            <>
              <div className="property-identified">
                <label>Do you have business proof ?</label>
                <label className="toggle-label">No</label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="ca_business_proof"
                    id="ca_business_proof"
                    value="1"
                    checked={
                      loanDetails[activeTab.split("_")[1]]?.[
                        "ca_business_proof"
                      ] == "1"
                        ? true
                        : false
                    }
                    onChange={handleCheckBoxChange}
                  />
                  <span className="slider"></span>
                </label>
                <label className="toggle-label">Yes</label>
              </div>
            </>
          ) : null}

          {loanDetails.length ? (
            <div className="btn-save-remarks">
              <button
                type="submit"
                className="editnextbtn"
                disabled={rejectDisable || IS_LOST}
              >
                Save
              </button>
            </div>
          ) : null}
        </Formsy>
      </div>
    </>
  );
};
//return true;
export default CoApplicantIncomes;
