import React, { useState } from "react";
import { TicketStatusColorMap, TicketStatusMap } from "../../config/constant";

const HelpSupportTable = ({tickets,setCurrentChat,conversations}) => {  


  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString); 
    return date.toLocaleDateString('en-US', options);
  }

  function handleTicketClick(ticket){
    setCurrentChat([ticket])
    localStorage.setItem(`count${ticket.id}`, conversations[ticket.id]?.length.toString());

  }
 

  return (
    <>
      <div className="table-container">
        <table>
          <thead style={{ position: "sticky", top: "0", zIndex: 10 }}>
            <tr>
              <th>Ticket ID</th>
              <th>Lead ID</th>
              <th>Title</th>
              <th>Last Updated</th>
              <th style={{width:"15%"}}>Status</th>
            </tr>
          </thead>
          <tbody>
            <>

            {
              // tickets?.map((ticket)=> <><tr onClick={()=>handleTicketClick(ticket)}>
                              tickets?.map((ticket)=> <><tr >

              <td>
              <div className="flexdate">
                  <span><img src={require(`../../../src/saathi-web/images/no-ticket.svg`)} width={16} height={12} /></span>
                  #{ticket.id}
                  </div>
                 
              </td>
              <td>
              <div className="flexdate">
                 
                  {ticket.custom_fields.cf_lead_id || "-"}
                  </div>
                 
              </td>
              <td>{ticket.custom_fields.cf_type_of_issue}</td>
              <td>
                  <div className="flexdate">
                  <span><img src={require(`../../../src/saathi-web/images/dateicon.svg`)} width={14} height={14} /></span>
                  {formatDate(ticket.updated_at)}
                  </div>
              </td>
              <td>
                  <div className="flexdate">
                  <span style={{position:"relative",visibility:+localStorage.getItem(`unread${ticket.id}`) > 0 ?"visible":"hidden"}}><img src={require(`../../../src/saathi-web/images/helpmessage.svg`)} width={30} height={30} />{+localStorage.getItem(`unread${ticket.id}`) > 0 ? <div className="unReadMessage">{localStorage.getItem(`unread${ticket.id}`)}</div> :""}</span>
                 <div className={`opensupportstatus`} style={{backgroundColor:TicketStatusColorMap[ticket.status]}}>{TicketStatusMap[ticket.status]}</div>
                
                 </div>
              </td>
            </tr>
            
            </>
          )
            }
             
            </>
          </tbody>
        </table>
        {!tickets?.length && <div style={{marginLeft:"45%",marginTop:"20%"}}>No Data Found</div>}
      </div>
     
    </>
  );
};

export default HelpSupportTable;
