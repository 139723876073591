import React, { useEffect, useState } from "react";
import { useCleverTap } from "../CleverTapContext";
import NewInputField from "../../view/elements/NewInputField";
import { toast } from "react-toastify";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { GET_DETAILS_BY_PANCARD_UPDATE } from "../../queries/sangam/cibil.gql";

const VerificationCibil = (props) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [formData, setFormData] = useState({
    loan_type: "",
    loan_sub_type: [],
    required_loan: "",
    employment_type: null,
    monthly_salary: "",
    pan_number: props.panNumber || "",
  });
  const [panDetails, setPanDetails] = useState({});
  const [isFetched, setIsFetched] = useState(false);

  const { panNumber, leadData, handleDataFromVerificationCibil, mobile,lead_id } = props;
  useEffect(() => {
    if (panNumber) {
      setFormData((prevData) => ({
        ...prevData,
        pan_number: panNumber,
      }));
    }
  }, [panNumber]);
  useEffect(() => {
    if (leadData || panDetails) {
      setFormData((prevData) => ({
        ...prevData,
        lead_id: leadData?.id || prevData.lead_id,
        pan_number: panNumber || leadData?.customer?.pancard_no,
        mobile_number: mobile || leadData?.customer?.mobile_number,
        first_name: panDetails?.firstName || prevData.first_name || "",
        last_name: panDetails?.lastName || prevData.last_name || "",
        dob: panDetails?.dob ? formatDateForInput(panDetails.dob) : prevData.dob || "",
        email: panDetails?.email || prevData.email || "",
      }));
    }
  }, [leadData, panDetails, panNumber, mobile]);
  

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      handleDataFromVerificationCibil("form_data", formData);
    }
  }, [formData]);

  const getDetailsByPancard = async () => {
    if (!formData.pan_number) {
      toast.error("Please enter a PAN number.");
      return;
    }

    try {
      const response = await executeGraphQLQueryFinex(
        GET_DETAILS_BY_PANCARD_UPDATE(formData.pan_number)
      );

      if (
        response &&
        JSON.parse(response?.data?.get_details_by_pancard)?.status === 200
      ) {
        const details = JSON.parse(response.data.get_details_by_pancard).data.response;
        setPanDetails(details);
        setIsFetched(true);

        setFormData((prevData) => ({
          ...prevData,
          first_name: details?.firstName || "",
          last_name: details?.lastName || "",
          dob: details?.dob ? formatDateForInput(details.dob) : "",
          email: details?.email || "",
          pan_number: details?.pan ||  panNumber || leadData?.customer?.pancard_no,
          mobile_number: mobile || leadData?.customer?.mobile_number
        }));

        handleDataFromVerificationCibil("pan_details", details);
        handleDataFromVerificationCibil("date_of_birth", details?.dob);
        toast.success("Data Fetched Successfully");
      } else {
        toast.error("Invalid PAN");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      console.error("API Error:", error.message);
    }
  };

  const formatDateForInput = (dob) => {
    const [day, month, year] = dob.split("/");
    return `${year}-${month}-${day}`;
  };

  const handleDataChange = (key, value) => {
    if(key==="pan_number"){
      setIsFetched(false)
    }
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="pan_card"
              id="pan_card"
              placeholder="Enter PAN Number"
              label="PAN No"
              value={formData?.pan_number}
              onChange={(e) => handleDataChange("pan_number", e.target.value)}
              maxLength={10}
              asterisk={true}
              disabled={!lead_id?true:false}
              verified={
                isFetched ? (
                  <img
                    src={require(`../../saathi-web/images/correct.png`)}
                    alt="verified"
                    width={20}
                    height={20}
                  />
                ) : (
                  <button
                    onClick={() => getDetailsByPancard(formData?.pan_number)}
                    style={{
                      background: "none",
                      border: "none",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Fetch
                  </button>
                )
              }
            />
          </div>

          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="mobile_number"
              id="mobile_number"
              label="Pan-Linked Mobile No."
              value={formData?.mobile_number}
              onChange={(e) => handleDataChange("mobile_number", e.target.value)}
              maxLength={10}
              asterisk={true}
            />
          </div>

          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="first_name"
              id="first_name"
              label="First Name"
              value={formData?.first_name}
              onChange={(e) => handleDataChange("first_name", e.target.value)}
              asterisk={true}
            />
          </div>

          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="last_name"
              id="last_name"
              label="Last Name"
              value={formData?.last_name}
              onChange={(e) => handleDataChange("last_name", e.target.value)}
              asterisk={true}
            />
          </div>

          <div className="col-sm-6">
            <NewInputField
              type="date"
              name="dob"
              id="dob"
              label="DOB"
              value={formData?.dob}
              onChange={(e) => handleDataChange("dob", e.target.value)}
              asterisk={true}
            />
          </div>

          <div className="col-sm-6">
            <NewInputField
              type="text"
              name="email"
              id="email"
              label="Email"
              value={formData?.email}
              onChange={(e) => handleDataChange("email", e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCibil;
