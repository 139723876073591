import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useCleverTap } from '../../CleverTapContext';
import crypto from "../../../config/crypto";
import { useParams } from "react-router-dom";
import NewInputField from "../../../view/elements/NewInputField";
import VerificationCibil from "../VerifictionCibil";
import OtpVarified from "./OtpVarified";
import ScoreCibil from "./ScoreCibil";
import { executeGraphQLMutationFinex, executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import { GET_MASTER_DATA } from "../../../queries/sangam/masterdata.gql";
import { CRIF_CONSENT_VERIFY, GET_CRIF_INFO, GET_DETAILS_BY_PANCARD_UPDATE, LEADS_QUERY } from "../../../queries/sangam/cibil.gql";
import CreateLead from "./NewLeadCreate";
import { LEAD_CREATION_FORM } from "../../../queries/sangam/create.gql";
import { LEAD_DETAIL_QUERY } from "../../../service/leads.gql";
import Loader from "../../../view/elements/PageLoader";
import { SAVE_CUST_DETAILS } from "../../../queries/sangam/customer.gql";

const NewHomeCibil = (props) => {
  const { updateStateIntoRedux ,} = props;
  const [showNext, setShowNext] = useState(1);
  const [pan, setPan] = useState("");
  const [selectedRadioOption, setSelectedRadioOption] = useState("");
  const [leadData, setLeadData] = useState({});
  const [mobile, setMobile] = useState("");
  const [pan_details, setPan_details] = useState({});
  const trackEvent = useCleverTap();
  const [newLead, setNewLead] = useState(false);
  const [masterdata, setMasterData] = useState([]);
  const [error, setError] = useState("");
  const [panError, setPanError] = useState("");
  const history = useHistory();
  const [panLinkedMobile, setPanLinkedMobile] = useState("");
  const [alreadyExistLeads,setAlreadyExistLeads]= useState([]);
  const [panLinkedMobileError, setPanLinkedMobileError] = useState("");
  const [dob,setDob]=useState("")
  const params = useParams();
  const [otp, setOtp] = useState("");
  const [crifRef,setCrifRef]=useState("");
  const [crifReport,setCrifReport]=useState("")
  const [formData, setFormData] = useState("");
  const [surpassVerificationPage,setSurpassVerificationPage]= useState(false)
  const [createdLead_id, setCreatedLeadId] = useState("")
  const [showCreateLead, setShowCreateLead] = useState(false);
  const [otpEntered, setOtpEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpErrors, setOtpErrors] = useState([]);  



  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  let lead_id = params.lead_id ? crypto.decode(params.lead_id) : null;

  useEffect(() => {
    trackEvent("page_loaded", "credit_report_pan");
  
    (async () => {
      setIsLoading(true);
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
      setIsLoading(false);
    })();
  }, [updateStateIntoRedux]);
  
  useEffect(() => {
    if (!lead_id) return;
  
    (async () => {
      setIsLoading(true);
      try {
        await leadDetails();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [lead_id]);
  
  useEffect(() => {
    if (!leadData || Object.keys(leadData).length === 0 || !lead_id) return;
  
    (async () => {
      setIsLoading(true);
      try {
        const resp = await fetchCrifCibilData();
        setShowNext(resp ? 5 : 3);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [leadData]);

  const togglePopup = () => {
    setNewLead(!newLead);
    document.body.classList.toggle("overflow-hidden", !newLead);
  };

  const handleBack = async () => {
    // setIsLoading(true); // Start loading
    if (lead_id && (showNext === 3 || (showNext === 5 && crifReport)||(showNext===4 && crifReport && lead_id))) {
      history.push(`/saathi-details-page/${crypto.encode(lead_id)}`);
      return;
    }
  
    try {
      setShowNext((prev) => {
        let newStep 
        if((crifReport && showNext!==5)||(!crifReport && otpErrors?.length>0)){
          newStep= prev - 2;
        }else if((crifReport && showNext===5) ){
          newStep= prev - 3;
        }
        else{
          newStep= prev - 1;
        }
        if (newStep === 0) {
          history.push("/saathi-tools");
        }
        return newStep;
      });
      if(!showNext===4){
        setSelectedRadioOption("");
      }
      setCrifReport("");
      setCrifRef("");
      setFormData({
        loan_type: "",
        loan_sub_type: [],
        required_loan: "",
        employment_type: null,
        monthly_salary: "",
        pan_number: "",
        mobile_number: "",
        first_name: "",
        last_name: "",
        dob: "",
        email: "",
      });
      setOtp("");
      setOtpEntered(false);
      setOtpErrors("")
      setShowCreateLead("")
      setSurpassVerificationPage(false)
      setCreatedLeadId("")
  
      await new Promise((resolve) => setTimeout(resolve, 1000)); 
  
    } catch (error) {
      console.error("Error handling back action:", error);
    } 
  };

  const validateFormData = () => {
    if (!formData?.pan_number || formData.pan_number.length !== 10) {
      toast.error("Invalid PAN Card");
      return false;
    }
    if (!formData?.mobile_number || formData.mobile_number.length !== 10 || !/^\d{10}$/.test(formData.mobile_number)) {
      toast.error("Mobile number must be exactly 10 digits.");
      return;
    }
    if (!formData?.first_name || formData.first_name.trim() === "") {
      toast.error("First Name cannot be empty");
      return false;
    }
    if (!formData?.last_name || formData.last_name.trim() === "") {
      toast.error("Last Name cannot be empty");
      return false;
    }
    if (!formData?.dob || formData.dob.trim() === "") {
      toast.error("Date of Birth cannot be empty");
      return false;
    }
    if (!formData?.loan_type  && !selectedRadioOption && !createdLead_id && !lead_id) {
      toast.error("Loan Type cannot be empty");
      return false;
    }
    if(otp.length<4 && (showNext===4 || (showNext===3 &&createdLead_id))){
      toast.error("Invalid OTP")
      return false;
    }
    return true;
  };

  const handleNext = async () => {
    if (isLoading) return;

  
    try {
      setIsLoading(true); 
      if ((!pan || pan.trim().length !== 10 || panError) && !formData?.pan_number && !crifReport) {
        return panError
          ? toast.error(panError)
          : toast.error("PAN card cannot be empty.");
      }
      if ((!panLinkedMobile || panLinkedMobile.trim().length !== 10 || panLinkedMobileError) && !formData?.mobile_number && !crifReport) {
        return panLinkedMobileError
          ? toast.error(panLinkedMobileError)
          : !panLinkedMobile
          ? toast.error("PAN Linked Mobile cannot be empty.")
          : toast.error("PAN Linked Mobile must be 10 digits.");
      }
  
      if (showNext === 1) {
        await getAllLeadsByMobile(panLinkedMobile);
      } else if (showNext === 2) {
        if (!selectedRadioOption && !showCreateLead && alreadyExistLeads?.length > 0) {
          return toast.error("Please select a lead to continue.");
        } else if (!alreadyExistLeads?.length > 0 || showCreateLead) {
          if(!validateFormData())return;
          const resp = await handleSave(showCreateLead);
          if (resp) {
            handlePanUpdate(resp);
            await fetchCrifCibilData(resp);
          }else {
            return ;
          }
        }else if(selectedRadioOption&& !showCreateLead && alreadyExistLeads?.length > 0){
        if(leadData?.customer?.pancard_no){
             const resp= await fetchCrifCibilData()
         if(resp){
          setShowNext(showNext + 2);
         }
        }
        }
      } 
      else if (showNext === 3 && (otp.length <= 0) && !crifReport) {
        if (validateFormData()) {
                 handlePanUpdate();
                 const resp= await fetchCrifCibilData()
                //  if(resp){
                //   setShowNext(showNext + 2);
                //  }
        } 
      } else if (showNext === 4 || (showNext === 3 && otp.length > 0)) {

        if (otp && !crifReport) {
          if(!validateFormData())return;
          const isOtpVerified = await callVerifyConsignmentOtp();
          if (!isOtpVerified) {
            setOtpErrors((...prevErrors)=>[...prevErrors,""]);
          }
          setOtpEntered(true);
        }else if(crifReport){
          history.push("/saathi-leads");
        } else {
          toast.error("Enter OTP to proceed.");
          return;
        }
      } else if ((showNext === 5 && (otpErrors?.length<=0||crifReport))|| ((showNext===3 || showNext===4) && crifReport)) {

        history.push(`/saathi-details-page/${crypto.encode(lead_id||createdLead_id||leadData?.id)}`);
      }else if(showNext === 5 && otpErrors?.length>0){

        setShowNext((prev)=>prev-3)
        setOtpErrors('')
        setOtp("")
        setOtpEntered(false)
      }
  
      setShowNext((prev) => prev + 1);
    } catch (error) {
      toast.error("An unexpected error occurred.");
      console.error(error);
    } finally {
      setIsLoading(false); 
    }
  };

  
  const handlePan = (e) => {
    const value = e.target.value.toUpperCase();
    setPan(value);

    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; 

    if (!value) {
      setPanError("PAN No is required");
    } else if (value.length !== 10) {
      setPanError("PAN No must be exactly 10 characters");
    } else if (!panRegex.test(value)) {
      setPanError("Invalid PAN format (e.g., ABCDE1234F)");
    } else {
      setPanError("");
    }
  };


  const PayoutCheck = () => {
    history.push("/payout-calculator");
  }

  const EmiCheck = () => {
    history.push("/emi-calculator");
  }

  const handleRadioChange = (event,value) => {
    setSelectedRadioOption(value);
  };

  const handleInput = (data) => {
    setLeadData(data);
    setFormData((prevData) => ({
      ...prevData,
      first_name: data?.customer?.first_name || "",
      last_name: data?.customer?.last_name || "",
      mobile_number: data?.customer?.mobile ||panLinkedMobile||"",
      pan_number: data?.customer?.pancard_no ||pan || "",
      dob: data?.customer?.dob || "",
    }));
  };

  const handleDataFromVerificationCibil = (name,value) => {
    if(name==="date_of_birth"){
      setDob(value)
    }else if(name==="mobile"){
      setMobile(value);
    }else if(name==="otp"){
      setOtp(value)
    }else if(name==="crif_ref"){
      setCrifRef(value)
    }else if(name==="pan_details"){
      setPan_details(value)
    }else if(name==="form_data"){
      setFormData((prevData) => ({
        ...prevData,
        ...value,
      }));
    }
  };

  const handlePanLinkedMobile = (event) => {
    const mobileValue = event.target.value;
    const sanitizedMobile = mobileValue.replace(/\D/g, '');
    setPanLinkedMobile(sanitizedMobile);

   
      if(!sanitizedMobile){
        setPanLinkedMobileError("Pan Linked Mobile can not be empty.");
      }
      else if (!/^[6-9]/.test(sanitizedMobile)) {
        setPanLinkedMobileError("Mobile number must start with 6, 7, 8, or 9");
      } else {
        setPanLinkedMobileError("");
      }
    
  
  };
  
  const getAllLeadsByMobile = async (mobile) => {
    if (isLoading) return; 
  
    try {
      setIsLoading(true);
  
      const filterData = { mobile };
      const response = await executeGraphQLQueryFinex(
        LEADS_QUERY(filterData, loginUserInfo?.dealer_id, loginUserInfo?.user_id)
      );
  
      setAlreadyExistLeads(response?.data?.leads[0]?.lead_list);
    } catch (error) {
      console.error("Error fetching leads:", error);
      toast.error("Failed to fetch leads. Please try again.");
    } finally {
      setIsLoading(false); 
    }
  };

  const leadDetails = async () => {
    if (isLoading) return; 
  
    try {
      setIsLoading(true); 
  
      const result = await executeGraphQLQueryFinex(LEAD_DETAIL_QUERY(lead_id));
      if (result?.data?.get_lead_detail[0]) {
        setLeadData(result?.data?.get_lead_detail[0]);
      }
    } catch (error) {
      console.error("Error fetching lead details:", error);
      toast.error("Failed to fetch lead details. Please try again.");
    } finally {
      setIsLoading(false); 
    }
  };
  


  const callVerifyConsignmentOtp = async () => {
    if (isLoading) return; 
  
    const variables = {
      cibilCrifInput: {
        pan_card: formData?.pan_number,
        mobile: Number( formData?.mobile_number),
        otp: otp,
        crif_ref: crifRef,
        partner_id: `${loginUserInfo?.dealer_id}`,
        user_id: loginUserInfo?.user_id,
        lead_id: leadData?.id || createdLead_id,
      },
    };
  
    try {
      setIsLoading(true); 
  
      const resp = await executeGraphQLMutationFinex(CRIF_CONSENT_VERIFY(), variables);
      const consentVerifyData = resp?.data?.cibil_crif_consent_verify;
  
      if (consentVerifyData?.status !== 200) {
        throw new Error(
          consentVerifyData?.errors || "Unable to send OTP for consent."
        );
      }
  
      const reportData = consentVerifyData?.data?.data?.crif_report;
  
      if (
        reportData?.cta === "Success" &&
        reportData?.consent === 1 &&
        reportData?.score &&
        reportData?.report
      ) {
        setCrifReport(reportData);
        toast.success("CRIF report fetched successfully.");
        return 1;
      } else {
        setOtpErrors((...prevErrors)=>[...prevErrors,"The customer details shared do not match the credit bureau’s records. Please re-check the shared details and try again."]);
        return 0;
      }
    } catch (error) {
      const errMsg = error.message || "Unable to download the report. Please try again.";
      if (errMsg.includes("Customer already granted the consent")) {
        toast.success(errMsg);
      } else {
        setOtpErrors((...prevErrors)=>[...prevErrors,errMsg]);
      }
      console.error("callVerifyConsignmentOtp >> error: ", error);
    } finally {
      setIsLoading(false);
    }
  };
    
  const fetchCrifCibilData = async (createdLeadId = 0) => {
    if (isLoading && !lead_id) return; 
    let isMounted = true; 
    let panNo
    let mobile
    let leadId;
    if(createdLeadId){
      panNo= formData?.pan_number
      mobile = Number(formData?.mobile_number)
      leadId = createdLeadId?.lead_id
    }else if(lead_id){
      panNo= leadData?.customer?.pancard_no||formData?.pan_number
      mobile = Number(leadData?.customer?.mobile|| formData?.mobile_number)
      leadId = lead_id
    }else{
      panNo =leadData?.customer?.pancard_no||formData?.pan_number
      mobile = Number(leadData?.customer?.mobile ||formData?.mobile_number)
      leadId = leadData?.id||formData?.lead_id;
    }
    const partnerId = `${loginUserInfo?.dealer_id}`;
    const userId = loginUserInfo?.user_id;

    if (!panNo) {
      return 0;
    }
    if (!mobile && !formData?.mobile_number) {
      return 0;
    }
    if (!partnerId) {
      return toast.error(`Partner id not found.`);
    }
    if (!leadId) {
      return toast.error(`Lead id not found.`);
    }
    if (!userId) {
      return toast.error(`User id not found.`);
    }
  
    try {
      setIsLoading(true); 
  
      const result = await executeGraphQLQueryFinex(
        GET_CRIF_INFO(panNo, "crif_report", mobile, partnerId, leadId, userId)
      );
  
      const crifReportData = result?.data?.cibil_info?.crif_report;
  
      if (
        crifReportData?.cta === "Success" &&
        crifReportData?.consent === 1 &&
        crifReportData?.score &&
        crifReportData?.report
      ) {
        setCrifReport(crifReportData);
        handleDataFromVerificationCibil("crif_ref", crifReportData.ref_no);
        toast.success("CRIF report fetched successfully.");
        setIsLoading(false);

        return 1;
      } else {
        setIsLoading(false);

        return 0;
      }

    } catch (error) {
      console.error("\n\n fetchCrifCibilData >> error => ", error);
      toast.error("An error occurred while fetching the CRIF report.");
      setIsLoading(false);
      return 0; // Ensure you return 0 on error
    } 
  };
  

  const handleSave = async (is_duplicate = false) => {
    if (isLoading) return; // Prevent multiple submissions
  
    setIsLoading(true); // Start loading
  
    try {
      const mutation = LEAD_CREATION_FORM;
      const variables = {
        partner_id: loginUserInfo?.dealer_id,
        api_called_by: "saathi_web",
        newLeadInput: {
          first_name: formData?.first_name,
          last_name: formData?.last_name,
          mobile: formData?.mobile_number,
          loan_type: formData?.loan_type === "Lap" ? 2 : 1, 
          ...(formData?.loan_sub_type
            ? { loan_sub_type: formData?.loan_sub_type?.join(",") }
            : {}),
          fulfillment_type: "ambak",
          assigned_partner_id: formData?.assigned_to || loginUserInfo?.partner_id,
          parent_partner_id: loginUserInfo?.parent_partner_id,
          ...(is_duplicate ? { is_duplicate } : {}),
        },
      };
  
      const headers = { is_demo: loginUserInfo?.is_demo || 0 }; // Ensure headers are valid
  
      const resp = await executeGraphQLMutationFinex(mutation, variables, headers);
  
      if (!resp?.data?.create_loan?.id) {
        toast.error(resp?.data?.create_loan?.message);
        return 0;
      }
  
      toast.success("Lead Created Successfully");
      setSurpassVerificationPage(true);
      setCreatedLeadId(resp?.data?.create_loan?.id);
  
      return { lead_id: resp?.data?.create_loan?.id };
    } catch (err) {
      console.error(`🔻 ERROR: ${err.message}`);
      toast.error(err.message || "Got Invalid value. Please Check");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  

  const handleNewLeadClick = () => {
    setSelectedRadioOption("")
    setShowCreateLead(true);
  };

  const handlePanUpdate = (new_lead_id) => {
  
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (formData?.pan_number && !panRegex.test(formData?.pan_number)) {
        toast.error("Invalid PAN card number. Please enter a valid one.");
        return;
      }
      let lead_idd = leadData?.id || lead_id ||new_lead_id?.lead_id;
      let reqBody = {
        lead_id: lead_idd,
        first_name: leadData?.customer?.first_name || formData?.first_name || "",
        last_name: leadData?.customer?.last_name || formData?.last_name || "",
        mobile: panLinkedMobile|| formData?.mobile_number || "",
        pancard_no: formData?.pan_number||"",
        dob: formData?.dob||"",
      };
  
      let mutation = SAVE_CUST_DETAILS,
        variables = {
          api_called_by: "saathi_web",
          partner_id: loginUserInfo?.dealer_id,
          LeadAdditionalInput: {
            lead_id: lead_idd,
            customer: reqBody,
          },
        };
      executeGraphQLMutationFinex(mutation, variables)
        .then((resp) => {
          let response = resp?.data?.save_customer_basic_details || null;
          if (response && !response.success) {
            throw new Error(response.error || "Something went wrong");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    };
  return (

    <div className="LeadContainer">
      <div className="mytoolcibilcard">
        <div className="toolcibilleft">
          <div className={showNext === 1 ? "toolbgcolor" : ""}>
            <div className="toolcibilpaddingtop">
              <div onClick={handleBack} className="backoffer">
                    <i className="ic-arrow_back" style={{ fontSize: 20, cursor: "pointer" }}></i>
                    {showNext < 4 && (
                      <div>Check Credit Score</div>
                    )}
                    {showNext === 4 && (
                      <div>Get Customer’s Consent</div>
                    )}
                    {showNext === 5 && (
                      <div>Credit Report Details</div>
                    )}
              </div>

             
            </div>

            {showNext === 1 && (
              <>
                <div className="mytoolcibilflexcard" style={{marginTop:60}}>
                  <div>
                    <img
                      src={require(`../../../../src/saathi-web/images/creditscore.png`)}
                      alt="thankyou"
                      width={210}
                      height={110}
                    />
                  </div>
                  <div className="offercost">
                    <img
                      src={require(`../../../../src/saathi-web/images/rupeeicon.png`)}
                      alt="thankyou"
                      width={18}
                      height={18}
                    />
                    <div>No Cost Credit Checks!</div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="toolcibilpadding">
            {showNext === 1 && (
              <>
                <div className="offerheadingben">Benefits of credit checks prior to loan applications</div>
                <div className="offerlistingicon">
                  <img
                    src={require(`../../../../src/saathi-web/images/correct.svg`)}
                    alt="thankyou"
                    width={18}
                    height={18}
                  />
                  <div>Crack Lowest Interest Rates</div>
                </div>
                <div className="offerlistingicon">
                  <img
                    src={require(`../../../../src/saathi-web/images/correct.svg`)}
                    alt="thankyou"
                    width={18}
                    height={18}
                  />
                  <div>Close DPDs & Other Queries in Advance</div>
                </div>
                <div className="offerlistingicon">
                  <img
                    src={require(`../../../../src/saathi-web/images/correct.svg`)}
                    alt="thankyou"
                    width={18}
                    height={18}
                  />
                  <div>Increase Loan Approval Chances</div>
                </div>

                <div className="row" style={{ marginTop: 50 }}>
                  <div className="col-sm-6">
                    <NewInputField
                      type="text"
                      name="pan_card"
                      id="pan_card"
                      placeholder=""
                      label="Pan No"
                      onChange={handlePan}
                      value={pan}
                      maxLength={10}
                      asterisk={true}
                      required={true}
                      error={panError}
                    />
                  </div>
                  <div className="col-sm-6">
                    <NewInputField
                      type="text"
                      name="mobile"
                      id="mobile"
                      placeholder=""
                      label="Pan-Linked Mobile No."
                      onChange={handlePanLinkedMobile}
                      value={panLinkedMobile}
                      maxLength={10}
                      asterisk={true}
                      required={true}
                      error={panLinkedMobileError}
                     
                    />
                  </div>
                </div>
                { isLoading ? <Loader/> : ''}

              </>
            )}

            {showNext === 2 && alreadyExistLeads.length > 0 && !showCreateLead  && (
              <>
                <div className="leadheading">Mobile No. Already Exists</div>
                <div className="amountdetailsname">
                  This number exists for the following customer(s). Select the customer to proceed or create a new lead:
                </div>
                <div className="loanfulfillment">
                  <ul className="offerlistingcoapp">
                    {alreadyExistLeads.map((lead, index) => (
                      <li key={index}>
                        <div className="loaninput">
                          <input
                            type="radio"
                            required
                            id={`fulfillment_${lead.id}`}
                            name="fulfillment_type"
                            checked={selectedRadioOption === lead.id}
                            onChange={(e) => {
                              handleRadioChange(e, lead.id);
                              handleInput(lead);
                            }}
                          />
                          <div>
                            <label htmlFor={`fulfillment_${lead.id}`} className="offerlistingname">
                              {`${lead?.customer?.first_name} ${lead?.customer?.last_name}` || "Unknown Name"}
                            </label>
                            <div className="amountdetailsname">
                              ID - {lead.id} | {lead.loan_type===1?"HL":"LAP" || "N/A"}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                
              </>
            )}

            {showNext === 2 && (alreadyExistLeads.length === 0 || showCreateLead) && (
              <>
                <div className="leadheading">Create your Customer Lead.</div>
                <div className="amountdetailsname" style={{marginBottom:20}}>
                 Create a lead to proceed.
                </div>
                <CreateLead
                  panNumber={pan}
                  leadData={leadData}
                  handleDataFromVerificationCibil={handleDataFromVerificationCibil}
                  mobile={panLinkedMobile}
                  partner_id={loginUserInfo?.dealer_id}
                />
              </>
            )}

            {showNext === 3 &&!surpassVerificationPage && !crifReport&& (
              <>
               <div className="leadheading">Verify Customer Details.</div>
                <VerificationCibil
                  panNumber={pan}
                  leadData={leadData}
                  handleDataFromVerificationCibil={handleDataFromVerificationCibil}
                  mobile={panLinkedMobile}
                  lead_id={lead_id}
                />
              </>
            )}
{ ((!crifReport && showNext === 4 && (otp.length !== 4 || !otpEntered)) 
   || (surpassVerificationPage && !crifReport && showNext === 3)) && (
              <>
                <OtpVarified 
                  createdLeadId={createdLead_id} 
                  formData={formData} 
                  pan_details={pan_details}  
                  mobile={panLinkedMobile} 
                  panNumber={pan} 
                  leadData={leadData} 
                  user_id={loginUserInfo?.user_id} 
                  partner_id={loginUserInfo?.dealer_id} 
                  dob={dob} 
                  handleDataFromVerificationCibil={handleDataFromVerificationCibil} 
                  handleBack={handleBack}
                  setOtp={setOtp}
                />
              </>
              
)}
            {(crifReport||otp.length===4) && ((crifReport&& (showNext===4||showNext===3))||showNext === 5 ||(showNext===4 && createdLead_id)) && (
              <>
              <ScoreCibil otpErrors={otpErrors}   createdLeadId={createdLead_id}  formData={formData}  crifReport={crifReport} mobile={panLinkedMobile} panNumber={pan} leadData={leadData} user_id={loginUserInfo?.user_id} partner_id={loginUserInfo?.dealer_id} crifRef={crifRef} />
              </>
            )}

           
          </div>

          <div className="cibilsendconsent">
            {showNext === 3 && !crifReport && (
              <>
                <div className="offerterms">
                  By clicking on "Proceed", you agree to our
                  <a href="https://ambak.com/terms-and-conditions"> Terms And Conditions,</a>
                  <a href="https://ambak.com/privacy-policy"> Privacy Policy</a>
                  and <a href="https://ambak.com/terms-of-use"> Terms of Use</a>
                </div>
              </>
            )}

            <div className="cibilsendbottom">
              {showNext === 1 && (
                <button className="cibilcheckbtn" disabled={isLoading} onClick={handleNext}>
                  <img
                    src={require(`../../../../src/saathi-web/images/info.png`)}
                    alt="thankyou"
                    width={18}
                    height={18}
                    style={{ marginRight: 10 }}
                  />
                  Check credit for a Lead
                </button>
              )}

              {showNext === 2 && (
                <div className="buttonflexmobile">
                  {!showCreateLead && alreadyExistLeads.length>0 &&(
                     <button className="cibilnewlead" onClick={handleNewLeadClick}>+ New Lead</button>
                  )}
                  {alreadyExistLeads.length>0 &&(
                    <button className="cibilcheckbtn" disabled={isLoading} onClick={handleNext}>
                    Continue
                  </button>
                  )}
                    {!alreadyExistLeads.length>0 &&(
                    <button className="cibilcheckbtn" disabled={isLoading} onClick={handleNext}>
                   Proceed
                  </button>
                  )}
                </div>
              )}

          {/* <Modal isOpen={newLead} togglePopup={togglePopup} width={800} removeHeight={true}>
            <NewLeadCreate
              masterData={masterdata}
              loginUserInfo={loginUserInfo}
            />
          </Modal> */}

              {((showNext === 3 && !crifReport )|| (showNext === 4 && !crifReport)) && (
                <div className="buttonflexmobile">
                  <button className="cibilcheckbtn" disabled={isLoading} onClick={handleNext}>
                  {isLoading ? "Loading..." : "Proceed"}
                  </button>
                </div>
              )}
              {(showNext === 5|| (showNext===4 && crifReport)|| (showNext===3 && crifReport)) && (
                <div className="buttonflexmobile">
                  <button className="cibilcheckbtn"disabled={isLoading} onClick={handleNext}>
                  {isLoading ? "Loading..." :otpErrors.length>0?"Try Again": "Go to lead"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="toolcibilright">
          <div className="verificationheadingmain">Other Tools</div>
          <ul className="otherlinkshow">
            {/* <li>
                <Link to="#">
                  <div className="otherlinkslist">
                    <img
                      src="https://static.ambak.com/saathi/1724136701567.png"
                      alt="home"
                      width={60}
                      height={60}
                    ></img>
                    <div className="cibiltextwidth">
                      Get home insurance starting @ just ₹90/ month*
                    </div>
                    <div>
                      <img
                        src="https://static.ambak.com/saathi/1724140240138.png"
                        alt="icon"
                        width={12}
                        height={14}
                      ></img>
                    </div>
                  </div>
                </Link>
              </li>

              <li>
                <Link to="#">
                  <div className="otherlinkslist">
                    <img
                      src="https://static.ambak.com/saathi/1723192167400.png"
                      alt="home"
                      width={60}
                      height={60}
                    ></img>
                    <div className="cibiltextwidth">APF Search Engine</div>
                    <div>
                      <img
                        src="https://static.ambak.com/saathi/1724140240138.png"
                        alt="icon"
                        width={12}
                        height={14}
                      ></img>
                    </div>
                  </div>
                </Link>
              </li> */}
            <li>
              <Link to="#">
                <div className="otherlinkslist">
                  <img
                    src="https://static.ambak.com/saathi/1723193405707.png"
                    alt="home"
                    width={60}
                    height={60}
                  />
                  <div className="cibiltextwidth" onClick={EmiCheck}>
                    EMI Calculator
                  </div>
                  <div>
                    <img
                      src="https://static.ambak.com/saathi/1724140240138.png"
                      alt="icon"
                      width={12}
                      height={14}
                    />
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="#">
                <div className="otherlinkslist">
                  <img
                    src="https://static.ambak.com/saathi/1723193491801.png"
                    alt="home"
                    width={60}
                    height={60}
                  />
                  <div className="cibiltextwidth" onClick={PayoutCheck}>
                    Payout Calculator
                  </div>
                  <div>
                    <img
                      src="https://static.ambak.com/saathi/1724140240138.png"
                      alt="icon"
                      width={12}
                      height={14}
                    />
                  </div>
                </div>
              </Link>
            </li>
            {/* <li>
                <Link to="#">
                  <div className="otherlinkslist">
                    <img
                      src="https://static.ambak.com/saathi/1723193561304.png"
                      alt="home"
                      width={60}
                      height={60}
                    ></img>
                    <div className="cibiltextwidth">Doc List Generator</div>
                    <div>
                      <img
                        src="https://static.ambak.com/saathi/1724140240138.png"
                        alt="icon"
                        width={12}
                        height={14}
                      ></img>
                    </div>
                  </div>
                </Link>
              </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(NewHomeCibil)
);
