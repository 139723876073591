import React, { useState } from "react";

const Accordion = ({ faqs }) => {
  const [ShowIndexNumber, SetShowIndexNumber] = useState(-0);

  const showHide = (index) => {
    SetShowIndexNumber(index === ShowIndexNumber ? -1 : index);
  };

  const AccordDataItems = faqs?.map((faq, index) => (
    <li
      key={index}
      className={`faqli ${index === ShowIndexNumber ? 'faqli-open' : ''}`}
    >
      <div
        className={`faqheading ${index === ShowIndexNumber ? 'faqheading-open' : ''}`}
        onClick={() => showHide(index)}
      >
        <div>
          {index + 1}. {faq.question}
        </div>
          <span className="faqicon">
            <img src={require(`../../../src/saathi-web/images/downicon.png`)} width={10} height={5} />
          </span>
      </div>
      <div className={`accordionHeight ${index === ShowIndexNumber ? '' : 'hide'}`}>
        <p className="accordionDetail">{faq.answer}</p>
      </div>
    </li>
  ));

  return <ul className="faqquastion">{AccordDataItems}</ul>;
};

export default Accordion;
