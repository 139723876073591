import React, { useEffect, useMemo, useState } from "react";
import InputField from "../../../../view/elements/Input";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";
import LoanOfferIncomeDetailsSE from "./LoanOfferIncomeDetailsSE";
import LoanOfferAdditionalIncome from "./LoanOfferAdditionalIncome";
import { toast } from "react-toastify";
import { SAVE_CUST_DETAILS } from "../../../../queries/sangam/customer.gql";
import {
  SAVE_OFFER_BANK_DETAILS,
  SAVE_OFFER_ITR_DETAILS,
} from "../../../../queries/sangam/bre.gql";
import { executeGraphQLMutationFinex } from "../../../../common/executeGraphQLQuery";
import { useCleverTap } from '../../../CleverTapContext';

const AMOUNT_TYPE_OPTIONS = [
  { id: "account", label: "Account", value: "account" },
  { id: "cash", label: "Cash", value: "cash" },
];

const LoanOfferIncomeDetails = ({
  breData,
  handleBreDataChange,
  masterdata,
  setNextActive,
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [errors, setErrors] = useState({});
  const [isUseEffectCalled, setIsUseEffectCalled] = useState(false);
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState(1);
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    []
  );
  const trackEvent = useCleverTap();

  const ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata } : {};
    const RENT_INCOME_ID = 1,
      OTHER_INCOME_ID = 5,
      LIQUID_INCOME_ID = 6;
    options["amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["additional_income_type"] =
      options["additional_income_type"]
        ?.map((obj) => ({
          ...obj,
          value: obj.id,
        }))
        ?.filter(
          (obj) =>
            obj.value == RENT_INCOME_ID ||
            obj.value == OTHER_INCOME_ID ||
            obj.value == LIQUID_INCOME_ID
        ) || [];
    options["filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    return options;
  }, [masterdata]);

  useEffect(() => {
    if (breData.is_additional_income === "yes" && !isUseEffectCalled) {
      for (let i = 0; i < breData.additional_income?.length; i++) {
        handleAddAdditionalIncome(i);
        ALL_OPTIONS[`filtered_additional_income_type_${i + 1}`] = ALL_OPTIONS[
          `filtered_additional_income_type_${i}`
        ]?.filter(
          (obj) => obj.value !== breData.additional_income[i].income_type_id
        );
      }
      setIsUseEffectCalled(true);
    }
  });

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`filtered_additional_income_type_${index + 1}`] = ALL_OPTIONS[
        `filtered_additional_income_type_${index}`
      ].filter((obj) => obj.value !== data.value);
    }
  };

  const handleFieldsValue = (key, value, price = false) => {
    if (price) {
      value = value.replace(/[^0-9]/g, "");
    }
    handleSetErrors(key, "");
    handleBreDataChange(key, value);
  };

  const handleTabChange = (name, value) => {
    handleBreDataChange(name, value);
    if (value === "yes") {
      handleAddAdditionalIncome(0);
    } else {
      setAdditionalIncomeCount(0);
      setAdditionalIncomeFormFields([]);
    }
  };

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields.filter(
        (obj) => obj.id === `additional_income_type_${index}`
      );
      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[additionalIncomeFormFields.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `additional_income_type_${index}` };
        setAdditionalIncomeCount(index + 1);
        setAdditionalIncomeFormFields((current) => [...current, dropDownObj]);
      }
    }
  };

  const handleRemoveAdditionalIncome = (index) => {
    const existingAddIncome = Array.isArray(breData.additional_income)
      ? [...breData.additional_income]
      : [];
    const existingAddIncomeField = [...additionalIncomeFormFields];
      const removedEle = {
      ...existingAddIncome[index],
      status: "0",
      income_type_id: null,
      amount_type: "",
      amount: "",
    };
      existingAddIncomeField.splice(index, 1);
      if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[
        existingAddIncomeField.length - 1
      ].canAddMore = true;
    }
      if (index >= 0 && index < existingAddIncome.length) {
      existingAddIncome.splice(index, 1);
      existingAddIncome.push(removedEle);
    }
      setAdditionalIncomeCount(additionalIncomeCount - 1);
    setAdditionalIncomeFormFields(existingAddIncomeField);
    handleBreDataChange("additional_income", existingAddIncome);
  };
  

  const handleSetAdditionalIncomeData = (data) => {
    handleBreDataChange("additional_income", data);
  };

  const handleNext = () => {

    trackEvent("cta_clicked", "offer_engine_form_page_2");
    if (
      breData.profession !== 3 &&
      (!breData.monthly_salary ||
        breData.monthly_salary === "" ||
        breData.monthly_salary === null)
    ) {
      return handleSetErrors(
        "monthly_salary",
        "Take Home Monthly Salary is required"
      );
    }
    if (
      breData.profession !== 3 &&
      (!breData.gross_monthly_salary ||
        breData.gross_monthly_salary === "" ||
        breData.gross_monthly_salary === null)
    ) {
      return handleSetErrors(
        "gross_monthly_salary",
        "Gross Salary is required"
      );
    }
    if (
      breData.profession === 3 &&
      breData.income_calculation_mode === "bank" &&
      (!breData.no_of_accounts ||
        breData.no_of_accounts === "" ||
        breData.no_of_accounts === null)
    ) {
      return handleSetErrors("no_of_accounts", "Banking Income is required");
    }
    handleSubmit();
  };

  const handleSubmit = async () => {
    try {
      const {
        lead_id,
        profession,
        monthly_salary,
        gross_monthly_salary,
        income_calculation_mode,
        is_additional_income,
        additional_income,
        existing_emi_amount,
        emi_ending_six_month,
        year_1,
        year_2,
        no_of_accounts,
        customer_id,
        first_name,
        last_name,
        mobile,
      } = breData;

      if (lead_id) {
        const mutation = SAVE_CUST_DETAILS;
        const isCustAddInc = is_additional_income || "no";
        const itrDetails = [],
          bankDetails = [];
        const lead_details = {
          lead_id,
          ...(profession !== 3 ? { monthly_salary, gross_monthly_salary } : {}),
        };
        const customer = {
          is_additional_income: isCustAddInc,
          existing_emi_amount,
          emi_ending_six_month,
          first_name,
          last_name,
          mobile,
          ...(profession === 3 ? { offer_type: income_calculation_mode } : {}),
        };

        if (isCustAddInc === "yes") {
          const additionalIncome = additional_income?.map((income) => {
            return {
              ...(income.id ? { id: income?.id } : {}),
              lead_id,
              customer_id,
              customer_type: "customer",
              income_type_id: income.income_type_id,
              amount_type: income.amount_type,
              amount: income.amount,
              status: income.status,
            };
          });
          customer["additional_income"] = additionalIncome;
        }

        if (isCustAddInc === "no") {
          const obj = {
            lead_id,
            customer_id,
            customer_type: "customer",
          };
          customer["additional_income"] = [obj];
        }

        if (profession === 3) {
          if (income_calculation_mode === "itr") {
            if (year_1) {
              itrDetails.push({
                ...(year_1.id ? { id: year_1?.id } : {}),
                lead_id,
                is_itr_filled: "yes",
                npat: year_1.npat || "",
                depreciation: year_1.depreciation || "",
                interest: year_1.interest || "",
                tax_paid: year_1.tax_paid || "",
                itr_year: "1",
                customer_id,
                customer_type: "customer",
              });
            }

            if (year_2) {
              itrDetails.push({
                ...(year_2.id ? { id: year_2?.id } : {}),
                lead_id,
                is_itr_filled: "yes",
                npat: year_2.npat || "",
                depreciation: year_2.depreciation || "",
                interest: year_2.interest || "",
                tax_paid: year_2.tax_paid || "",
                itr_year: "2",
                customer_id,
                customer_type: "customer",
              });
            }
          } else if (income_calculation_mode === "bank") {
            for (let i = 0; i < no_of_accounts; i++) {
              bankDetails.push({
                ...(breData[`bank_details_id_${i}`]
                  ? { id: breData[`bank_details_id_${i}`] }
                  : {}),
                lead_id,
                account_type: breData[`bank_account_type_${i}`] || "",
                amount: breData[`bank_account_avg_amount_${i}`] || "",
                customer_id,
                customer_type: "customer",
              });
            }
            customer["no_of_accounts"] = no_of_accounts;
          }
        }
        const variables = {
          api_called_by: "saathi_web",
          partner_id: loginUserInfo?.dealer_id,
          LeadAdditionalInput: {
            lead_id,
            customer,
            lead_details,
          },
        };
        await executeGraphQLMutationFinex(mutation, variables);
        if (itrDetails.length > 0) {
          const itrVariable = {
            offerItrDetails: {
              offer_itr_details: itrDetails,
            },
          };
          await executeGraphQLMutationFinex(
            SAVE_OFFER_ITR_DETAILS,
            itrVariable
          );
        } else if (bankDetails.length > 0) {
          const bankVariable = {
            offerBankDetails: {
              offer_bank_details: bankDetails,
            },
          };
          await executeGraphQLMutationFinex(
            SAVE_OFFER_BANK_DETAILS,
            bankVariable
          );
        }
        toast.success("Success");
      }
      setNextActive();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSetErrors = (field_name, value) => {
    setErrors((current) => ({
      ...current,
      [field_name]: value,
    }));
  };

  return (
    <>
      <div className="commonloancard">
        <div className="mainheadingall">Income Details</div>
        <div className="loanofferallbox">
          {breData.profession === 3 ? (
            <LoanOfferIncomeDetailsSE
              breData={breData}
              handleBreDataChange={handleBreDataChange}
              errors={errors}
              setErrorFields={handleSetErrors}
            />
          ) : (
            <div className="row">
              <div className="col-sm-4">
                <InputField
                  type="text"
                  name="monthly_salary"
                  id="monthly_salary"
                  maxLength={12}
                  placeholder="Enter Take Home Monthly Salary"
                  label="Take Home Monthly Salary"
                  onChange={(e) =>
                    handleFieldsValue("monthly_salary", e.target.value, true)
                  }
                  value={handlePriceInputIndian(breData.monthly_salary)}
                  required={true}
                />
                {errors?.monthly_salary && (
                  <div className="formerrorinput">{errors.monthly_salary}</div>
                )}
              </div>
              <div className="col-sm-4">
                <InputField
                  type="text"
                  required={true}
                  name="gross_monthly_salary"
                  id="gross_monthly_salary"
                  maxLength={12}
                  placeholder="Enter Gross Salary"
                  label="Gross Salary"
                  onChange={(e) =>
                    handleFieldsValue(
                      "gross_monthly_salary",
                      e.target.value,
                      true
                    )
                  }
                  value={handlePriceInputIndian(breData.gross_monthly_salary)}
                />
                {errors?.gross_monthly_salary && (
                  <div className="formerrorinput">
                    {errors.gross_monthly_salary}
                  </div>
                )}
              </div>
            </div>
          )}

          {(breData?.income_calculation_mode === "itr" ||
            breData.profession !== 3) && (
            <>
              <div className="allheadingincome">Any Additional Income?</div>
              <div className="cibilloanflex">
                <div
                  className={`cibilhometab ${
                    breData.is_additional_income === "yes" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("is_additional_income", "yes")}
                >
                  <span>Yes</span>
                </div>

                <div
                  className={`cibilhometab ${
                    breData.is_additional_income === "no" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("is_additional_income", "no")}
                >
                  <span>No</span>
                </div>
              </div>

              {breData.is_additional_income === "yes" && (
                <>
                  {additionalIncomeFormFields.map((data, index) => (
                    <LoanOfferAdditionalIncome
                      index={index}
                      ALL_OPTIONS={ALL_OPTIONS}
                      setAdditionalIncomeType={handleAdditionalIncomeType}
                      removeAdditionalIncome={handleRemoveAdditionalIncome}
                      setAdditionalIncomeData={handleSetAdditionalIncomeData}
                      additionalIncomeData={breData["additional_income"]}
                      key={index}
                    />
                  ))}
                  {additionalIncomeCount < 3 && (
                    <button
                    style={{       
                      border:"none",
                      background:"#fff",
                      width: "fit-content"
                    }}
                      className="loanadditional"
                      onClick={() =>
                        handleAddAdditionalIncome(additionalIncomeCount)
                      }
                    >
                      + Add Additional Income
                    </button>
                  )}
                </>
              )}
            </>
          )}

          <div className="allheadingincome">Obligation</div>
          <br />
          <div className="row">
            <div className="col-sm-4">
              <InputField
                type="text"
                maxLength={12}
                name="existing_emi_amount"
                id="existing_emi_amount"
                placeholder="Current EMIs"
                label="Current EMIs"
                onChange={(e) =>
                  handleFieldsValue("existing_emi_amount", e.target.value, true)
                }
                value={handlePriceInputIndian(breData.existing_emi_amount)}
              />
            </div>
            <div className="col-sm-4">
              <InputField
                type="text"
                maxLength={12}
                name="emi_ending_six_month"
                id="emi_ending_six_month"
                placeholder="EMIs Ending in 6 Months"
                label="EMIs Ending in 6 Months"
                onChange={(e) =>
                  handleFieldsValue(
                    "emi_ending_six_month",
                    e.target.value,
                    true
                  )
                }
                value={handlePriceInputIndian(breData.emi_ending_six_month)}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: "end" }}>
        <button className="loanbtnnext" onClick={handleNext}>
          Next
        </button>
      </div>
    </>
  );
};

export default LoanOfferIncomeDetails;
