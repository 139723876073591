import React, { useEffect, useState } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import { Link, useHistory } from "react-router-dom";
import Modal from "../../view/common/Modal";
import NewLeadCreate from "./NewLeadCreate";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import MyLeadListFilter from "./MyLeadListFilter";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { LEADS_QUERY, STATUS_QUERY } from "../../service/leads.gql";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { GET_MASTER_DATA } from "../../queries/sangam/masterdata.gql";
import { DASHBOARD_QUERY } from "../../queries/sangam/dashboard.gql";
import { GET_USER_LIST } from "../../service/role.gql";
import dateFormat from "dateformat";
import Pagination from "../../view/pagination/Pagination";
import Loader from "../../view/elements/PageLoader";
import ActionTypes from "../../store/action/action";
import crypto from "../../config/crypto";
import GeneralService from "../../service/generalService";
import { RoleAccess } from "../../config/constant";
import NoDataFound from "../../common/NoDataFound";
import { useCleverTap } from '../CleverTapContext';
import { convertJsonToCsv, downloadFile } from "./helpers/helpers";
import InputField from "../../view/elements/InputField";
import ReactTooltip from "react-tooltip";

const MyLeadList = (props) => {
  const sourced_by = RoleAccess("Sourced By Lead Listing");
  const assign_to = RoleAccess("Assigned to Lead Listing");
  const fullfilled_by = RoleAccess("Fullfiled By Lead Listing");
  const assigned_to_button = RoleAccess("Re Assign Lead Listing");
  const new_lead_button = RoleAccess("New Lead Button");
  const export_button = RoleAccess("Lead Export");
  const trackEvent = useCleverTap();

  const client = "";
  const [activeTab, setActiveTab] = useState("All Leads");
  const [showModal, setShowModal] = useState(false)
  const [filter,setFilter] = useState({})
  const [newLead, setNewLead] = useState(false);
  const history = useHistory();
  const [filterParam, setFilterParam] = useState({
    page_no: 1,
    action_type: "lead_id",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [financers, setFinancers] = useState([]);
  const [fulfillments, setFulfillment] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [masterdata, setMasterData] = useState([]);
  const randomCardColorId = () => Math.floor(Math.random() * 5) + 1;
  const [dashboard, setDashboard] = useState([]);
  const [userList, setUserList] = useState([]);
  const [assignTo, setAssignTo] = useState([]);
  const [splitPayment, setSplitPayment] = useState([]);
  const today = new Date().toISOString().split("T")[0];

  const updateReduxData = async () => {
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      props.updateStateIntoRedux(ActionTypes.LOGIN_USER, loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    const role = loginResponse?.data?.user_data?.role || null;
    if (role !== "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const getMasterData = async () => {
    try {
      const response = await executeGraphQLQueryFinex(GET_MASTER_DATA());

      if (response && response.data && response.data.masterdata) {
        let result = { ...response.data.masterdata }; // Create a copy to avoid mutating the original response

        // Define keys that need processing
        let keyList = [
          "tenure",
          "agreement_type",
          "profession",
          "salary_credit_mode",
          "property_type",
          "usage_type",
          "loan_type",
          "loan_sub_type",
        ];

        // Process each key in keyList
        for (let item of keyList) {
          if (result[item] && result[item].length) {
            result[item] = result[item].map((data) => ({
              ...data,
              value: +data.id, // Convert id to number
              label: data.label, // Maintain the label
            }));
          }
        }

        // Process bank_list if it exists
        if (result.bank_list && result.bank_list.length) {
          result.bank_list = result.bank_list.map((data) => ({
            ...data,
            value: data.id, // Use id as value
            label: data?.banklang?.bank_name || "", // Use bank name or empty string if not available
          }));
        }

        // Set the processed data in state
        setMasterData(result);
      }
    } catch (error) {
      // Handle any errors that occur during the query
      console.error("Error fetching master data:", error);
      toast.error("Something went wrong, please try again");
    }
  };

  let loan_type = masterdata?.loan_type;
  let loan_sub_type = masterdata?.loan_sub_type;

  const getstatusList = () => {
    executeGraphQLQueryFinex(STATUS_QUERY, client)
      .then((result) => {
        setStatuses(result?.data?.status_list);
        setSubStatusList(result?.data?.sub_status_list);
        setFinancers(result.data?.financier_list);
        setFulfillment(result.data?.fulfillment_type);
      })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again");
      });
  };

  useEffect(() => {
    trackEvent("page_loaded", "my_leads");
    updateReduxData();
    getLeadList(filterParam);
    getstatusList();
    getMasterData();
    getDashboardCount();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      // Get the actual tooltip DOM element by its ID
      const tooltipEl = document.getElementById("tooltip-dateFilter");

      // If the tooltip is in the DOM and the click is NOT inside it...
      if (tooltipEl && !tooltipEl.contains(event.target)) {
        // ...then hide all tooltips (or just this one if needed)
        
        handleDateChange("createToDate", "")
        handleDateChange("createFromDate", "")
        ReactTooltip.hide();
      }
    }

    // Attach the listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

  let name = loginUserInfo?.name ? loginUserInfo.name : "";
  let userId =
    loginUserInfo && loginUserInfo.user_data
      ? loginUserInfo.user_data.user_id
      : 1;

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    //console.log("tabName", tabName);
    switch (tabName) {
      case "All Leads":
        filterParam.status_id = "";
        break;
      case "New Leads":
        filterParam.status_id = [1];
        break;
      case "Logged In":
        filterParam.status_id = [2];
        break;
      case "Sanctioned":
        filterParam.status_id = [3];
        break;
      case "Disbursed":
        filterParam.status_id = [4];
        break;
      case "Lost":
        filterParam.status_id = [5];
        break;
    }
    getLeadList(filterParam);
  };


  const togglePopup = () => {
    trackEvent("cta_clicked", "my_leads_new_lead");
    setNewLead(!newLead);
    document.body.classList.toggle("overflow-hidden", !newLead);
  };

  const navigateLeadDetail = (lead_id, status_id) => {
    // history.push("/saathi-details-page");
    let url = `/saathi-details-page/${crypto.encode(lead_id)}`;
    // history.push(url);
    window.open(url, "_blank");
  };

  const handleClickOutside = (event) => {
    const moreFilterDiv = document.querySelector(".more-filter");
    if (moreFilterDiv && !moreFilterDiv.contains(event.target)) {
      const toggleCheckbox = document.getElementById("toggleCheckbox");
      if (toggleCheckbox) toggleCheckbox.checked = false;
    }
  };

  const onPaginate = (p) => {
    setFilterParam((prev) => ({ ...prev, ["page_no"]: p }));
    filterParam.page_no = p;
    getLeadList(filterParam);
  };

  function isWithinSixMonths(date1, date2) {
    const sixMonthsInMs = 6 * 30 * 24 * 60 * 60 * 1000; // Approximate six months in milliseconds
    const diffInMs = Math.abs(new Date(date1) - new Date(date2)); // Get absolute difference in milliseconds
    return diffInMs <= sixMonthsInMs;
  }

  const handleDownload = (from_date, to_date) =>{
    if(!from_date || !to_date ){
      toast.error("Please select date range");
      return;
    }

    const from_date_raw = new Date(from_date);
    const to_date_raw = new Date(to_date);

    if(to_date_raw < from_date_raw){
      toast.error("To date should be greater than from date");
      return;
    }
    
    if(!isWithinSixMonths(from_date_raw, to_date_raw)){
      toast.error("Date range should be within 180 days");
      return;
    }

    const LOAN_TYPE ={
      1: "Home Loan",
      2: "LAP"
    }

    const LOAN_SUB_TYPE = {
      "1": "Balance Transfer",
      "2": "Topup",
      "3":"OD",
    }

    const filter = {};
    filter.column = "created_date";
    filter.sort = "desc";
    filter.user_id = loginUserInfo.user_data.dealer_id;
    filter.partner_id = loginUserInfo.user_data.dealer_id;
    filter.from_date = from_date;
    filter.to_date = to_date;
    filter.per_page = 100000;
    filter.page_no = 1;
    filter.date_type = "created_date";
    executeGraphQLQueryFinex(LEADS_QUERY(filter))
      .then((result) => {
        console.log(result?.data?.leads?.[0]?.lead_list);
        const array = result?.data?.leads?.[0]?.lead_list?.map((data)=>{
          return {
            "Lead id": data?.id,
            "Name": data?.customer?.first_name + " " + data?.customer?.last_name,
            "Mobile Number": data?.customer?.mobile,
            "Fulfilled Type": data?.fulfillment_type?.toUpperCase(),
            "Loan Amount":data?.lead_details?.loan_amount,
            "Login Amount":data?.lead_details?.loan_amount,
            "Disbursed Amount":data?.splitpayment[0]?.disbursed_amount,
            "Status": data?.status_info?.statuslang?.status_name,
            "Transaction": data?.splitpayment[0]?.is_tranch_verified === "1" ? getTransactionStatus(data) : "Pending",
            "PDD": data?.splitpayment[0]?.is_tranch_verified === "1" ? (data?.splitpayment[0]?.is_pdd_verified === "1" ? getPDDStatus(data) : "Pending") : "",
            "Bank MIS": data?.splitpayment[0]?.is_tranch_verified === "1" ? (data?.splitpayment[0]?.pay_in_done === "1" ? "Recieved" : "Pending") :"",
            "Collection": data?.splitpayment[0]?.is_tranch_verified === "1" ? (data?.splitpayment[0]?.is_pay_in_verified === "1" ? getCollection(data) : "Pending") : "",
            "Payout": data?.splitpayment[0]?.is_tranch_verified === "1" ? (data?.splitpayment[0]?.is_payout_verified === "1" ? getPayoutStatus(data) : "Pending") : "",
            "Source By":data?.partner_name,
            "Assigned To":data?.assigned_partner_name,
            "Created Date": `${dateFormat(
              data?.created_date,
              "d mmm yyyy | hh:MM TT"
            )}`,
            "Updated Date":`${dateFormat(
              data?.updated_date,
              "d mmm yyyy | hh:MM TT"
            )}`,
          }
        });
        if(array?.length === 0) {
          toast.error("No data found");
        }else{
          const data = convertJsonToCsv(array);
          downloadFile(data, "text/csv", "lead_list.csv");
          setFilter({});
          setShowModal(false);
        }
      })
      .catch((errors) => {
        console.log(errors)
        toast.error("Somethig went wrong ,please try again");
      });
  }

  const getLeadList = (filterData = {}) => {
    filterData.user_id = loginUserInfo.user_data.dealer_id;
    filterData.partner_id = loginUserInfo.user_data.dealer_id;
    setIsLoading(true);
    if (filterData.search_type) delete filterData.action_type;
    if (!filterData.column) filterData.column = "last_updated_date";
    if (!filterData.sort) filterData.sort = "desc";
    if(filterData.assigned_to) {
      filterData.partner_id = filterData.assigned_to.value
    }
    executeGraphQLQueryFinex(LEADS_QUERY(filterData))
      .then((result) => {
        console.log(result?.data?.leads?.[0]?.lead_list)
        setLeads(result?.data?.leads?.[0]?.lead_list);
        setTotalPage(result?.data?.leads?.[0]?.pagination?.totalpage);
        setIsLoading(false);
      })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again");
      });
  };

  const handleSearch = (filterData) => {
    filterData.action_type = filterParam.action_type;
    setFilterParam((prev) => ({ ...prev, ...filterData }));
    filterData.page_no = 1;
    setFilterParam((prev) => ({ ...prev, ["page_no"]: 1 }));
    getLeadList(filterData);
  };

  const getDashboardCount = async () => {
    if (userId) {
      let partner_id = loginUserInfo.user_data.dealer_id;

      executeGraphQLQueryFinex(DASHBOARD_QUERY(userId, partner_id))
        .then((res) => {
          const response = res.data?.lead_dashboard?.map((data) => ({
            ...data,
            color_id: randomCardColorId(),
          }));
          setDashboard(response);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserList();
    };
    fetchData();
  }, []);

  let all_lead_counts = dashboard
    ?.filter((ob) => ob.name === "All Leads")
    ?.map((v) => v.lead_count);
  let new_lead_counts = dashboard
    ?.filter((ob) => ob.name === "New Leads")
    ?.map((v) => v.lead_count);
  let login_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Logged In")
    ?.map((v) => v.lead_count);
  let san_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Sanctioned")
    ?.map((v) => v.lead_count);
  let dis_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Disbursed")
    ?.map((v) => v.lead_count);
  let lost_lead_counts = dashboard
    ?.filter((ob) => ob.name === "Lost")
    ?.map((v) => v.lead_count);

  const getUserList = async () => {
    let user_list = [],
      userlist = [];
    await executeGraphQLQueryFinex(GET_USER_LIST(5, 1))
      .then((response) => {
        if (response && response.data && response.data.get_user_list) {
          user_list = response.data.get_user_list?.filter(
            (obj) => obj.status === "1"
          );
          user_list &&
            user_list.map((v) => {
              userlist.push({
                value: v.user_id,
                label: v.name,
                role_id: v.role_assigned_ids,
              });
              return v;
            });
          setUserList(userlist);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatCurrency = (amount) => {
    if (amount == 0) {
      return "--";
    }
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
    return formattedAmount.replace(/\.00$/, "");
  };

  useEffect(() => {
    const fetchAssignToList = async () => {
      try {
        const response = await GeneralService.subPartnerList(
          loginUserInfo.user_data.dealer_id
          
        );
        if (response?.data?.status === 200) {
          setAssignTo(response?.data?.data?.sub_partner_list || []);
          let retrievedUser = JSON.parse(localStorage.getItem("loginUserInfo"));
        }
      } catch (error) {
        console.error("Error fetching assignTo list:", error);
      }
    };
    fetchAssignToList();
  }, [loginUserInfo.user_data.dealer_id]);

  const getTransactionStatus = (lead) => {
    if (lead?.splitpayment[0]?.transaction_date ) {
      return lead.splitpayment[0]?.is_transaction_verified === "1"
        ? new Date(lead?.splitpayment[0]?.transaction_date).toLocaleDateString()
        : "Pending";
    }
    return "Update";
  };

  const getPDDStatus = (lead) => {
    if (lead?.splitpayment[0]?.pdd_date) {
      return lead?.splitpayment[0]?.is_pdd_verified === "1"
        ? new Date(lead?.splitpayment[0]?.pdd_date).toLocaleDateString()
        : "Pending";
    }
    return "Update";
  };

  const getCollection = (lead) => {
    if (lead?.splitpayment[0]?.pay_in_done === "1") {
      if (lead?.splitpayment[0]?.pay_in_confirm_date) {
        return  "Received"

      }
      return "Pending";
    }
    return "Pending";
  };
  const getBankMISStatus = (lead) => {
    return lead?.splitpayment[0]?.pay_in_done==="1"
      ? "Received"
      : "Pending";
  };

  const getPayoutStatus = (lead) => {
    const payoutExpectedDate = lead?.splitpayment[0]?.payout_expected_date;
    const payoutDate = lead?.splitpayment[0]?.payout_date;
  
    if (payoutExpectedDate) {
      return `ETA - ${new Date(payoutExpectedDate).toLocaleDateString()}`;
    } else if (payoutDate) {
      return `Paid - ${new Date(payoutDate).toLocaleDateString()}`;
    } else {
      return "Pending";
    }
  };

  const CloseUpdateModal = () => {
    setShowModal(false);
  };

  const handleDateChange = (fieldname, value) =>{
    setFilter({
      ...filter,
      [fieldname]: value
    })
  }
  

  return (
    <div className="LeadContainer" onClick={handleClickOutside}>
    
      <div className="myleadcontainer">
        <div className="leftmyleadcontainer">
          <div className="leadheading">My Leads</div>
        </div>
        <div className="rightmyleadcontainer">
          <div className="not_assigned_lead">
            <Link to="/saathi-re-assign-leads">
              {assigned_to_button === 1 && <button>Re-assign</button>}
            </Link>
          </div>
          <div className="newleadbutton">
            {new_lead_button === 1 && (
              <button onClick={togglePopup}>+ New Lead</button>
            )}
          </div>
        </div>
      </div>
      <MyLeadListFilter
        masterdata={masterdata}
        handleSearch={handleSearch}
        setFilterParam={setFilterParam}
        assignTo={assignTo}
      />
      {isLoading ? <Loader /> : null}
      <div className="tablecontainer">
        <div className="newtabsContainer">
          <ul className="newtabs">
            {[
              "All Leads",
              "New Leads",
              "Logged In",
              "Sanctioned",
              "Disbursed",
              "Lost",
            ].map((tabName) => (
              <li
                key={tabName}
                className={
                  activeTab === tabName ? "newtabsItem active" : "newtabsItem"
                }
              >
                <div
                  className="newtabContent"
                  onClick={() => handleTabClick(tabName)}
                >
                  {tabName}
                  <span className="newtabSpan">
                    {/* (
                    {
                      {
                        "All Leads":
                          Number(new_lead_counts) +
                          Number(login_lead_counts) +
                          Number(san_lead_counts) +
                          Number(dis_lead_counts) +
                          Number(lost_lead_counts),
                        "New Leads": new_lead_counts,
                        "Logged In": login_lead_counts,
                        Sanctioned: san_lead_counts,
                        Disbursed: dis_lead_counts,
                        Lost: lost_lead_counts,
                      }[tabName]
                    }
                    ) */}
                  </span>
                </div>
              </li>
            ))}
          </ul>
{    export_button == 1 &&      <button data-tip  data-for="tooltip-dateFilter" data-event="click"  className="exportbtn mrg-r10 " ><img  alt="img" src={require(`../images/exportFile.svg`)} height={15} width={15} /> Export</button>
}        </div>
        <div className="table-container">
          <table>
            <thead style={{ position: "sticky", top: "0",zIndex:10 }}>
              <tr>
                <th style={{ width: "7%" }}>Lead ID</th>
                <th>Name</th>
                <th>Loan Type</th>
                <th>Bank</th>
                {activeTab === "All Leads" && (
                  <th style={{ width: "10%" }}>Status</th>
                )}
                {sourced_by === 1 && <th>Sourced by</th>}
                {assign_to === 1 && (
                  <th>
                    {activeTab === "Last" ? "Last Assigned" : "Assigned to"}
                  </th>
                )}

                {fullfilled_by === 1 && <th>Fulfilled by</th>}
                <th>Amount</th>
                <th>Created</th>
                <th>{activeTab == "Sanctioned" ? "Sanctioned Date" : activeTab == "Disbursed" ? "Disbursed Date" : activeTab == "Lost" ? "Lost Date" : "Created Date"}</th>
                {activeTab === "Lost" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {leads?.length > 0 ? (
                leads?.map((lead, index) => {
                  let leadStatus =
                    lead?.status_info?.statuslang?.status_name || "";
                  let databaseDate = new Date(lead?.updated_date);
                  let currentDate = new Date();
                  // Calculate the difference in milliseconds
                  let differenceInMilliseconds =
                    currentDate.getTime() - databaseDate.getTime();
                  // Convert the difference to seconds
                  let differenceInSeconds = differenceInMilliseconds / 1000;
                  // Convert the difference to minutes
                  let differenceInMinutes = differenceInSeconds / 60;
                  // Convert the difference to hours
                  let differenceInHours = differenceInMinutes / 60;

                  return (
                    <React.Fragment key={lead.id}>
                      <tr
                        key={index}
                        onClick={() =>
                          navigateLeadDetail(lead.id, lead.status_id)
                        }
                      >
                        <td>
                          {" "}
                          {lead?.id}
                          <div>
                            {lead.is_status_verified == 2 ? (
                              <img
                                style={{ marginLeft: 5 }}
                                src="https://static.ambak.com/saathi/1725263429582.svg"
                                alt="logo"
                                width={16}
                                height={16}
                              />
                            ) : null}
                          </div>
                        </td>
                        <td>
                          {" "}
                          {lead?.customer?.first_name}{" "}
                          {lead?.customer?.last_name}
                        </td>
                        <td>
                          {loan_type
                            ?.filter((data) => data.id == lead?.loan_type)
                            ?.map((v) => v.label)}{" "}
                          {lead?.loan_sub_type ? "-" : ""}
                          {loan_sub_type
                            ?.filter((data) =>
                              lead?.loan_sub_type?.split(",")?.includes(data.id)
                            )
                            ?.map((v) => `${v.label}\n`)}
                        </td>
                        <td>{lead?.lead_details?.bank?.banklang?.bank_name || '--'}</td>
                        {activeTab === "All Leads" && (
                          <td>
                            <span
                              className={`leadStatus ${leadStatus === "New Lead"
                                  ? "newleadsbg"
                                  : ["Contacted", "contact", "Contact"].includes(leadStatus)
                                    ? "disbursedbg"
                                    : ["LogIn", "Login","log in","logged in"].includes(leadStatus)
                                      ? "loggeninbg"
                                      : ["Sanctioned", "Sanction", "sanction"].includes(leadStatus)
                                        ? "sanctionedbg"
                                        : ["Disbursed", "Disburse", "disburse"].includes(leadStatus)
                                          ? "disbursedbg"
                                          : ["Lost", "lost"].includes(leadStatus)
                                            ? "lostleadbg"
                                            : "otherbg"
                                }`} 
                            >
                              {["LogIn", "Login","log in","logged in"].includes(leadStatus) ? "Logged In" : leadStatus}
                              {["Disbursed", "Disburse", "disburse"].includes(leadStatus) && (
                            <>
                            <div className="disburse-container"> 
                              <div style={{display:"flex",gap:"10px"}}>
                                <img  src="https://static.ambak.com/saathi/1733552594076.png" alt="icon" width={16} height={16}/>
                              <span><img src="https://static.ambak.com/saathi/1733552799331.png"  alt="icon" width={12} height={6}/></span>
                              </div>
                             
                            <div className="disburse-card">
                            <ul>
                                
                                          <li key={index} >
                                          {lead?.splitpayment[0] && (
                                            <>
                                              {lead?.splitpayment[0]?.is_tranch_verified === "1" &&
                                                (lead?.splitpayment[0]?.transaction_date ? (
                                                  <div className="disbursecommon">
                                                    <div className="disbursenew">
                                                      <img
                                                        src={
                                                          lead?.splitpayment[0]?.is_transaction_verified === "1"
                                                            ? "https://static.ambak.com/saathi/1733562776847.png"
                                                            : "https://static.ambak.com/saathi/1733562923039.png"
                                                        }
                                                        alt="icon"
                                                        width={12}
                                                        height={6}
                                                      />
                                                      <div>Transaction</div>
                                                    </div>
                                                    <div
                                                      className={
                                                        lead?.splitpayment[0]?.is_transaction_verified === "1"
                                                          ? "othercolor"
                                                          : "disbursepending"
                                                      }
                                                    >
                                                      {lead?.splitpayment[0]?.is_transaction_verified === "1"
                                                        ? getTransactionStatus(lead)
                                                        : "Pending"}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  lead?.splitpayment[0]?.pending?.split(",").includes("Transaction") && (
                                                    <div className="disbursecommon">
                                                      <div className="disbursenew">
                                                        <img
                                                          src={
                                                            "https://static.ambak.com/saathi/1733563093839.png"
                                                          }
                                                          alt="icon"
                                                          width={12}
                                                          height={6}
                                                        />
                                                        <div>Transaction</div>
                                                      </div>
                                                      <div className="othercolor">Update</div>
                                                    </div>
                                                  )
                                                ))}
                                            </>
                                          )}


                                             
                                          {lead?.splitpayment[0]?.is_tranch_verified === "1" && lead?.splitpayment[0] && (
                                            <>
                                              {lead?.splitpayment[0]?.pdd_date ? (
                                                <div className="disbursecommon">
                                                  <div className="disbursenew">
                                                    <img
                                                      src={
                                                        lead?.splitpayment[0]?.is_pdd_verified === "1"
                                                          ? "https://static.ambak.com/saathi/1733562776847.png"
                                                          : "https://static.ambak.com/saathi/1733562923039.png"
                                                      }
                                                      alt="icon"
                                                      width={12}
                                                      height={6}
                                                    />
                                                    <div>PDD</div>
                                                  </div>
                                                  <div
                                                    className={
                                                      lead?.splitpayment[0]?.is_pdd_verified === "1"
                                                        ? "othercolor"
                                                        : "disbursepending"
                                                    }
                                                  >
                                                    {lead?.splitpayment[0]?.is_pdd_verified === "1"
                                                      ? getPDDStatus(lead)
                                                      : "Pending"}
                                                  </div>
                                                </div>
                                              ) : (
                                                (  lead?.splitpayment[0]?.pending?.split(",").includes(" Pdd") && 
                                                <div className="disbursecommon">
                                                  <div className="disbursenew">
                                                  <img
                                                      src={
                                                       
                                                           "https://static.ambak.com/saathi/1733563093839.png"
                                                      }
                                                      alt="icon"
                                                      width={12}
                                                      height={6}
                                                    />
                                                    <div>PDD</div>
                                                  </div>
                                                  <div className="othercolor">Update</div>
                                                </div>
                                              )
                                                
                                              )}
                                            </>
                                          )}

                                           

                                          {lead?.splitpayment[0]?.is_tranch_verified === "1" && (
                                            <>
                                              {lead?.splitpayment[0]?.pay_in_done === "1" ? (
                                                <div className="disbursecommon">
                                                  <div className="disbursenew">
                                                    <img
                                                      src="https://static.ambak.com/saathi/1733562776847.png"
                                                      alt="icon"
                                                      width={12}
                                                      height={6}
                                                    />
                                                    <div>Bank MIS</div>
                                                  </div>
                                                  <div className="othercolor">Received</div>
                                                </div>
                                              ) : (
                                                lead?.splitpayment[0]?.pending?.split(",").some(value => ["Mis", " Mis"].includes(value)) && (
                                                  <div className="disbursecommon">
                                                    <div className="disbursenew">
                                                      <img
                                                        src="https://static.ambak.com/saathi/1733563093839.png"
                                                        alt="icon"
                                                        width={12}
                                                        height={6}
                                                      />
                                                      <div>Bank MIS</div>
                                                    </div>
                                                    <div className="disbursepending">Pending</div>
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}


                                              
                                          {lead?.splitpayment[0]?.is_tranch_verified === "1" && (
                                            <>
                                              {lead?.splitpayment[0]?.is_pay_in_verified === "1" ? (
                                                <div className="disbursecommon">
                                                  <div className="disbursenew">
                                                    <img
                                                      src="https://static.ambak.com/saathi/1733562776847.png"
                                                      alt="icon"
                                                      width={12}
                                                      height={6}
                                                    />
                                                    <div>Collection</div>
                                                  </div>
                                                  <div className="othercolor">{getCollection(lead)}</div>
                                                </div>
                                              ) : (
                                                lead?.splitpayment[0]?.pending?.split(",").some(value => ["Pay-in"," Pay-in"].includes(value)) && (
                                                  <div className="disbursecommon">
                                                    <div className="disbursenew">
                                                      <img
                                                        src="https://static.ambak.com/saathi/1733563093839.png"
                                                        alt="icon"
                                                        width={12}
                                                        height={6}
                                                      />
                                                      <div>Collection</div>
                                                    </div>
                                                    <div className="disbursepending">Pending</div>
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}

                                             
                                          {lead?.splitpayment[0]?.is_tranch_verified === "1" && (
                                            <>
                                              <div className="disbursecommon">
                                                <div className="disbursenew">
                                                  <img
                                                    src={
                                                      lead?.splitpayment[0]?.is_payout_verified === "1" &&
                                                        (lead?.splitpayment[0]?.payout_expected_date || lead?.splitpayment[0]?.payout_date)
                                                        ? "https://static.ambak.com/saathi/1733562776847.png"
                                                        : "https://static.ambak.com/saathi/1733563093839.png"
                                                    }
                                                    alt="icon"
                                                    width={12}
                                                    height={6}
                                                  />
                                                  <div>PayOut</div>
                                                </div>
                                                <div
                                                  className={
                                                    lead?.splitpayment[0]?.is_payout_verified === "1"
                                                      ? "othercolor"
                                                      :getPayoutStatus(lead)!=="Pending"?"othercolor": "disbursepending"
                                                  }
                                                >
                                                  {getPayoutStatus(lead)
                                                   }
                                                </div>
                                              </div>
                                            </>
                                          )}
 
                                          </li>
                              </ul>
                              </div>
                            </div>
                            </>
                          )}
                            </span>
                          </td>
                        )}

{/* lead?.splitpayment[0]?.payout_expected_date||lead?.splitpayment[0]?.pay_out_date  */}
                        {sourced_by === 1 && <td>{lead?.partner_name || '--'} </td>}
                        {assign_to === 1 && (
                          <td>
                            {/* {assignTo
                            ?.filter(
                              (data) => data.sub_partner_id == lead?.assigned_partner_name
                            )
                            ?.map((v) => v.sub_partner_name)} */}
                            {lead?.assigned_partner_name || '--'}
                          </td>
                        )}
                        {fullfilled_by === 1 && (
                          <td>{lead?.fulfillment_type.toUpperCase()}</td>
                        )}
                        <td>
                          {/* {lead?.status_id === 1
                            ? "Loan"
                            : lead?.status_id === 2
                            ? "Login"
                            : lead?.status_id === 3
                            ? "Sanctioned"
                            : lead?.status_id === 4
                            ? "Disbursed"
                            : "Loan"}{" "}
                          Amount :{" "} */}
                          {lead?.status_id === 1
                            ? formatCurrency(lead?.lead_details?.loan_amount)
                            : lead?.status_id === 2
                            ? formatCurrency(lead?.lead_details?.login_amount)
                            : lead?.status_id === 3
                            ? formatCurrency(
                                lead?.lead_details?.approved_amount
                              )
                            : lead?.status_id === 4
                            ? formatCurrency(
                                lead?.splitpayment[0]?.disbursed_amount
                              )
                            : formatCurrency(lead?.lead_details?.loan_amount)}
                        </td>
                        <td>
                          {dateFormat(
                            lead?.created_date,
                            "d mmm,yyyy | hh:MM TT"
                          )}
                        </td>
                        <td>
                          {dateFormat(
                            lead?.updated_date,
                            "d mmm,yyyy | hh:MM TT"
                          )}
                        </td>
                        {activeTab === "Lost" && (
                          <td
                            style={{ color: ["#E16B4D", "#473391"][index % 2] }}
                          >
                            {["Re-Open", "Re-Login"][index % 2]}
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <td colspan="11" style={{ textAlign: "center" }}>
                 <NoDataFound/>
                </td>
              )}
            </tbody>
          </table>

          <Pagination
            pageCount={totalPage}
            activePage={
              leads &&
              leads[0] &&
              leads[0].pagination &&
              leads[0].pagination.page_no &&
              1
            }
            onPaginate={onPaginate}
          />
        </div>
        <Modal isOpen={newLead} togglePopup={togglePopup} width={800} removeHeight={true}>
            <NewLeadCreate
              masterData={masterdata}
              loginUserInfo={loginUserInfo}
            />
          </Modal>


        <ReactTooltip
          id="tooltip-dateFilter"
          className="no-blur-tooltip"
          place="bottom"
          effect="solid" // ensures there's no fade/blur effect
          backgroundColor="#fff"
          textColor="#000"
          clickable
          getContent={() => (
            <div className="toolTipContent p-3" style={{ minWidth: "300px" ,padding:"15px"}}>
              {/* Smaller heading */}
              <h6 className="mb-2 font-weight-normal">Select date to download files</h6>

              {/* Row with two date inputs (reduced gap) */}
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="createFromDate" className="form-label ">
                    From
                  </label>
                  <InputField
                    type="date"
                    name="createFromDate"
                    id="createFromDate"
                    value={filter.createFromDate}
                    placeholder="From date"
                    max={today}
                    onChange={(e) => handleDateChange("createFromDate", e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="createToDate" className="form-label">
                    To
                  </label>
                  <InputField
                    type="date"
                    name="createToDate"
                    id="createToDate"
                    value={filter.createToDate}
                    placeholder="To date"
                    max={today}
                    onChange={(e) => handleDateChange("createToDate", e.target.value)}
                    className="form-control"
                  />
                </div>

              </div>

              {/* Download button with slightly smaller styling */}
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-sm btn-primary px-3"
                  onClick={()=>{handleDownload(filter?.createFromDate, filter?.createToDate)}}
                  style={{transition:"none"}}
                >
                  Download
                </button>
              </div>
            </div>
          )}
        />



      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(MyLeadList)
);