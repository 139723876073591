import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../MyLeads/helpers/helpers";
import moment from "moment";
import { INVOICE_LISTING_DATA } from "../../../queries/payout.gql";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import PageLoader from "../../../view/elements/PageLoader";

const NotesPopUp = ({notesData,selectedLeadId,bankList,selectedInvoiceId}) => {

    const [loading, setLoading] = useState(false);
    const [breakupData,setBreakupData] = useState(null)
    const leadDetails  = notesData.notes.find(item=>item.leads.find(lead=>lead.lead_id==selectedLeadId))

    const details = {...notesData,leadDetails}

    const fetchBreakupData = async()=>{
        setLoading(true)
        const response = await executeGraphQLMutation(INVOICE_LISTING_DATA(selectedInvoiceId));
        setBreakupData(response.data.invoice_payouts[0]);
        setLoading(false)
    }
    useEffect(()=>{
        fetchBreakupData()
    },[])

    const getRangeLabel = (amount) => {
        if (amount >= 1000000000) return "100cr+";
        if (amount >= 900000000) return "90cr+";
        if (amount >= 800000000) return "80cr+";
        if (amount >= 700000000) return "70cr+";
        if (amount >= 600000000) return "60cr+";
        if (amount >= 500000000) return "50cr+";
        if (amount >= 400000000) return "40cr+";
        if (amount >= 300000000) return "30cr+";
        if (amount >= 250000000) return "25cr+";
        if (amount >= 200000000) return "20cr+";
        if (amount >= 150000000) return "15cr+";
        if (amount >= 100000000) return "10cr+";
        if (amount >= 90000000) return "9cr+";
        if (amount >= 80000000) return "8cr+";
        if (amount >= 70000000) return "7cr+";
        if (amount >= 60000000) return "6cr+";
        if (amount >= 50000000) return "5cr+";
        if (amount >= 40000000) return "4cr+";
        if (amount >= 30000000) return "3cr+";
        if (amount >= 20000000) return "2cr+";
        if (amount >= 10000000) return "1cr+";
        return "0"; // Default case if amount is below the lowest range
    };


    return (
         <>
        {loading ? <PageLoader /> : ""}
        {breakupData && <>
        <div>
            <h3>Breakup Details</h3>
            <span className="breakupTag tag-purple color-white">Lead ID - {leadDetails?.leads[0]?.lead_id}</span>{" "}
            <span className="breakupTag tag-yellow">Bank - {bankList.find(item=>item.id==leadDetails?.leads[0]?.financer_id)?.name}</span>
        </div>
            <div className="breakuplistflex">
                <div>
                    <div className="headingbreak">
                    {leadDetails?.leads[0]?.product_type==2?"Loan Against Property":"Home Loan"}
                    </div>
                    <div className="subheadingbreak">Product Type</div>
                </div>
                <div>
                    <div className="headingbreak">
                        {details.invoice_no}
                    </div>
                    <div className="subheadingbreak">Original Invoice ID</div>
                </div>
                <div>
                    <div className="headingbreak">
                        {moment(details.created_at).format("DD/MM/YYYY")}
                    </div>
                    <div className="subheadingbreak">Original Invoice Date</div>
                </div>
                <div>
                    <div className="headingbreak">
                        {formatCurrency(details.payout_amount)}
                    </div>
                    <div className="subheadingbreak">Original Invoice Amt</div>
                </div>
                <div>
                    <div className="headingbreak">{leadDetails?.leads[0]?.disbursal_date?moment(leadDetails.leads[0].disbursal_date).format("DD/MM/YYYY"):""}</div>
                    <div className="subheadingbreak">Disb Date</div>
                </div>
            </div>

            <div className="breakshowdetails">
                <div className="breakleftflot">
                    <div className="mainheadingpayout">Total Payout</div>
                    <div className="breakamountshow">
                        <div className="amountdetailsname">Disbursed Amount</div>
                        <div className="amountdetailsprice">
                            ₹{" "}
                            {formatCurrency(leadDetails?.leads[0]?.disbursal_amount)}
                        </div>
                    </div>

                    <div className="breakamountshow">
                        <div className="amountdetailsname">Payout % of disbursal</div>
                        <div className="amountdetailsprice">
                            {" "}
                            {parseFloat(notesData?.payout_per).toFixed(3)}
                            %
                        </div>
                    </div>

                    <div className="breakamountshow">
                        <div className="amountdetailsname">Invoice Amount</div>
                        <div className="amountdetailsprice">₹  {notesData?.payout_amount ||
                            notesData?.payouts?.payout_amount}</div>
                    </div>

                    <div className="breakamountshow">
                        <div className="amountdetailsname">Original Payable Amount</div>
                        <div className="amountdetailsprice">₹  {+breakupData?.pat-(+breakupData?.sgst_amount+breakupData?.igst_amount+breakupData?.igst_amount)}</div>
                    </div>

                    <div className="breakamountshow">
                        <div className="amountdetailsname">{breakupData.payouts.payout_type=="credit"?"Credit":"Debit"} Amount</div>
                        <div className="amountdetailsprice">₹  {breakupData.payouts.payout_amount}</div>
                    </div>

                    <div className="breakamountshow">
                        <div className="amountdetailsname">TDS@{breakupData.payouts.tds_per}%</div>
                        <div className="amountdetailsprice">-₹  {breakupData.payouts.tds}</div>
                    </div>

                    <div className="breakamountshow">
                        <div className="amountdetailsname">GST</div>
                        <div className="amountdetailsprice">+₹  {breakupData.payouts.gst_amount||0.00}</div>
                    </div>

                    <div className="breakamountshow">
                        <div className="amountdetailsname">Total Payable</div>
                        <div className="amountdetailsprice">₹  {breakupData.payouts.pat}</div>
                    </div>


                </div>

                <div className="breakrightflot">
                    <div className="mainheadingpayout"> Reason</div>
                    <div>
                        <div className="menu-tab-left-panel-new">
                            {breakupData.payouts.comment}
                        </div>

                        {/* <div className="showhistory">
                  <div className="mainheadingpayout">Booster</div>
                  <div className="checknowbtn">Check Now</div>
                </div>

                <div className="breakamountshow">
                  <div className="amountdetailsname">
                    Booster payout % of disbursal{" "}
                  </div>
                  <div className="amountdetailsprice">0.2%</div>
                </div> */}
                    </div>
                </div>
            </div>
            </>}
        </>
    );
};

export default NotesPopUp;
