import { useEffect, useState } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import PageLoader from "../view/elements/PageLoader";

const getQueryParams = () => {
  return new URLSearchParams(window.location.search);
};

const initializeFreshdesk = () => {
  return new Promise((resolve) => {
    const query = getQueryParams();

    const source = query.get("source") || "";
    let widgetId= 1070000002508; //saathi

    if(source=="yoddha"){
      widgetId = 1070000002510;
    }
    console.log("widgetIdwidgetIdwidgetId",widgetId);

    window.fwSettings = { widget_id: widgetId };

    if (!document.querySelector(`script[src="https://ind-widget.freshworks.com/widgets/${widgetId}.js"]`)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://ind-widget.freshworks.com/widgets/"+widgetId+".js";
      script.async = true;
      script.defer = true;

      script.onload = () => {
        const checkWidget = setInterval(() => {
          if (window.FreshworksWidget) {
            clearInterval(checkWidget);
            resolve(true);
          }
        }, 500);
      };

      document.body.appendChild(script);
    } else {
      const checkWidget = setInterval(() => {
        if (window.FreshworksWidget) {
          clearInterval(checkWidget);
          resolve(true);
        }
      }, 500);
    }
  });
};

const SupportPage = () => {

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const partnerId = query.get("partnerId");
  const name = query.get("name") || "";
  const screen = query.get("screen") || "";
  const source = query.get("source") || "";
  const invoiceId = query.get("invoiceId") || "";
  const tranchId = query.get("tranchId") || "";
  const email = query.get("email");
  const createdByName =query.get("createdByName") || "";
  const createByEmail = query.get("createdByEmail") || "";
  const createdBy =query.get("createdBy") || "";
  const yoddhaId =query.get("yoddhaId") || "";
  const yoddhaEmail =query.get("yoddhaEmail") || "";
  const yoddhaName =query.get("yoddhaName") || "";


  const issueType = query.get("issueType");
 
  const [isLoading, setIsLoading] = useState(true);

  let ticketSource = "Saathi App";
  let createdByDetails = "";
  let rmDetails = "";
  let subject = "";

  if (yoddhaName) {
    rmDetails = `${yoddhaName} - ${yoddhaEmail}`;
  }

  let description ="";

  if (invoiceId && screen && ["invoice", "payout"].includes(screen?.toLowerCase())) {
    description = "There is an issue with Invoice - " + invoiceId;
  }

  if (tranchId && screen && ["invoice", "payout"].includes(screen?.toLowerCase())) {
    description = "There is an issue with tranch - " + tranchId;
  }

  if(source === "saathi"){
    createdByDetails = `${name} - ${email}`;
    subject     = screen + " Query raised by "+name +" - "+partnerId;
  }

  if (source === "yoddha") {
    ticketSource = "Yoddha App";
    if (createdByName && source=="yoddha") {
      createdByDetails = `${createdByName} - ${createByEmail}`;
      subject     = "Query raised by Yoddha on behalf of "+name +" - "+partnerId;
    }
  }

  useEffect(() => {
    initializeFreshdesk().then(() => {
      console.log("✅ Freshdesk Widget Loaded");

      if (window.FreshworksWidget) {
        console.log("✅ Setting up event listeners");
        window.FreshworksWidget("hide", "launcher");
        window.FreshworksWidget(
          "hide",
           "ticketForm",
           ["name",
            "subject",
            "custom_fields.cf_source",
            "email",
            "custom_fields.cf_source",
            "custom_fields.cf_partner_id",
            "custom_fields.cf_requested_by",
            "custom_fields.cf_rm_details"]);

        // Set user details
        window.FreshworksWidget("identify", "ticketForm", {
          name: name,
          email: email,
        });

        // Prefill and open the widget
        setTimeout(() => {
          console.log("📝 Prefilling the ticket form...");
          window.FreshworksWidget("prefill", "ticketForm", {
            name: name,
            subject: subject,
            description :  description || "" ,
            email: email,
            requested_by :1211,
            custom_fields: {
              cf_partner_id: partnerId,
              cf_source: ticketSource,
              cf_email :email,
              cf_requester:email,
              cf_requested_by : createdByDetails,
              ...(rmDetails && {cf_rm_details :rmDetails}),
              ...(screen && source=="saathi" && (screen=="Payout" || screen=="Invoice") && {cf_issue:"Payout/Invoice Issue"}),
              ...(issueType && { cf_type_of_issue: issueType }),
            },
          });
          window.FreshworksWidget("disable", "ticketForm", [
            "name",
            "email",
            "custom_fields.cf_partner_id",
            ...(issueType && ["custom_fields.cf_type_of_issue"]),
          ]);
          console.log("📬 Opening the ticket form...");
          window.FreshworksWidget("open", "ticketForm");

        }, 500);
      } else {
        console.error("🚨 FreshworksWidget is not available!");
      }
    });
  }, []);


  const handleBackButtonClick = () => {
    window.ReactNativeWebView.postMessage("close")
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
      {
        isLoading ? <PageLoader /> :

        <div style={{ textAlign: "center", padding: "20px" }}>

          <h4>🎉 Great! Your ticket has been created successfully. It may take 2 mins for the ticket to reflect in the app.Click below to return to the app and we'll get back to you soon!</h4>

          <button
            onClick={() => handleBackButtonClick()}
            style={{
              padding: "10px 15px",
              backgroundColor: "#473391",
              color: "white",
              border: "none",
              borderRadius: "12px",
              cursor: "pointer",
              margin: "auto",
              marginTop: "50px",
              height: "40px",
              width: "150px"
            }}
          >
            Back to app
          </button>
        </div>
      }
    </div>
  );
};

export default SupportPage;
